import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "histories/";

import authHeader from "../services/auth-header";
export const histories = {
  state: {
    histories: [],
  },
  getters: {
    allHistory: (state) => state.histories,
  },
  mutations: {
    setHistories(state, histories) {
      state.histories = histories;
    },
  },
  actions: {
    async getHistories(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setHistories", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHisBySearch(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "search", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setHistories", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
