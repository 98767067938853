<template>
  <div class="mb-3">
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Farms
        </span>
      </v-col>
    </v-row>
    <v-card id="card" class="mx-5 mb-10">
      <v-card-title style="color: #383874; font-style: normal">
        Farms
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getFarms"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="editFarmData(item)"
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delFarmData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position: fixed; bottom: 25px; right: 0px"
            class="ma-10"
            fixed
            fab
            color="#384CFF"
            dark
            absolute
            bottom
            right
            dense
            floating
            v-bind="attrs"
            v-on="on"
            @click="form = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>

      <v-dialog v-model="form" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Create Farm</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addFarm()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
                ref="farmData"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="farmData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="numberTower"
                  label="Number Tower*"
                  v-model="farmData.numberTower"
                  :rules="[(v) => !!v || 'Number Tower is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="macAddress"
                  label="MACAddress"
                  v-model="farmData.macAddress"
                  :rules="[(v) => !!v || 'MACAddress is required']"
                  required
                >
                </v-text-field>
                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">Location</v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="lat"
                      label="Lat"
                      v-model="farmData.location.lat"
                      :rules="[(v) => !!v || 'Lat is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="lon"
                      label="Lon"
                      v-model="farmData.location.lon"
                      :rules="[(v) => !!v || 'Lon is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-autocomplete
                  :items="getOwners"
                  name="owner"
                  label="Owner*"
                  item-value="_id"
                  item-text="name"
                  v-model="farmData.owner"
                  required
                ></v-autocomplete>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="countries"
                      name="country"
                      label="Country*"
                      v-model="farmData.country"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="provinces"
                      name="Province"
                      label="Province*"
                      v-model="farmData.province"
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">Camera</v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-text-field
                      name="ip"
                      label="IP"
                      v-model="farmData.camera.ip"
                      :rules="[(v) => !!v || 'ip is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="username"
                      label="username"
                      v-model="farmData.camera.username"
                      :rules="[(v) => !!v || 'username is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="password"
                      label="password"
                      v-model="farmData.camera.password"
                      :rules="[(v) => !!v || 'password is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn text @click="addFarm()" color="green"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Edit Farm</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editFarm()"
                ref="farmEditData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="farmEditData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="macAddress"
                  label="MACAddress"
                  v-model="farmEditData.macAddress"
                  :rules="[(v) => !!v || 'MACAddress is required']"
                  required
                >
                </v-text-field>
                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">Location</v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="lat"
                      label="Lat"
                      v-model="farmEditData.location.lat"
                      :rules="[(v) => !!v || 'Lat is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="lon"
                      label="Lon"
                      v-model="farmEditData.location.lon"
                      :rules="[(v) => !!v || 'Lon is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-autocomplete
                  :items="getOwners"
                  name="owner"
                  label="Owner*"
                  item-value="_id"
                  item-text="name"
                  v-model="farmEditData.owner"
                  required
                ></v-autocomplete>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="countries"
                      name="country"
                      label="Country*"
                      v-model="farmEditData.country"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="provinces"
                      name="Province"
                      label="Province*"
                      v-model="farmEditData.province"
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">Camera</v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-text-field
                      name="ip"
                      label="IP"
                      v-model="farmEditData.camera.ip"
                      :rules="[(v) => !!v || 'ip is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="username"
                      label="username"
                      v-model="farmEditData.camera.username"
                      :rules="[(v) => !!v || 'username is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="6" class="mt-0 pt-0">
                    <v-text-field
                      name="password"
                      label="password"
                      v-model="farmEditData.camera.password"
                      :rules="[(v) => !!v || 'password is required']"
                      required
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn color="green" text @click="editFarm()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="confirmDelete" max-width="320">
        <v-card>
          <v-card-title style="color: #383874" primary-title>
            Confirm Delete
          </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this farm ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delFarm()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import provinces from "@/config/provinces";
import countries from "@/config/countries";
export default {
  name: "OwnerPage",
  components: {},
  computed: {
    provinces() {
      return provinces;
    },
    countries() {
      return countries;
    },
    getFarms() {
      return this.$store.getters.getFarms;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getOwners() {
      return this.$store.getters.allOwners;
    },
  },
  async mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role == "Superadmin") {
      this.$store.dispatch("getFarms");
      this.$store.dispatch("getOwners");
    } else {
      this.$router.push("/");
    }
  },

  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
      form: false,
      search: null,
      confirmDelete: false,
      editform: false,
      farmData: {
        _id: null,
        owner: null,
        name: null,
        macAddress: null,
        province: null,
        country: null,
        location: {
          lat: null,
          lon: null,
        },
        numberTower: null,
        camera: {
          ip: null,
          username: null,
          password: null,
        },
      },
      default: {
        _id: null,
        owner: null,
        name: null,
        macAddress: null,
        province: null,
        country: null,
        location: {
          lat: null,
          lon: null,
        },
        numberTower: null,
        camera: {
          ip: null,
          username: null,
          password: null,
        },
      },
      farmEditData: {
        _id: null,
        owner: null,
        name: null,
        macAddress: null,
        province: null,
        country: null,
        location: {
          lat: null,
          lon: null,
        },
        numberTower: null,
        camera: {
          ip: null,
          username: null,
          password: null,
        },
      },
      farmDelId: null,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Owner", value: "owner.name", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  methods: {
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.farmData = Object.assign({}, this.default);
    },
    delFarmData(id) {
      this.confirmDelete = true;
      this.farmDelId = id;
    },
    addFarm() {
      if (this.farmData.Name === "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create a owner.";
      } else {
        this.$store.dispatch("createFarm", this.farmData).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.closeDlg();
            this.$store.dispatch("getFarms");
            this.color = "success";
            this.text = "Farm has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        });
      }
    },
    delFarm() {
      this.$store
        .dispatch("deleteFarm", this.farmDelId)
        .then(() => {
          this.closeDlg();
          this.$store.dispatch("getFarms");
          this.color = "success";
          this.text = "Deleted! Farm has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this Farm.";
          this.snackbar = true;
        });
    },
    editFarmData(data) {
      //console.log("data");
      this.editform = true;
      this.farmEditData = Object.assign({}, data);
    },
    editFarm() {
      this.$store
        .dispatch("editFarm", this.farmEditData)
        .then((res) => {
          //console.log('res.error.message');
          //console.log(res);
          this.$store.dispatch("getFarms");
          if (res.data.message == "Sorry Name Is Already Exists") {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that name already exists!";
            this.snackbar = true;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Farm has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this Farm.";
          this.snackbar = true;
        });
    },
  },
};
</script>
<style></style>
