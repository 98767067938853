<template>
  <div v-if="getAccess" v-resize="onResize">
    <v-app-bar v-if="isMobile" dense elevation="0" color="#fff">
      <v-app-bar-nav-icon
        class="ml-1"
        @click.native="$router.push('/overview')"
      >
        <img :src="require('/public/logo.png')" width="3%" />
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div style="width: 15%">
        {{ time }}
      </div>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                <v-list-item-subtitle>
                  @{{ getAccess.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="dialogPass = true">
              <v-list-item-action>
                <v-icon>mdi mdi-lock-reset</v-icon>
              </v-list-item-action>
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialog = true">
              <v-list-item-action>
                <v-icon>fas fa-sign-out-alt</v-icon>
              </v-list-item-action>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-app-bar v-else dense elevation="0" color="#fff">
      <v-app-bar-nav-icon class="ml-1">
        <img :src="require('/public/logo.png')" width="3%" />
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-tabs v-model="tab" color="#000" align-with-title>
        <v-tabs-slider color="#007346"></v-tabs-slider>
        <v-tab
          style="width: 10%"
          v-for="item in items"
          :key="item.tab"
          :to="item.to"
          >{{ item.name }}</v-tab
        >
      </v-tabs>
      <div style="width: 10%">
        {{ time }}
      </div>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                <v-list-item-subtitle>
                  @{{ getAccess.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="dialogPass = true">
              <v-list-item-action>
                <v-icon>mdi mdi-lock-reset</v-icon>
              </v-list-item-action>
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialog = true">
              <v-list-item-action>
                <v-icon>fas fa-sign-out-alt</v-icon>
              </v-list-item-action>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Logout </v-card-title>
        <v-card-text>Are you sure you want to logout?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="logout()"> Yes </v-btn>
          <v-btn color="error" text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPass" persistent max-width="400">
      <v-card>
        <v-card-title primary-title style="color: #383874">
          Change Password
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="changePassword()"
            @keyup.native.enter="changePassword()"
            ref="changPassword"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              :type="password.hideOld ? 'text' : 'password'"
              :append-icon="
                password.hideOld ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="old password"
              label="Old Password"
              hide-details="auto"
              v-model="password.old"
              @click:append="password.hideOld = !password.hideOld"
              :rules="[(v) => !!v || 'Old Password is required']"
              required
              @keypress="isLetter($event)"
              color="#000"
            >
            </v-text-field>
            <v-text-field
              :type="password.hideNew ? 'text' : 'password'"
              :append-icon="
                password.hideNew ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="new password"
              label="New Password"
              hide-details="auto"
              v-model="password.new"
              @click:append="password.hideNew = !password.hideNew"
              :rules="[(v) => !!v || 'New Password is required']"
              required
              @keypress="isLetter($event)"
              color="#000"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="success" text @click="changePassword()">Change</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      this.time = new Date().toLocaleTimeString("it-IT");
    }, 1000);
  },
  mounted() {
    this.$store.dispatch("auth/decode");
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      if (this.getAccess.role == "Superadmin") {
        this.sockets.subscribe("failConnect", (data) => {
          console.log(data);
          this.text = data.message;
          this.color = "red";
          this.snackbar = true;
        });
      }
      this.checkManu();
    }
  },

  watch: {
    $route(to) {
      console.log(to.name);
      this.path = to.name;
      this.checkManu();
    },
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data: () => ({
    isMobile: false,
    interval: null,
    time: null,
    path: null,
    tab: null,
    items: [],
    dialog: false,
    dialogPass: false,
    itemsSuperAdmin: [
      { name: "Overview", to: "/overview" },
      { name: "Reports", to: "/reports" },
      { name: "Events", to: "/events" },
      { name: "Alarms", to: "/alarms" },
      { name: "Setting", to: "/owners" },
    ],
    itemsAdmin: [
      { name: "Overview", to: "/overview" },
      { name: "Reports", to: "/reports" },
      { name: "Events", to: "/events" },
      { name: "Alarms", to: "/alarms" },
      { name: "Setting", to: "/owners/lines" },
    ],
    itemsUser: [{ name: "Overview", to: "/overview" }],
    itemsSettingSuper: [
      { name: "Overview", to: "/overview" },
      { name: "Owners", to: "/owners" },
      { name: "Farms", to: "/farms" },
      { name: "Accounts", to: "/accounts" },
    ],
    itemsSettingAdmin: [
      { name: "Overview", to: "/overview" },
      { name: "Owners Line ", to: "/owners/lines" },
      { name: "Accounts", to: "/accounts" },
    ],
    password: {
      old: "",
      new: "",
      hideOld: false,
      hideNew: false,
    },
    payload: {
      id: "",
      newPassword: "",
      password: "",
    },
    snackbar: false,
    text: "",
    color: "",
  }),
  methods: {
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
    checkManu() {
      //console.log(this.eventSound);
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (
        this.path == "Owners" ||
        this.path == "Farms" ||
        this.path == "Accounts" ||
        this.path == "LineOwner"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.itemsSettingSuper;
        } else if (this.getAccess.role == "Admin") {
          this.items = this.itemsSettingAdmin;
        } else {
          this.items = this.itemsAdmin;
        }
      } else if (
        this.path == "Overview" ||
        this.path == "Farm" ||
        this.path == "Tower" ||
        this.path == "Reports" ||
        this.path == "Events" ||
        this.path == "Alarms"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.itemsSuperAdmin;
        } else if (this.getAccess.role == "Admin") {
          console.log("item admin");
          this.items = this.itemsAdmin;
        } else {
          this.items = this.itemsUser;
        }
      }
    },
    close() {
      this.dialogPass = false;
      this.dialogPassEvent = false;
      this.password.new = "";
      this.password.old = "";
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    logout() {
      try {
        this.$store.dispatch("auth/logout").then(() => {
          this.$router.push("/");
        });
      } catch (error) {
        console.log(error);
      }
    },
    changePassword() {
      this.payload.id = this.getAccess.id;
      this.payload.newPassword = this.password.new;
      this.payload.password = this.password.old;
      if (this.password.new == "" || this.password.old == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else if (
        /^[A-Za-z0-9]+$/.test(this.password.old) &&
        /^[A-Za-z0-9]+$/.test(this.password.new)
      ) {
        this.$store.dispatch("auth/changePassword", this.payload).then(
          () => {
            //console.log(res);
            this.dialog = false;
            this.color = "success";
            this.text = "Success! Your Password has been changed!";
            this.snackbar = true;
            this.close();
          },
          () => {
            this.text = "Error! Your old password is incorrect!";
            this.color = "red";
            this.snackbar = true;
            this.password.old = "";
          }
        );
      } else {
        this.text = "Error! Password can only use A-Z a-z 0-9";
        this.color = "red";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style></style>
