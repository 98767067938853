import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "farms/";

import authHeader from "../services/auth-header";
export const farms = {
  state: {
    farms: [],
    realTime: null,
    farm: {},
    CO2: {},
    towers: [],
    dataMap: [],
  },
  getters: {
    chartOptions: (state) => state.dataMap,
    getFarms: (state) => state.farms,
    OneFarm: (state) => state.farm,
    CO2Farm: (state) => state.CO2,
    allTower: (state) => state.towers,
  },
  mutations: {
    setFarms(state, farms) {
      state.farms = farms;
      state.dataMap = setData(farms);
    },
    setTowerByFarm(state, towers) {
      state.towers = towers;
    },
    setOneFarm(state, farm) {
      console.log(farm);
      state.farm = farm;
    },
    SOCKET_env(state, payload) {
      console.log(payload);
      let data = payload;
      if (state.farms != []) {
        const index = state.farms.findIndex((t) => t._id === data._id);
        if (index > -1) {
          if (data.CO2 != undefined) {
            state.farms[index].Environment.CO2 = data.CO2;
          } else if (data.Programs != undefined) {
            state.farms[index].Environment.Programs = data.Programs;
          } else if (data.Environment != undefined) {
            state.farms[index].Environment = data.Environment;
          }
        }
      }
      if (state.farm != null) {
        if (data._id == state.farm._id) {
          if (data.CO2 != undefined) {
            state.farm.Environment.CO2 = data.CO2;
          } else if (data.Programs != undefined) {
            state.farm.Environment.Programs = data.Programs;
            console.log(state.farm.Environment.Programs);
          } else if (data.airs != undefined) {
            state.farm.airs = data.airs;
          } else if (data.Environment != undefined) {
            state.farm.Environment = data.Environment;
          }
        }
      }
    },
    SOCKET_online(state, payload) {
      console.log("online");
      console.log(payload);
      let data = payload;
      if (state.farms != []) {
        const index = state.farms.findIndex((t) => t._id === data.farm);
        if (index > -1) {
          if (data.status != undefined) {
            state.farms[index].status = data.status;
          }
        }
      }
      if (state.farm != null) {
        if (data.farm == state.farm._id) {
          if (data.status != undefined) {
            state.farm.status = data.status;
          }
        }
      }
    },
  },
  actions: {
    async getFarms(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setFarms", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createFarm(context, data) {
      console.log(data);
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);

          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteFarm(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editFarm(context, data) {
      console.log(data);
      return axios
        .put(apiURL + data._id, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);

          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    async getFarmByID(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setOneFarm", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setPressure(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "SetCO2/pressure",
          {
            No: data.No,
            macAddress: data.macAddress,
            Name: data.Name,
            HighHigh: data.HighHigh,
            High: data.High,
            Low: data.Low,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setCO2Valve(context, data) {
      return axios
        .post(
          apiURL + "SetCO2/valve",
          {
            No: data.No,
            macAddress: data.macAddress,
            Name: data.Name,
            Mode: data.Mode,
            setOn: data.setOn,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setProgram(contex, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "setProgram",
          {
            macAddress: data.macAddress,
            No: data.No,
            startTime: data.startTime,
            Temperature: data.Temperature,
            humidity: data.humidity,
            deHumidity: data.deHumidity,
          },
          { headers: authHeader() }
        )
        .then(() => {
          //console.log(res);
          //console.log("Create is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setCO2(context, data) {
      return axios
        .put(apiURL + "env/setCO2/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setTemp(context, data) {
      console.log(data);
      return axios
        .put(apiURL + "env/setTemp/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          //context.commit("setTemp", data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setHumidity(context, data) {
      return axios
        .put(apiURL + "env/setHumidity/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setFanFlow(context, data) {
      return axios
        .put(apiURL + "env/setFanFlow/" + data.id, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setFFU(context, data) {
      return axios
        .put(apiURL + "env/setFFU/" + data.id, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async getTowerByFarm(context, id) {
      //console.log(id)
      return axios
        .get(apiURL + "towers/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setTowerByFarm", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAlarmTemp(context, data) {
      console.log(data);
      return axios
        .put(apiURL + "env/setAlarm/temp/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmHumi(context, data) {
      return axios
        .put(apiURL + "env/setAlarm/humidity/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmCO2(context, data) {
      return axios
        .put(apiURL + "env/setAlarm/co2/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmVPD(context, data) {
      return axios
        .put(apiURL + "env/setAlarm/vpd/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmDiff(context, data) {
      return axios
        .put(apiURL + "env/setAlarm/diffPressure/" + data.farm, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
};
function setData(data) {
  let array = [];
  for (let i = 0; i < data.length; i++) {
    array.push({
      name: data[i].name,
      id: data[i].id,
      status: nameStatus(data[i].status),
      lat: data[i].location.lat,
      lon: data[i].location.lon,
      owner: data[i].owner,
      color: colorStatus(data[i].status),
    });
  }
  return array;
}
function colorStatus(status) {
  if (status == true) {
    return "green";
  } else {
    return "red";
  }
}
function nameStatus(status) {
  if (status == true) {
    return "Online";
  } else {
    return "Offline";
  }
}
