import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "lines/";

import authHeader from "../services/auth-header";
export const lines = {
  state: {
    lines: [],
    linesalarms: [],
  },
  getters: {
    allLines: (state) => state.lines,
    getLinesAlarms: (state) => state.linesalarms,
  },
  mutations: {
    setLine(state, lines) {
      state.lines = lines;
    },
    createLine(state, line) {
      state.lines.push(line);
    },
    createAlarmLine(state, line) {
      state.linesalarms.push(line);
    },
    editLine(state, line) {
      //console.log(user);
      const index = state.lines.findIndex((u) => u._id === line.id);
      state.lines.splice(index, 1, line);
    },
    deleteLine(state, id) {
      const index = state.lines.findIndex((u) => u._id === id);
      state.lines.splice(index, 1);
    },
    setLinesAlarms(state, linesalarms) {
      state.linesalarms = linesalarms;
    },
    deleteAlarmLine(state, id) {
      const index = state.linesalarms.findIndex((u) => u._id === id);
      state.linesalarms.splice(index, 1);
    },
  },
  actions: {
    async getLines(context, id) {
      console.log(id);
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setLine", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createLine(context, data) {
      console.log(data);
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createLine", res.data.data);
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteLine(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteLine", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editLine(context, data) {
      console.log(data);
      return axios
        .put(apiURL + data._id, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("editLine", data);
          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    async getLinesAlarms(context, id) {
      return axios
        .get(apiURL + "alarms/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setLinesAlarms", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createAlarmLine(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "alarms/", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createAlarmLine", res.data.data);
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteAlarmLine(context, id) {
      return axios
        .delete(apiURL + "alarms/" + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteAlarmLine", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
