import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { accounts } from "./accounts.module";
import { farms } from "./farms.module";
import { towers } from "./towers.module";
import { owners } from "./owners.module";
import { settingTower } from "./settingTower.module";
import { reports } from "./reports.module";
import { airs } from "./airs.module";
import { realtimes } from "./realtimes.module";
import { alarms } from "./alarms.module";
import { histories } from "./histories.module";
import { events } from "./events.module";
import { lines } from "./lines.module";
import { samplings } from "./samplings.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    accounts,
    farms,
    towers,
    owners,
    settingTower,
    reports,
    airs,
    realtimes,
    alarms,
    histories,
    events,
    lines,
    samplings,
  },
});
