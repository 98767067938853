import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "towers/";

import authHeader from "../services/auth-header";
export const towers = {
  state: {
    towers: [],
    tower: null,
    //realtimeTower: null,
  },
  getters: {
    OneTower: (state) => state.tower,
    allTowers: (state) => state.towers,
  },
  mutations: {
    setOneTower(state, tower) {
      state.tower = tower;
    },
    setTowers(state, towers) {
      state.towers = towers;
    },
    SOCKET_tower(state, payload) {
      console.log(payload);
      let data = payload;
      if (state.towers != []) {
        const index = state.towers.findIndex((t) => t._id === data._id);
        if (index > -1) {
          if (data.Layer != undefined) {
            state.towers[index].Layer = data.Layer;
          } else if (data.cleanSetting != undefined) {
            state.towers[index].cleanSetting = data.cleanSetting;
          } else if (data.pondingSetting != undefined) {
            state.towers[index].pondingSetting = data.pondingSetting;
          } else if (data.cleanNutrientSetting != undefined) {
            state.towers[index].cleanNutrientSetting =
              data.cleanNutrientSetting;
          } else if (data.MainProgram != undefined) {
            state.towers[index].MainProgram = data.MainProgram;
          } else if (data.Valve != undefined) {
            state.towers[index].Valve = data.Valve;
          } else if (data.Pump != undefined) {
            state.towers[index].Pump = data.Pump;
          } else if (data.Level != undefined) {
            state.towers[index].Level = data.Level;
          } else if (data.pHSetting != undefined) {
            state.towers[index].pHSetting = data.pHSetting;
          } else if (data.ECSetting != undefined) {
            state.towers[index].ECSetting = data.ECSetting;
          } else if (data.errSensor != undefined) {
            state.towers[index].errSensor = data.errSensor;
          } else if (data.DO != undefined) {
            state.towers[index].DO = data.DO;
          } else if (data.Luminous != undefined) {
            state.towers[index].Luminous = data.Luminous;
          }
        }
      }
      if (state.tower != null) {
        if (data._id == state.tower._id) {
          if (data.Layer != undefined) {
            state.tower.Layer = data.Layer;
          } else if (data.cleanSetting != undefined) {
            state.tower.cleanSetting = data.cleanSetting;
          } else if (data.pondingSetting != undefined) {
            state.tower.pondingSetting = data.pondingSetting;
          } else if (data.cleanNutrientSetting != undefined) {
            state.tower.cleanNutrientSetting = data.cleanNutrientSetting;
          } else if (data.MainProgram != undefined) {
            state.tower.MainProgram = data.MainProgram;
          } else if (data.Valve != undefined) {
            state.tower.Valve = data.Valve;
          } else if (data.Pump != undefined) {
            state.tower.Pump = data.Pump;
          } else if (data.Level != undefined) {
            state.tower.Level = data.Level;
          } else if (data.pHSetting != undefined) {
            state.tower.pHSetting = data.pHSetting;
          } else if (data.ECSetting != undefined) {
            state.tower.ECSetting = data.ECSetting;
          } else if (data.errSensor != undefined) {
            state.tower.errSensor = data.errSensor;
          }
        }
      }
    },
  },
  actions: {
    async getTowers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setTowers", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getTowerByID(context, id) {
      //console.log(id)
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setOneTower", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
