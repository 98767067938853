import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "events/";

import authHeader from "../services/auth-header";
export const events = {
  state: {
    events: [],
  },
  getters: {
    allEvents: (state) => state.events,
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    deleteEvent(state, id) {
      const index = state.events.findIndex((u) => u._id === id);
      state.events.splice(index, 1);
    },
  },
  actions: {
    async getEvents(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setEvents", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getEventBySearch(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "search", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setEvents", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async deleteEvent(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteEvent", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
