import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "owners/";
import authHeader from "../services/auth-header";
export const owners = {
  state: {
    owners: [],
  },
  getters: {
    allOwners: (state) => state.owners,
  },
  mutations: {
    setOwner(state, owner) {
      state.owners = owner;
    },
    createOwner(state, owner) {
      state.owners.push(owner);
    },
    editOwner(state, owner) {
      //console.log(user);
      const index = state.owners.findIndex((u) => u._id === owner._id);
      state.owners.splice(index, 1, owner);
    },
    deleteOwner(state, id) {
      const index = state.owners.findIndex((u) => u._id === id);
      state.owners.splice(index, 1);
    },
  },
  actions: {
    async getOwners(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setOwner", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createOwner(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("createOwner", res.data.data);
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteOwner(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteOwner", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editOwner(context, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          context.commit("editOwner", data);
          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
