<template>
  <highcharts
    style="
      min-width: 310px;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
    "
    :constructorType="'mapChart'"
    class="hc"
    :options="getOptions"
    ref="chart"
  ></highcharts>
</template>

<script>
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import proj4 from "proj4";

export default {
  computed: {
    getOptions() {
      let chartOptions = {
        chart: {
          map: worldMap,
          proj4: proj4,
          height: (9 / 16) * 100 + "%",
          backgroundColor: "#eef1fa",
        },
        title: {
          text: null,
        },
        accessibility: {
          series: {
            descriptionFormat:
              "{series.name}, map with {series.points.length} areas.",
            pointDescriptionEnabledThreshold: 50,
          },
        },
        tooltip: {
          headerFormat: "",
          pointFormat:
            "<b>{point.name}</b><br><b>{point.status}</b> <br><b>Owner: {point.owner.name}</b> <br>Lat: {point.lat}, Lon: {point.lon}",
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
            x: 10,
          },
        },
        colorAxis: {
          visible: false,
          start: 1,
          min: 1,
          max: 40,
          minColor: "#d19664",
          maxColor: "#fc7300",
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  console.log(this.options);
                  if (this.options.id != undefined) {
                    location.href =
                      process.env.VUE_APP_URL + "farm/" + this.options.id;
                  }
                  //
                },
              },
            },
          },
          mappoint: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (typeof this.point.clusterPointsAmount === "undefined") {
                  return "";
                } else {
                  return this.point.clusterPointsAmount;
                }
              },
            },
            cluster: {
              enabled: true,
              allowOverlap: true,
              min: "#bcb3fc",
              max: "#8676FF",
              animation: {
                duration: 450,
              },
              layoutAlgorithm: {
                type: "grid",
                gridSize: 50,
              },
              zones: [
                {
                  from: 1,
                  to: 10,
                  marker: {
                    radius: 15,
                  },
                },
                {
                  from: 11,
                  to: 20,
                  marker: {
                    radius: 15,
                  },
                },
                {
                  from: 21,
                  to: 30,
                  marker: {
                    radius: 15,
                  },
                },
                {
                  from: 31,
                  to: 200,
                  marker: {
                    radius: 15,
                  },
                },
              ],
            },
          },
        },
        series: [
          {
            // Use the gb-all map with no data as a basemap
            name: "Basemap",
            borderColor: "#cad1e3",
            borderWidth: 1,
            nullColor: "#FFFFFF",
            showInLegend: false,
            accessibility: {
              exposeAsGroupOnly: true,
            },
          },
          {
            type: "mappoint",
            enableMouseTracking: true,
            colorKey: "clusterPointsAmount",
            name: "Cities",
            data: [],
            marker: {
              lineWidth: 1,
              lineColor: "#fff",
              symbol: "mapmarker",
              radius: 10,
            },
            dataLabels: {
              verticalAlign: "top",
              enabled: true,
              shadow: true,
            },
          },
        ],
        credits: {
          enabled: false,
        },
      };
      chartOptions.series[1].data = this.$store.getters.chartOptions;
      return chartOptions;
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.highcharts-point {
  filter: drop-shadow(8px 16px 18px hsla(224, 28%, 56%, 0.418));
}
</style>
