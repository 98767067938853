<template>
  <div>
    <ApexChart
      type="line"
      :height="height"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "ChartSmall",
  props: {
    series: Array,
    options: Object,
    height: {
      type: String,
      default: "20%",
    },
  },
  computed: {
    chartOptions() {
      var chartOptions = {
        chart: {
          parentHeightOffset: 0,
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: true,
          },
          toolbar: {
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        grid: {
          show: false,
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: true,
            format: "dd/MMM/yyyy <br> HH:mm:ss",
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          decimalsInFloat: 2,
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm:ss",
            },
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          categories: this.options.xaxis.categories,
        },
      };
      return chartOptions;
    },
  },
};
</script>
