<template>
  <div class="mb-3">
    <v-row align="center" class="ma-1">
      <v-col cols="6" xs="6" sm="6" md="6" lg="6">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Overview
        </span>
      </v-col>
      <v-col align="end" cols="6" xs="6" sm="6" md="6" lg="6">
        <v-btn-toggle v-model="toggle" dense color="#384CFF">
          <v-btn class="text-upper" small> map </v-btn>
          <v-btn class="text-upper" small> dashboard </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
        <p
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
          "
        >
          All Farms
        </p>
        <p
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            margin-top: -4%;
            margin-bottom: -4%;
          "
        >
          {{ getFarms.length }}
        </p>
      </v-col>
      <v-col v-if="toggle == 0">
        <worldmap :options="getOptions" />
      </v-col>
      <v-col v-else>
        <v-row class="mb-2">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            style="padding-right: 0%"
          >
            <v-text-field
              v-model="search"
              class="mb-0"
              label="Search"
              dense
              outlined
              color="#384CFF"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="1" lg="1" style="color: #383874"
            >Country</v-col
          >
          <v-col cols="6" xs="6" sm="6" md="3" lg="3">
            <v-autocomplete
              dense
              :hide-details="true"
              color="#384CFF"
              :items="countrySelect"
              v-model="searchCountry"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="1" lg="1" style="color: #383874">
            Province
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="3" lg="3">
            <v-autocomplete
              dense
              :hide-details="true"
              color="#384CFF"
              :items="provinceSelect"
              v-model="searchProvince"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-col
          v-for="(item, index) in searchFarm"
          :key="index"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          style="padding-left: 0%; padding-right: 0%"
        >
          <v-card color="white" elevation="10">
            <v-row class="px-3" align="center" justify="center">
              <v-col cols="1">
                <span>
                  <v-tooltip top v-if="item.status">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="green darken-2"
                        style="font-size: 15px"
                      >
                        fas fa-circle
                      </v-icon>
                    </template>
                    <span>Online</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="#FE0315"
                        style="font-size: 15px"
                      >
                        fas fa-circle
                      </v-icon>
                    </template>
                    <span>Offline</span>
                  </v-tooltip>
                </span>
              </v-col>
              <v-col>
                <span
                  style="
                    color: #383874;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                  "
                >
                  {{ item.name }}
                </span>
              </v-col>
            </v-row>
            <v-col class="py-0 my-0">
              <p
                class="pb-0 mb-0"
                style="color: #383874; font-weight: lighter; font-size: 16px"
              >
                Owner: {{ item.owner.name }}
              </p>
            </v-col>
            <v-col class="py-0 my-0">
              <p class="pb-0 mb-0" style="color: #383874; font-size: 16px">
                Country: {{ item.country }}
              </p>
            </v-col>
            <v-col class="py-0 my-0">
              <p class="pb-0 mb-0" style="color: #383874; font-size: 16px">
                Province: {{ item.province }}
              </p>
            </v-col>
            <v-col class="py-0 my-0">
              <p
                class="pb-0 mb-0"
                style="color: #383874; font-style: normal; font-size: 16px"
              >
                Location: {{ item.location.lat }}, {{ item.location.lon }}
              </p>
            </v-col>
            <v-row class="mx-1">
              <v-spacer></v-spacer>
              <v-col cols="4" align="end">
                <v-btn
                  :to="{ name: 'Farm', params: { id: item.id } }"
                  dark
                  color="#384CFF"
                >
                  detail</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Map from "@/components/WorldMap";
import provinces from "@/config/provinces";
import countries from "@/config/countries";
export default {
  name: "DashboardPage",
  components: {
    worldmap: Map,
  },
  computed: {
    searchFarm: function () {
      if (this.searchCountry == "All") {
        return this.$store.getters.getFarms.filter((farm) => {
          console.log(farm);
          return farm.name.match(this.search);
        });
      } else if (this.searchProvince == "All") {
        return this.$store.getters.getFarms.filter((farm) => {
          console.log(farm);
          return (
            farm.country.match(this.searchCountry) &&
            farm.name.match(this.search)
          );
        });
      } else {
        return this.$store.getters.getFarms.filter((farm) => {
          console.log(farm);
          return (
            farm.country.match(this.searchCountry) &&
            farm.province.match(this.searchProvince) &&
            farm.name.match(this.search)
          );
        });
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getFarms() {
      return this.$store.getters.getFarms;
    },
    getOptions() {
      console.log(this.$store.getters.chartOptions);
      return this.$store.getters.chartOptions;
    },
    filterStations: function () {
      let farms = this.$store.getters.getFarms;
      return farms.filter((farm) => {
        return (
          farm.name.match(this.search) || farm.macAddress.match(this.search)
        );
      });
    },
    provinceSelect() {
      let newFirstElement = "All";
      let array = provinces;
      let newArray = [newFirstElement].concat(array);
      return newArray;
    },
    countrySelect() {
      let newFirstElement = "All";
      let array = countries;
      let newArray = [newFirstElement].concat(array);
      return newArray;
    },
  },
  async mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.$store.dispatch("getFarms");
    }
  },
  data() {
    return {
      toggle: 0,
      search: "",
      searchCountry: "Thailand",
      searchProvince: "Chonburi",
      chartOptions: {
        series: [
          {
            data: [1, 2, 3], // sample data
          },
        ],
      },
      items: [
        {
          text: "Overview",
          disabled: true,
          href: "breadcrumbs_dashboard",
        },
      ],
    };
  },
  methods: {
    handler() {
      var args = arguments;
      for (var arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },
  },
};
</script>
