<template>
  <div
    fluid
    :class="isMobile ? 'pb-10' : ''"
    v-resize="onResize"
    class="mb-3"
    v-if="!loading"
  >
    <v-breadcrumbs :items="items" style="padding: 15px 15px 0px">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mx-1">
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="mr-0">
        <v-row align="center" class="mt-1">
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDatapHEC('EC')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  EC
                </div>
                <Gauge
                  :value="
                    parseFloat(
                      getRealTime.Status.NutrientTank.EC[0].Value.toFixed(2)
                    )
                  "
                  :target="parseFloat(getTower.ECSetting.SetPoint.toFixed(2))"
                  height="55%"
                  :min="0.1"
                  :max="0.1"
                  :LCL="parseFloat(getTower.ECSetting.LCL.toFixed(2))"
                  :UCL="parseFloat(getTower.ECSetting.UCL.toFixed(2))"
                  :LSL="parseFloat(getTower.ECSetting.Low.toFixed(2))"
                  :USL="parseFloat(getTower.ECSetting.High.toFixed(2))"
                  unit="mS/cm"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[0].chartOptions"
                  :series="chartAll[0].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('Sensor EC')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  Error EC
                </div>
                <Gauge
                  :value="
                    parseFloat(
                      getRealTime.Status.NutrientTank.errSensor.ec.toFixed(2)
                    )
                  "
                  height="55%"
                  :min="0.1"
                  :max="0.1"
                  :LCL="parseFloat(getTower.errSensor.ec.LCL.toFixed(2))"
                  :UCL="parseFloat(getTower.errSensor.ec.UCL.toFixed(2))"
                  :LSL="parseFloat(getTower.errSensor.ec.Low.toFixed(2))"
                  :USL="parseFloat(getTower.errSensor.ec.High.toFixed(2))"
                  unit="%"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[1].chartOptions"
                  :series="chartAll[1].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="mt-1">
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDatapHEC('pH')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  pH
                </div>
                <Gauge
                  :value="
                    parseFloat(
                      getRealTime.Status.NutrientTank.PH[0].Value.toFixed(2)
                    )
                  "
                  :target="parseFloat(getTower.pHSetting.SetPoint.toFixed(2))"
                  height="55%"
                  :min="0.1"
                  :max="0.1"
                  :LCL="parseFloat(getTower.pHSetting.LCL.toFixed(2))"
                  :UCL="parseFloat(getTower.pHSetting.UCL.toFixed(2))"
                  :LSL="parseFloat(getTower.pHSetting.Low.toFixed(2))"
                  :USL="parseFloat(getTower.pHSetting.High.toFixed(2))"
                  unit=""
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[2].chartOptions"
                  :series="chartAll[2].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('Sensor pH')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  Error pH
                </div>
                <Gauge
                  :value="
                    parseFloat(
                      getRealTime.Status.NutrientTank.errSensor.ph.toFixed(2)
                    )
                  "
                  height="55%"
                  :min="0.1"
                  :max="0.1"
                  :LCL="parseFloat(getTower.errSensor.ph.LCL.toFixed(2))"
                  :UCL="parseFloat(getTower.errSensor.ph.UCL.toFixed(2))"
                  :LSL="parseFloat(getTower.errSensor.ph.Low.toFixed(2))"
                  :USL="parseFloat(getTower.errSensor.ph.High.toFixed(2))"
                  unit="%"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[3].chartOptions"
                  :series="chartAll[3].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="mt-1">
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('DO')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  DO
                </div>
                <Gauge
                  :value="
                    parseFloat(getRealTime.Status.NutrientTank.DO.toFixed(2))
                  "
                  height="55%"
                  :min="1"
                  :max="1"
                  :LCL="getTower.DO.LCL"
                  :UCL="getTower.DO.UCL"
                  :LSL="getTower.DO.Low"
                  :USL="getTower.DO.High"
                  style="margin-top: 1%"
                  unit="mg/L"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[4].chartOptions"
                  :series="chartAll[4].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('Luminous')">
                  <i class="fas fa-cog" style="font-size: 18px"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  Luminous
                </div>
                <Gauge
                  :value="getRealTime.Luminous"
                  height="55%"
                  :min="10"
                  :max="10"
                  :LCL="getTower.Luminous.LCL"
                  :UCL="getTower.Luminous.UCL"
                  :LSL="getTower.Luminous.Low"
                  :USL="getTower.Luminous.High"
                  unit="µmol/m^2.s"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[5].chartOptions"
                  :series="chartAll[5].series"
                  height="15%"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col cols="12" class="pr-1">
            <v-card>
              <div
                class="py-3"
                justify="center"
                align="center"
                style="font-weight: bold; font-size: medium; color: #383874"
              >
                Main Program
              </div>
              <v-row class="mx-1">
                <v-col>
                  <v-btn
                    :color="getRealTime.MainProgram ? 'success' : ''"
                    @click="StartProgram(true)"
                    :disabled="getAccess.role == 'User'"
                    small
                    >Start
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-end">
                  <v-btn
                    :color="!getRealTime.MainProgram ? 'error' : ''"
                    @click="StopProgram(true)"
                    :disabled="getAccess.role == 'User'"
                    small
                    >Stop
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" class="pr-1">
            <v-card>
              <div
                class="py-3"
                justify="center"
                align="center"
                style="font-weight: bold; font-size: medium; color: #383874"
              >
                Power Meter
              </div>
              <v-row class="mx-1" style="color: #383874; font-size: 85%">
                <v-col class="my-0 py-1"> Power </v-col>
                <v-col class="my-0 py-1 text-right">
                  {{ getRealTime.powerMeter.power.toFixed(2) }} W
                </v-col>
              </v-row>
              <v-row class="mx-1" style="color: #383874; font-size: 85%">
                <v-col class="my-0 py-1"> Current </v-col>
                <v-col class="my-0 py-1 text-right">
                  {{ getRealTime.powerMeter.current.toFixed(2) }} A
                </v-col>
              </v-row>
              <v-row class="mx-1" style="color: #383874; font-size: 85%">
                <v-col class="my-0 pt-1 pb-3"> Voltage </v-col>
                <v-col class="my-0 pt-1 pb-3 text-right">
                  {{ getRealTime.powerMeter.voltage.toFixed(2) }} V
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="9"
        lg="9"
        md="9"
        sm="12"
        xs="12"
        align="center"
        justify="center"
      >
        <Tower />
      </v-col>
    </v-row>
    <v-dialog v-model="modals.valve" max-width="300px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
        >
          {{ this.dataShowV.Name }}
        </v-card-title>
        <v-card-text>
          <p
            class="mb-2 text-left"
            style="text-transform: uppercase; font-size: 13px"
          >
            {{ this.dataShowV.Description }}
          </p>

          <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
            <h5 class="text-center" style="text-transform: uppercase">
              {{ getRealTime.Valve[dataShowV.No].Mode }}
            </h5>
          </div>

          <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
            <h5 v-if="getRealTime.Valve[dataShowV.No].On" class="text-center">
              OPENED
            </h5>
            <h5 v-else class="text-center">CLOSED</h5>
          </div>
          <h5 class="mb-1 mt-3 text-center" style="text-transform: uppercase">
            mode control
          </h5>
          <div class="row">
            <div class="col-6">
              <v-btn
                :color="this.dataShowV.Mode == 'Auto' ? '#384CFF' : ''"
                :dark="this.dataShowV.Mode == 'Auto'"
                @click="setModeV('Auto')"
                class="btn-wd"
              >
                AUTO
              </v-btn>
            </div>
            <div class="col-6 text-right">
              <v-btn
                class="btn-wd"
                :color="this.dataShowV.Mode == 'Manual' ? '#384CFF' : ''"
                :dark="this.dataShowV.Mode == 'Manual'"
                @click="setModeV('Manual')"
              >
                MANUAL
              </v-btn>
            </div>
          </div>
          <h5 class="mb-1 mt-2 text-center" style="text-transform: uppercase">
            MANUAL control
          </h5>
          <div class="row">
            <div class="col-6">
              <v-btn
                :color="this.dataShowV.setOn == true ? 'success' : ''"
                :disabled="this.dataShowV.Mode == 'Auto'"
                class="btn-wd"
                @click="setOnOffV(true)"
                >OPEN</v-btn
              >
            </div>
            <div class="col-6 text-right">
              <v-btn
                class="btn-wd"
                :color="!this.dataShowV.setOn ? 'error' : ''"
                :disabled="this.dataShowV.Mode == 'Auto'"
                @click="setOnOffV(false)"
                >CLose
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn text class="text-center mb-2" color="#384CFF" @click="close()"
            >CLOSE</v-btn
          >
          <v-spacer> </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.drain" max-width="300px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
          primary-title
          class="justify-center"
        >
          Program Drain control
        </v-card-title>
        <v-card-text>
          <h5>Start Date</h5>
          <date-picker
            v-model="rangeDate.start"
            type="datetime"
            format="DD/MM/YYYY HH:mm"
            value-type="format"
            placeholder="Start Date"
            style="width: 100%"
          ></date-picker>
          <h5 style="margin-bottom: 4px">End Date</h5>
          <date-picker
            v-model="rangeDate.end"
            type="datetime"
            format="DD/MM/YYYY HH:mm"
            value-type="format"
            placeholder="End Date"
            style="width: 100%"
          ></date-picker>
        </v-card-text>
        <v-card-actions class="mx-2 mt-0 pb-2">
          <v-btn
            class="btn-wd mb-3"
            text
            color="#384CFF"
            dark
            style="font-size: 14px"
            @click="setAutoDrain()"
            >UPDATE
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="btn-wd mb-3" text @click.native="close()">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.pump" max-width="300px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
        >
          <v-row>
            <v-col>
              {{ this.dataShowPump.Name }}
            </v-col>
            <v-col align="end">
              <v-btn
                rounded
                small
                color="#384CFF"
                dark
                @click="modals.level = true"
                >SET LEVEL
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p
            class="mb-2 text-left"
            style="text-transform: uppercase; font-size: 13px"
          >
            {{ this.dataShowPump.Description }}
          </p>

          <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
            <h5 class="text-center" style="text-transform: uppercase">
              {{ getRealTime.Pump[dataShowPump.No - 1].Mode }}
            </h5>
          </div>
          <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
            <h5
              v-if="this.getRealTime.Pump[this.dataShowPump.No - 1].On"
              class="text-center"
            >
              OPENED
            </h5>
            <h5 v-else class="text-center">CLOSED</h5>
          </div>
          <h5 class="mb-1 mt-3 text-center" style="text-transform: uppercase">
            mode control
          </h5>
          <div class="row">
            <div class="col-6">
              <v-btn
                class="btn btn-outline btn-wd"
                :color="this.dataShowPump.Mode == 'Auto' ? '#384CFF' : ''"
                :dark="this.dataShowPump.Mode == 'Auto'"
                @click="setModePump('Auto')"
              >
                AUTO
              </v-btn>
            </div>
            <div class="col-6 text-right">
              <v-btn
                class="btn btn-outline btn-wd"
                :color="this.dataShowPump.Mode == 'Manual' ? '#384CFF' : ''"
                :dark="this.dataShowPump.Mode == 'Manual'"
                @click="setModePump('Manual')"
              >
                MANUAL
              </v-btn>
            </div>
          </div>
          <div>
            <h5 class="mb-1 mt-2 text-center" style="text-transform: uppercase">
              MANUAL control
            </h5>
            <div class="row">
              <div class="col-6">
                <v-btn
                  :color="this.dataShowPump.setOn == true ? 'success' : ''"
                  :disabled="this.dataShowPump.Mode == 'Auto'"
                  class="btn-wd"
                  @click="setOnOffPump(true)"
                  >OPEN</v-btn
                >
              </div>
              <div class="col-6 text-right">
                <v-btn
                  class="btn-wd"
                  :color="!this.dataShowPump.setOn ? 'error' : ''"
                  :disabled="this.dataShowPump.Mode == 'Auto'"
                  @click="setOnOffPump(false)"
                  >Close
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn text class="text-center mb-2" color="#384CFF" @click="close()"
            >CLOSE</v-btn
          >
          <v-spacer> </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.level" max-width="300px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
        >
          {{ this.dataShowLevel.Name }}
        </v-card-title>
        <v-card-text>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>HighHigh :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataShowLevel.HighHigh"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h5>%</h5>
            </v-col>
          </v-row>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>High :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataShowLevel.High"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h5>%</h5>
            </v-col>
          </v-row>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>SetPoint :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataShowLevel.SetPoint"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h5>%</h5>
            </v-col>
          </v-row>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>Low :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataShowLevel.Low"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h5>%</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-wd my-1"
            text
            color="#384CFF"
            style="font-size: 14px"
            @click="setLevel()"
            >UPDATE</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn class="btn-wd" text @click.native="closeLevel()">CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.setpHEC" max-width="380px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
          primary-title
          class="justify-center"
        >
          Setting Nutrient Water
        </v-card-title>
        <v-card-text class="mb-0 pb-0">
          <div>
            <div class="col-12 my-0 py-0 text-right">
              <v-btn-toggle dense v-model="typeSetting" color="#383874">
                <v-btn
                  small
                  v-for="(option, index) in datapHEC"
                  :key="index"
                  :value="option.name"
                >
                  {{ option.name }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <div v-if="typeSetting == 'pH'" class="col-12">
              <h4 class="mb-2 text-center" style="font-size: 13px">pH</h4>
              <v-card style="border: 1px solid #11cdef" class="pa-2">
                <v-row align="center">
                  <v-col cols="4" style="font-size: small"> Set Point : </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="text-right">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.SetPoint"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="5" style="font-size: small">
                    Deadband(%) :
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.Deadband"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <h5 class="text-center">CorseFeed</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6" style="font-size: small">
                    Time On (sec)
                  </v-col>
                  <v-col cols="6" style="font-size: small">
                    Time Off (sec)
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.CorseFeed.timeOn"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.CorseFeed.timeOff"
                      @keypress="isNum($event)"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <h5 class="text-center mt-1">FineFeed</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6"> Time On (sec) </v-col>
                  <v-col cols="6"> Time Off (sec) </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.FineFeed.timeOn"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.FineFeed.timeOff"
                      @keypress="isNum($event)"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <h5 class="text-center mt-1">Set Alarm</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6"> LSL </v-col>
                  <v-col cols="6"> USL </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.Low"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.High"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6"> LCL </v-col>
                  <v-col cols="6"> UCL </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.LCL"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetpH.UCL"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <div class="col-12 text-center pb-1">
                  <v-btn
                    small
                    dark
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    style="font-size: 12px"
                    @click="setpH()"
                    >UPDATE</v-btn
                  >
                </div>
              </v-card>
            </div>
            <div v-if="typeSetting == 'EC'" class="col-12">
              <h4 class="mb-2 text-center" style="font-size: 13px">EC</h4>
              <v-card style="border: 1px solid #11cdef" class="pa-2">
                <v-row align="center" class="pb-0">
                  <v-col cols="4" style="font-size: small"> Set Point : </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="text-right pb-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.SetPoint"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="5" style="font-size: small">
                    Deadband(%) :
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.Deadband"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <h5 class="text-center">CorseFeed</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6" style="font-size: small">
                    Time On (sec)
                  </v-col>
                  <v-col cols="6" style="font-size: small">
                    Time Off (sec)
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.CorseFeed.timeOn"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.CorseFeed.timeOff"
                      @keypress="isNum($event)"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <h5 class="text-center mt-1">FineFeed</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6"> Time On (sec) </v-col>
                  <v-col cols="6"> Time Off (sec) </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.FineFeed.timeOn"
                      @keypress="isNum($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.FineFeed.timeOff"
                      @keypress="isNum($event)"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <h5 class="text-center mt-1">Set Alarm</h5>
                <v-row justify="center" align="center">
                  <v-col cols="6"> LSL </v-col>
                  <v-col cols="6"> USL </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.Low"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.High"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6"> LCL </v-col>
                  <v-col cols="6"> UCL </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.LCL"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="false"
                      required
                      style="width: 100%"
                      v-model="dataSetEC.UCL"
                      type="Number"
                      hide-spin-buttons
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <div class="col-12 text-center pb-1">
                  <v-btn
                    small
                    dark
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    style="font-size: 12px"
                    @click="setEC()"
                    >UPDATE</v-btn
                  >
                </div>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-card-actions clas="mb-1">
          <v-spacer> </v-spacer>
          <v-btn
            class="btn-wd"
            text
            style="font-size: 12px"
            @click.native="close()"
            >CLOSE
          </v-btn>
          <v-spacer> </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.layer" max-width="350px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
          primary-title
          class="justify-center"
        >
          LAYER {{ this.dataShowLayer.numLayer }}
        </v-card-title>
        <v-card-text>
          <div class="col-12 mt-2">
            <v-select
              dense
              outlined
              hide-details
              v-model="dataShowLayer.functionLayer"
              class="form-control"
              :items="optionsFunction"
              item-text="text"
              item-value="value"
              @change="setfunctionLayer()"
            >
            </v-select>
          </div>
          <div v-if="dataShowLayer.functionLayer == 0">
            <div class="col-12 mx-0 text-right">
              <v-btn-toggle dense v-model="typeIndex" color="#383874">
                <v-btn small v-for="(option, index) in dataLayer" :key="index">
                  {{ option.name }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <div v-if="typeIndex == 0">
              <h5
                class="title-up mb-2 text-center"
                style="text-transform: uppercase"
              >
                status Light
              </h5>
              <div class="row my-0 py-0 mx-1" style="border: 1px solid #11cdef">
                <div
                  class="col-6 text-center my-0 pb-0"
                  style="color: #40a0b8; font-size: 14px"
                >
                  MODE
                </div>
                <div
                  class="col-6 text-center my-0 pb-0"
                  style="color: #40a0b8; font-size: 14px"
                >
                  STAGE
                </div>
                <div
                  class="col-6 text-center my-0 py-0"
                  style="font-size: 13px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].Mode }}
                </div>
                <div
                  class="col-6 text-center my-0 py-0"
                  style="font-size: 13px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].Stage }}
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="color: #40a0b8; font-size: 13px"
                >
                  WHITE
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="color: #40a0b8; font-size: 13px"
                >
                  RED
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="color: #40a0b8; font-size: 13px"
                >
                  BLUE
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="color: #40a0b8; font-size: 12px"
                >
                  FAR RED
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="font-size: 12px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].LED1 }}
                  %
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="font-size: 12px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].LED2 }}
                  %
                </div>
                <div
                  class="col-3 text-center my-0 py-0"
                  style="font-size: 12px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].LED3 }}
                  %
                </div>
                <div
                  class="col-3 text-center my-0 pt-0"
                  style="font-size: 12px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].LED4 }}
                  %
                </div>
              </div>

              <div class="row mt-0 pt-0">
                <div class="col-6">
                  <v-btn
                    class="btn-wd"
                    :color="this.dataShowLight.Mode == 'Auto' ? '#384CFF' : ''"
                    :dark="this.dataShowLight.Mode == 'Auto'"
                    @click="setLightMode('Auto')"
                  >
                    AUTO
                  </v-btn>
                </div>
                <div class="col-6 text-right">
                  <v-btn
                    :color="
                      this.dataShowLight.Mode == 'Manual' ? '#384CFF' : ''
                    "
                    :dark="this.dataShowLight.Mode == 'Manual'"
                    class="btn-wd"
                    @click="setLightMode('Manual')"
                  >
                    MANUAL
                  </v-btn>
                </div>
              </div>
              <div v-if="this.dataShowLight.Mode == 'Manual'" class="mt-3">
                <v-row align="center">
                  <v-col> WHITE : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowLight.Manual.LED[0].setPercen"
                      @keypress="isNum($event)"
                      @keyup.native.enter="setLightManual()"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Manual.LED[0].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  @drag-end="setLightManual()"
                  :clickable="false"
                ></vue-slider>
                <v-row align="center">
                  <v-col> RED : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowLight.Manual.LED[1].setPercen"
                      @keypress="isNum($event)"
                      @keyup.native.enter="setLightManual()"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Manual.LED[1].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  @drag-end="setLightManual()"
                  :clickable="false"
                ></vue-slider>
                <v-row align="center">
                  <v-col> BLUE : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowLight.Manual.LED[2].setPercen"
                      @keypress="isNum($event)"
                      @keyup.native.enter="setLightManual()"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Manual.LED[2].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                  @drag-end="setLightManual()"
                ></vue-slider>
                <v-row align="center">
                  <v-col>FAR RED : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowLight.Manual.LED[3].setPercen"
                      @keypress="isNum($event)"
                      @keyup.native.enter="setLightManual()"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Manual.LED[3].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                  @drag-end="setLightManual()"
                ></vue-slider>
              </div>
              <div v-if="this.dataShowLight.Mode == 'Auto'">
                <div align="center" class="mt-2">
                  <v-btn-toggle dense v-model="numStage" color="#383874">
                    <v-btn
                      small
                      v-for="(option, index) in dataStage"
                      :key="index"
                    >
                      {{ option.name }}</v-btn
                    >
                  </v-btn-toggle>
                </div>

                <!--  <button @click="setStage()"> -->
                <h5>Start Date</h5>
                <date-picker
                  v-model="dateStage[numStage].startDate"
                  type="date"
                  value-type="format"
                  format="DD/MM/YYYY"
                  placeholder="Start Date"
                  style="width: 100%"
                ></date-picker>

                <h5 style="margin-bottom: 4px">End Date</h5>
                <date-picker
                  v-model="dateStage[numStage].endDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="format"
                  placeholder="End date"
                  style="width: 100%"
                ></date-picker>

                <div class="row mt-2 text-center">
                  <div class="col-6">TimeOn:</div>
                  <div class="col-6">TimeOff:</div>
                </div>
                <div class="row text-center">
                  <div class="col-6 mt-0 pt-0">
                    <vue-timepicker
                      input-width="70px"
                      manual-input
                      v-model="timeStageOn[numStage]"
                    ></vue-timepicker>
                  </div>
                  <div class="col-6 mt-0 pt-0 text-center">
                    <vue-timepicker
                      input-width="70px"
                      manual-input
                      v-model="timeStageOff[numStage]"
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="text-right mt-1">
                  <v-btn
                    small
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    dark
                    style="font-size: 12px"
                    @click="setStage()"
                    >UPDATE</v-btn
                  >
                </div>
                <v-row align="center" class="pt-1">
                  <v-col> WHITE : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="
                        dataShowLight.Auto[numStage].LED[0].setPercen
                      "
                      @keypress="isNum($event)"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Auto[numStage].LED[0].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                ></vue-slider>
                <v-row align="center" class="pt-1">
                  <v-col> RED : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="
                        dataShowLight.Auto[numStage].LED[1].setPercen
                      "
                      @keypress="isNum($event)"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Auto[numStage].LED[1].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                ></vue-slider>
                <v-row align="center" class="pt-1">
                  <v-col> BLUE : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="
                        dataShowLight.Auto[numStage].LED[2].setPercen
                      "
                      @keypress="isNum($event)"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Auto[numStage].LED[2].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                ></vue-slider>
                <v-row align="center" class="pt-1">
                  <v-col> FAR-RED : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="
                        dataShowLight.Auto[numStage].LED[3].setPercen
                      "
                      @keypress="isNum($event)"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowLight.Auto[numStage].LED[3].setPercen"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                ></vue-slider>
                <div class="text-right">
                  <v-btn
                    small
                    dark
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    style="font-size: 12px"
                    @click="setLightAuto()"
                    >UPDATE</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h5
                class="title-up mb-1 mt-1 text-center"
                style="text-transform: uppercase"
              >
                Status Fan
              </h5>
              <div class="row my-0 py-0 mx-1" style="border: 1px solid #11cdef">
                <div
                  class="col-4 text-center my-0 pb-0"
                  style="color: #40a0b8; font-size: 14px"
                >
                  MODE
                </div>
                <div
                  class="col-4 text-center my-0 pb-0"
                  style="color: #40a0b8; font-size: 14px"
                >
                  STAGE
                </div>
                <div
                  class="col-4 text-center my-0 pb-0"
                  style="color: #40a0b8; font-size: 14px"
                >
                  Fan
                </div>
                <div
                  class="col-4 text-center my-0 py-0"
                  style="font-size: 13px"
                >
                  {{ this.getRealTime.Fan[this.dataShowFan.No - 1].Mode }}
                </div>
                <div
                  class="col-4 text-center my-0 py-0"
                  style="font-size: 13px"
                >
                  {{ this.getRealTime.Light[this.dataShowLight.No - 1].Stage }}
                </div>
                <div
                  class="col-4 text-center my-0 py-0"
                  style="font-size: 13px"
                >
                  {{ this.getRealTime.Fan[this.dataShowFan.No - 1].Speed }} %
                </div>
              </div>
              <div class="row mt-0 pt-0">
                <div class="col-6">
                  <v-btn
                    class="btn-wd"
                    :color="this.dataShowFan.Mode == 'Auto' ? '#384CFF' : ''"
                    :dark="this.dataShowFan.Mode == 'Auto'"
                    @click="setFanMode('Auto')"
                  >
                    AUTO
                  </v-btn>
                </div>
                <div class="col-6 text-right">
                  <v-btn
                    :color="this.dataShowFan.Mode == 'Manual' ? '#384CFF' : ''"
                    :dark="this.dataShowFan.Mode == 'Manual'"
                    class="btn-wd"
                    @click="setFanMode('Manual')"
                  >
                    MANUAL
                  </v-btn>
                </div>
              </div>

              <div v-if="this.dataShowFan.Mode == 'Auto'">
                <div align="center" class="mt-2">
                  <v-btn-toggle dense v-model="numStage" color="#383874">
                    <v-btn
                      small
                      v-for="(option, index) in dataStage"
                      :key="index"
                    >
                      {{ option.name }}</v-btn
                    >
                  </v-btn-toggle>
                </div>

                <!--  <button @click="setStage()"> -->
                <h5>Start Date</h5>
                <date-picker
                  v-model="dateStage[numStage].startDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="format"
                  placeholder="Start Date"
                  style="width: 100%"
                ></date-picker>

                <h5 style="margin-bottom: 4px">End Date</h5>
                <date-picker
                  v-model="dateStage[numStage].endDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="format"
                  placeholder="End date"
                  style="width: 100%"
                ></date-picker>

                <div class="row mt-2 text-center">
                  <div class="col-6">TimeOn:</div>
                  <div class="col-6">TimeOff:</div>
                </div>
                <div class="row text-center">
                  <div class="col-6 mt-0 pt-0">
                    <vue-timepicker
                      input-width="70px"
                      manual-input
                      v-model="timeStageOn[numStage]"
                    ></vue-timepicker>
                  </div>
                  <div class="col-6 mt-0 pt-0 text-center">
                    <vue-timepicker
                      input-width="70px"
                      manual-input
                      v-model="timeStageOff[numStage]"
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="text-right mt-1">
                  <v-btn
                    small
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    dark
                    style="font-size: 12px"
                    @click="setStage()"
                    >UPDATE</v-btn
                  >
                </div>
                <v-row align="center" class="pt-1">
                  <v-col> Speed : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowFan.Auto[numStage].Speed"
                      @keypress="isNum($event)"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowFan.Auto[numStage].Speed"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                ></vue-slider>
                <div class="text-right">
                  <v-btn
                    small
                    class="btn-wd btn-sm my-1"
                    color="#384CFF"
                    dark
                    style="font-size: 12px"
                    @click="setFanAuto()"
                    >UPDATE</v-btn
                  >
                </div>
              </div>
              <div v-else class="mt-1">
                <v-row align="center" class="pt-1">
                  <v-col> Speed : </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      style="width: 85px; font-size: 1em"
                      dense
                      hide-details
                      filled
                      rounded
                      single-line
                      v-model.number="dataShowFan.Manual.Speed"
                      @keypress="isNum($event)"
                      @keyup.native.enter="setFanManual()"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <vue-slider
                  v-model="dataShowFan.Manual.Speed"
                  :min="0"
                  :max="100"
                  :tooltip-formatter="formatter1"
                  :clickable="false"
                  @drag-end="setFanManual()"
                ></vue-slider>
              </div>
            </div>
          </div>
          <div v-if="dataShowLayer.functionLayer == 1">
            <h4
              class="title-up mb-1 mt-n1 text-center"
              style="text-transform: uppercase"
            >
              Setting Program Clean RO
            </h4>
            <h5
              class="title-up mb-1 mt-1 text-center"
              style="text-transform: uppercase"
            >
              status
            </h5>
            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5 class="text-center" style="text-transform: uppercase">
                {{ this.getRealTime.cleanSetting.Mode }}
              </h5>
            </div>

            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5
                v-if="this.getRealTime.cleanSetting.Run"
                class="text-center"
                style="text-transform: uppercase"
              >
                START
              </h5>
              <h5 v-else class="text-center" style="text-transform: uppercase">
                stop
              </h5>
            </div>
            <div class="row mt-0 pt-0">
              <div class="col-6">
                <v-btn
                  class="btn-wd"
                  :color="this.dataProgramClean.Mode == 'Auto' ? '#384CFF' : ''"
                  :dark="this.dataProgramClean.Mode == 'Auto'"
                  @click="setModeClean('Auto')"
                >
                  AUTO
                </v-btn>
              </div>
              <div class="col-6 text-right">
                <v-btn
                  :color="
                    this.dataProgramClean.Mode == 'Manual' ? '#384CFF' : ''
                  "
                  :dark="this.dataProgramClean.Mode == 'Manual'"
                  class="btn-wd"
                  @click="setModeClean('Manual')"
                >
                  MANUAL
                </v-btn>
              </div>
            </div>

            <div v-if="this.dataProgramClean.Mode == 'Manual'">
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                MANUAL control
              </h5>
              <div class="row">
                <div class="col-6">
                  <v-btn
                    :color="dataProgramClean.Start ? 'success' : ''"
                    class="btn-wd"
                    @click="setStartClean(true)"
                    >START</v-btn
                  >
                </div>
                <div class="col-6 text-right">
                  <v-btn
                    class="btn-wd"
                    :color="dataProgramClean.Stop ? 'error' : ''"
                    text-right
                    @click="setStopClean(true)"
                    >STOP</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                AUTO control
              </h5>
              <h5>Start Date</h5>
              <date-picker
                v-model="rangeDateClean.start"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="Start date"
                style="width: 100%"
              ></date-picker>

              <h5 style="margin-bottom: 4px">End Date</h5>
              <date-picker
                v-model="rangeDateClean.end"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="End date"
                style="width: 100%"
              ></date-picker>

              <div class="text-right mt-2">
                <v-btn
                  small
                  dark
                  class="my-1 btn-sm"
                  color="#384CFF"
                  style="font-size: 14px"
                  @click="setAutoClean()"
                  >UPDATE</v-btn
                >
              </div>
            </div>
          </div>
          <div v-if="dataShowLayer.functionLayer == 2">
            <h4
              class="title-up mb-1 mt-n1 text-center"
              style="text-transform: uppercase"
            >
              Setting Program Clean Nutrient Tank
            </h4>
            <h5
              class="title-up mb-1 mt-1 text-center"
              style="text-transform: uppercase"
            >
              status
            </h5>
            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5 class="text-center" style="text-transform: uppercase">
                {{ this.getRealTime.cleanNutrientSetting.Mode }}
              </h5>
            </div>
            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5
                v-if="this.getRealTime.cleanNutrientSetting.Run"
                class="text-center"
                style="text-transform: uppercase"
              >
                START
              </h5>
              <h5 v-else class="text-center" style="text-transform: uppercase">
                stop
              </h5>
            </div>
            <div class="row mt-0 pt-0">
              <div class="col-6">
                <v-btn
                  class="btn-wd"
                  :color="
                    this.dataProgramCleanNutrient.Mode == 'Auto'
                      ? '#384CFF'
                      : ''
                  "
                  :dark="this.dataProgramCleanNutrient.Mode == 'Auto'"
                  @click="setModeCleanNutrient('Auto')"
                >
                  AUTO
                </v-btn>
              </div>
              <div class="col-6 text-right">
                <v-btn
                  :color="
                    this.dataProgramCleanNutrient.Mode == 'Manual'
                      ? '#384CFF'
                      : ''
                  "
                  :dark="this.dataProgramCleanNutrient.Mode == 'Manual'"
                  class="btn-wd"
                  @click="setModeCleanNutrient('Manual')"
                >
                  MANUAL
                </v-btn>
              </div>
            </div>

            <div v-if="this.dataProgramCleanNutrient.Mode == 'Manual'">
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                MANUAL control
              </h5>
              <div class="row">
                <div class="col-6">
                  <v-btn
                    :color="
                      typeOnOff || this.dataProgramCleanNutrient.Start == true
                        ? 'success'
                        : ''
                    "
                    class="btn-wd"
                    @click="setStartCleanNutrient(true)"
                    >START</v-btn
                  >
                </div>
                <div class="col-6 text-right">
                  <v-btn
                    class="btn-wd"
                    :color="
                      !typeOnOff || this.dataProgramCleanNutrient.Stop == true
                        ? 'error'
                        : ''
                    "
                    text-right
                    @click="setStopCleanNutrient(true)"
                    >STOP</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                AUTO control
              </h5>
              <h5>Start Date</h5>
              <date-picker
                v-model="rangeDateCleanNutrient.start"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="Start date"
                style="width: 100%"
              ></date-picker>

              <h5 style="margin-bottom: 4px">End Date</h5>
              <date-picker
                v-model="rangeDateCleanNutrient.end"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="End date"
                style="width: 100%"
              ></date-picker>
              <div class="text-right mt-2">
                <v-btn
                  small
                  dark
                  class="my-1 btn-sm"
                  color="#384CFF"
                  style="font-size: 14px"
                  @click="setAutoCleanNutrient()"
                  >UPDATE</v-btn
                >
              </div>
            </div>
          </div>
          <div v-if="dataShowLayer.functionLayer == 3">
            <h4
              class="title-up mb-1 mt-n1 text-center"
              style="text-transform: uppercase"
            >
              Setting Program Ponding
            </h4>
            <h5
              class="title-up mb-1 mt-1 text-center"
              style="text-transform: uppercase"
            >
              status
            </h5>
            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5 class="text-center" style="text-transform: uppercase">
                {{ this.getRealTime.pondingSetting.Mode }}
              </h5>
            </div>
            <div class="col-12 mt-3 py-2" style="border: 1px solid #11cdef">
              <h5
                v-if="this.getRealTime.pondingSetting.Run"
                class="text-center"
                style="text-transform: uppercase"
              >
                START
              </h5>
              <h5 v-else class="text-center" style="text-transform: uppercase">
                stop
              </h5>
            </div>
            <div class="row mt-0 pt-0">
              <div class="col-6">
                <v-btn
                  class="btn-wd"
                  :color="
                    this.dataProgramPonding.Mode == 'Auto' ? '#384CFF' : ''
                  "
                  :dark="this.dataProgramPonding.Mode == 'Auto'"
                  @click="setModePonding('Auto')"
                >
                  AUTO
                </v-btn>
              </div>
              <div class="col-6 text-right">
                <v-btn
                  :color="
                    this.dataProgramPonding.Mode == 'Manual' ? '#384CFF' : ''
                  "
                  :dark="this.dataProgramPonding.Mode == 'Manual'"
                  class="btn-wd"
                  @click="setModePonding('Manual')"
                >
                  MANUAL
                </v-btn>
              </div>
            </div>

            <div v-if="this.dataProgramPonding.Mode == 'Manual'">
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                MANUAL control
              </h5>
              <h5 style="margin-bottom: 4px">Time Drain Water RO (s)</h5>
              <v-text-field
                dense
                v-model="timeDrainRO.timeDrainRO"
                outlined
                @keypress="isNum($event)"
              >
              </v-text-field>
              <div class="row">
                <div class="col-6">
                  <v-btn
                    :color="
                      typeOnOff || this.dataProgramPonding.Start == true
                        ? 'success'
                        : ''
                    "
                    class="btn-wd"
                    @click="setStartPonding(true)"
                    >START</v-btn
                  >
                </div>
                <div class="col-6 text-right">
                  <v-btn
                    class="btn-wd"
                    :color="
                      !typeOnOff || this.dataProgramPonding.Stop == true
                        ? 'error'
                        : ''
                    "
                    text-right
                    @click="setStopPonding(true)"
                    >STOP</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h5
                class="mb-1 mt-2 text-center"
                style="text-transform: uppercase"
              >
                AUTO control
              </h5>
              <h5>Start Date</h5>
              <date-picker
                v-model="rangeDatePonding.start"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="Start date"
                style="width: 100%"
              ></date-picker>
              <h5 style="margin-bottom: 4px">End Date</h5>
              <date-picker
                v-model="rangeDatePonding.end"
                type="datetime"
                format="DD/MM/YYYY HH:mm"
                value-type="format"
                placeholder="Start date"
                style="width: 100%"
              ></date-picker>

              <h5 style="margin-bottom: 4px">Time Drain Water RO (s)</h5>
              <v-text-field
                dense
                v-model="timeDrainRO.timeDrainRO"
                outlined
                @keypress="isNum($event)"
              >
              </v-text-field>
              <div class="text-right mt-2">
                <v-btn
                  small
                  dark
                  class="my-1 btn-sm"
                  color="#384CFF"
                  style="font-size: 14px"
                  @click="setAutoPonding()"
                  >UPDATE</v-btn
                >
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions clas="mb-1">
          <v-spacer> </v-spacer>
          <v-btn
            class="btn-wd"
            text
            style="font-size: 12px"
            @click.native="close()"
            >CLOSE
          </v-btn>
          <v-spacer> </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.setalarm" max-width="380px" persistent scrollable>
      <v-card>
        <v-card-title
          style="text-transform: uppercase; font-size: 16px; font-weight: bold"
          primary-title
          class="justify-center"
        >
          Setting Alarm {{ typeSetting }}
        </v-card-title>
        <v-card-text class="mb-0 pb-0 px-2">
          <div>
            <div class="col-12">
              <v-row justify="center" align="center">
                <v-col cols="6"> LSL </v-col>
                <v-col cols="6"> USL </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 100%"
                    v-model="dataSetAlarm.Low"
                    type="Number"
                    hide-spin-buttons
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 100%"
                    v-model="dataSetAlarm.High"
                    type="Number"
                    hide-spin-buttons
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row justify="center" align="center" class="mt-0 pt-0">
                <v-col cols="6"> LCL </v-col>
                <v-col cols="6"> UCL </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 100%"
                    v-model="dataSetAlarm.LCL"
                    type="Number"
                    hide-spin-buttons
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 100%"
                    v-model="dataSetAlarm.UCL"
                    type="Number"
                    hide-spin-buttons
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </div>
          </div>
          <v-card-actions clas="mb-10" class="mt-0 pt-0">
            <v-btn
              small
              dark
              class="btn-wd btn-sm my-1"
              color="#384CFF"
              style="font-size: 12px"
              @click="setAlarm()"
              >UPDATE</v-btn
            >
            <v-spacer> </v-spacer>
            <v-btn
              small
              class="btn-wd text-end"
              text
              style="font-size: 12px"
              @click.native="close()"
              >CLOSE
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
  <div
    v-else
    align="center"
    justify="center"
    style="height: 400"
    :style="loading ? 'display: block;' : 'display: none;'"
  >
    <v-col cols="12">
      <v-progress-circular
        :size="60"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import Tower from "@/components/Tower";
import Cookies from "js-cookie";
import "d3";
import * as d3 from "d3";
import Gauge from "@/components/Gauge.vue";
import ChartSmall from "@/components/ChartSmall.vue";
export default {
  name: "DashboardPage",
  components: {
    Tower,
    Gauge,
    ChartSmall,
  },

  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    datapHEC() {
      return [{ name: "pH" }, { name: "EC" }];
    },
    getTower() {
      return this.$store.getters.OneTower;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getRealTime() {
      return this.$store.getters.realTimeTower;
    },
    dataLayer() {
      return [{ name: "Light" }, { name: "Fan" }];
    },
    dataStage() {
      return [{ name: "Stage 1" }, { name: "Stage 2" }, { name: "Stage 3" }];
    },
    items() {
      return [
        {
          text: "Overview",
          disabled: false,
          to: "/overview",
        },
        {
          text: "Farm",
          to: {
            name: "Farm",
            params: {
              id: this.$store.getters.OneTower.farm._id,
            },
          },
        },
        {
          text: "Tower",
          disabled: true,
        },
      ];
    },
  },
  async created() {
    if (this.$route.params.id != "" && this.$route.params.id != null) {
      this.idTower = this.$route.params.id;
      Cookies.set("idTower", this.idTower);
    } else {
      this.idTower = Cookies.get("idTower");
    }
    this.idFarm = Cookies.get("idFarm");
    this.getData();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.onResize);
  },
  async mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.getData();
      this.towerClick();
      window.addEventListener("resize", this.onResize);
    }
  },
  data() {
    return {
      isMobile: false,
      optionsLayer: [
        { text: "Layer1", value: 1 },
        { text: "Layer2", value: 2 },
        { text: "Layer3", value: 3 },
        { text: "Layer4", value: 4 },
        { text: "Layer5", value: 5 },
      ],
      optionsFunction: [
        { text: "Plant", value: 0 },
        { text: "Clean RO", value: 1 },
        { text: "Clean Tank", value: 2 },
        { text: "Ponding RO", value: 3 },
        { text: "Disable", value: 4 },
      ],
      optionsLevel: [
        { text: "LT101", value: 1, Description: "Nutrient Level Tank" },
        { text: "LT102", value: 2, Description: "Drain Level Tank" },
        { text: "LT103", value: 3, Description: "Fertilizer A Level Tank" },
        { text: "LT104", value: 4, Description: "Fertilizer B Level Tank" },
        { text: "LT105", value: 5, Description: "pH+ Level Tank" },
        { text: "LT106", value: 6, Description: "pH- Level Tank" },
      ],
      numLevel: 1,
      numLayer: 1,
      width: null,
      height: null,
      formatter1: "{value}%",
      numStage: 0,
      typeSetting: "pH",
      loading: true,
      snackbar: false,
      text: null,
      color: null,
      idFarm: null,
      idTower: null,
      macAddress: null,
      typeMode: null,
      typeOnOff: null,
      typeIndex: 0,
      dataShowV: {
        Mode: null,
        setOn: null,
        Name: null,
        Description: null,
        macAddress: null,
        tower: null,
        No: 0,
      },
      dataShowPump: {
        macAddress: null,
        tower: null,
        No: 1,
        Name: null,
        Mode: null,
        Description: null,
        setOn: null,
        timeOn: null,
        timeOff: null,
        timeMode: null,
      },
      dataShowLevel: {
        macAddress: null,
        tower: null,
        No: 0,
        Name: null,
        HighHigh: null,
        High: null,
        Low: null,
        SetPoint: null,
      },
      dataProgramDrain: {
        macAddress: null,
        tower: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
      },
      cleanStartDate: {
        date: null,
        time: null,
      },
      cleanEndDate: {
        date: null,
        time: null,
      },
      dataProgramClean: {
        Start: false,
        Stop: false,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        Mode: null,
        setStart: false,
        macAddress: null,
        tower: null,
      },
      dataProgramCleanNutrient: {
        Start: false,
        Stop: false,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        Mode: null,
        setStart: false,
        macAddress: null,
        tower: null,
        timeDrainRO: 0,
      },
      dataProgramPonding: {
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        Mode: null,
        Start: false,
        Stop: false,
        timeDrainRO: 0,
      },
      dataSetAlarm: {
        macAddress: null,
        tower: null,
        High: null,
        Low: null,
        LCL: null,
        UCL: null,
      },
      dataSetpH: {
        macAddress: null,
        tower: null,
        SetPoint: null,
        Deadband: null,
        High: null,
        Low: null,
        LCL: null,
        UCL: null,
        CorseFeed: {
          timeOn: null,
          timeOff: null,
        },
        FineFeed: {
          timeOn: null,
          timeOff: null,
        },
      },
      dataSetEC: {
        High: null,
        Low: null,
        LCL: null,
        UCL: null,
        macAddress: null,
        tower: null,
        SetPoint: null,
        Deadband: null,
        CorseFeed: {
          timeOn: null,
          timeOff: null,
        },
        FineFeed: {
          timeOn: null,
          timeOff: null,
        },
      },
      dataShowLayer: {
        numLayer: 0,
        Fan: null,
        functionLayer: 0,
        Stage: [],
        Light: [],
      },
      dataShowFan: {
        macAddress: null,
        tower: null,
        No: 0,
        Mode: null,
        Name: null,
        setOn: null,
      },
      dataShowLight: {
        macAddress: null,
        tower: null,
        No: 1,
        Auto: null,
        Enable: null,
        Manual: null,
        Mode: null,
      },
      modals: {
        layer: false,
        valve: false,
        pump: false,
        level: false,
        setpHEC: false,
        drain: false,
        clean: false,
        ponding: false,
        setalarm: false,
      },
      dateStage: [
        {
          startDate: "",
          endDate: "",
        },
        {
          startDate: "",
          endDate: "",
        },
        {
          startDate: "",
          endDate: "",
        },
      ],
      timeStageOn: [
        {
          HH: 0,
          mm: 0,
        },
        {
          HH: 0,
          mm: 0,
        },
        {
          HH: 0,
          mm: 0,
        },
      ],
      timeStageOff: [
        {
          HH: 0,
          mm: 0,
        },
        {
          HH: 0,
          mm: 0,
        },
        {
          HH: 0,
          mm: 0,
        },
      ],
      timeDrainOn: {
        HH: 0,
        mm: 0,
      },
      timeDrainOff: {
        HH: 0,
        mm: 0,
      },
      rangeDate: {
        start: "",
        end: "",
      },
      rangeDateClean: {
        start: "",
        end: "",
      },
      rangeDateCleanNutrient: {
        start: "",
        end: "",
      },
      rangeDatePonding: {
        start: "",
        end: "",
      },
      timeDrainRO: {
        timeDrainRO: 0,
      },
      chartAll: [],
    };
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    parseTime(data) {
      let time = [data.HH, data.mm];
      return time.join(":");
    },
    setfunctionLayer() {
      let data = {
        macAddress: this.macAddress,
        tower: this.idTower,
        No: this.dataShowLayer.numLayer,
        functionLayer: this.dataShowLayer.functionLayer,
      };
      //console.log(data);
      this.$store.dispatch("setfunctionLayer", data).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Function Layer success";
        },
        (error) => {
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    calDO(data) {
      let value = (data * 100) / 12;
      return value;
    },
    colorPH(data) {
      //console.log(data);
      if (data > -1 && data < 3) {
        return "#d46731";
      } else if (data > 3 && data < 8) {
        return "#2cb02e";
      } else if (data > 8 && data < 15) {
        return "#5a2cb0";
      }
    },
    calEC(data) {
      let value = (data * 100) / 5;
      return value;
    },
    calpH(data) {
      let value = (data * 100) / 14;
      return value;
    },
    formetDate() {
      const d = new Date();
      const offset = d.getTimezoneOffset();
      const date = new Date(d.getTime() - offset * 60 * 1000);
      let day = date.toISOString().split("T")[0];
      let start = day + "T00:00:00";
      let end = day + "T23:59:59";
      return [start, end];
    },
    async getData() {
      const days = this.formetDate();
      let data = {
        filter: "Farm",
        timeMode: "Day",
        timeStart: days[0],
        timeEnd: days[1],
        farm: this.idFarm,
        tower: null,
        type: [
          "EC1",
          "ErrorSensorEC",
          "pH1",
          "ErrorSensorpH",
          "DO",
          "Luminous",
        ],
      };
      this.$store.dispatch("getFarmByID", this.idFarm).then(() => {
        this.$store.dispatch("getTowerByID", this.idTower).then(() => {
          this.$store.dispatch("getRealTime", this.idFarm).then(() => {
            this.$store.dispatch("getReport", data).then((res) => {
              this.chartAll = res;
              this.loading = false;
            });
            //console.log(this.getTower);
            this.macAddress = this.getTower.farm.macAddress;
            this.idTower = this.getTower._id;
            this.dataProgramDrain.startDate =
              this.getTower.drainSetting.startDate;
            this.dataProgramDrain.endDate = this.getTower.drainSetting.endDate;
            let on = this.getTower.drainSetting.startTime.split(":");
            this.timeDrainOn.HH = on[0];
            this.timeDrainOn.mm = on[1];
            let off = this.getTower.drainSetting.endTime.split(":");
            this.timeDrainOff.HH = off[0];
            this.timeDrainOff.mm = off[1];
            this.setDataLevel(1);
          });
        });
      });
    },
    StartProgram(data) {
      let dataSent = {
        macAddress: this.macAddress,
        tower: this.idTower,
        start: data,
      };
      this.$store.dispatch("setStartProgram", dataSent).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Set Start Main Program success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    StopProgram(data) {
      let dataSent = {
        macAddress: this.macAddress,
        tower: this.idTower,
        stop: data,
      };
      this.$store.dispatch("setStopProgram", dataSent).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Set Stop Main Program success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setDataLevel(No) {
      let Level = this.getTower.Level;
      this.dataShowLevel.No = No;

      let numL = Level[No].Name.slice(3);
      this.dataShowLevel.Name = "LT1" + numL;
      this.dataShowLevel.HighHigh = Level[No].HighHigh;
      this.dataShowLevel.High = Level[No].High;
      this.dataShowLevel.Low = Level[No].Low;
      this.dataShowLevel.SetPoint = Level[No].SetPoint;
    },
    towerClick() {
      let name;
      let setDataPump = this.setDataPump;
      let setDataV = this.setDataV;
      let setDataDrain = this.setDataDrain;
      let setDatapHEC = this.setDatapHEC;
      let setDataLayer = this.setDataLayer;
      this.$store.dispatch("getFarmByID", Cookies.get("idFarm")).then(() => {
        this.$store
          .dispatch("getTowerByID", Cookies.get("idTower"))
          .then(() => {
            if (this.getAccess.role != "User") {
              d3.select("div").on("click", function (event) {
                name = event.target.id;
                //console.log(name);
                let v = name.search("V");
                let l = name.search("L");
                let p = name.search("P");
                if (v == 0) {
                  setDataV(name);
                } else if (p == 0) {
                  setDataPump(name);
                } else if (name == "setDrain") {
                  setDataDrain();
                } else if (name == "setpHEC") {
                  setDatapHEC("pH");
                } else if (l == 3) {
                  let No = parseInt(name.slice(8));
                  setDataLayer(No);
                  //console.log(Layer[1]);
                }
              });
            }
          });
      });
    },
    setDataV(name) {
      let num = name.slice(2);
      this.dataShowV.Name = name;

      if (num.charAt(0) == 0) {
        this.dataShowV.No = name.slice(3);
        this.dataShowV.Mode = this.getTower.Valve[name.slice(3)].Mode;
        this.dataShowV.setOn = this.getTower.Valve[name.slice(3)].setOn;
        this.dataShowV.Description =
          this.getTower.Valve[name.slice(3)].Description;
      } else {
        this.dataShowV.No = name.slice(2);
        this.dataShowV.Mode = this.getTower.Valve[num].Mode;
        this.dataShowV.setOn = this.getTower.Valve[num].setOn;
        this.dataShowV.Description = this.getTower.Valve[num].Description;
      }
      this.modals.valve = true;
    },
    setDataPump(name) {
      this.modals.pump = true;
      this.dataShowPump.Name = name;
      this.dataShowPump.No = name.slice(3);

      this.dataShowPump.Mode = this.getTower.Pump[name.slice(3)].Mode;
      this.dataShowPump.setOn = this.getTower.Pump[name.slice(3)].setOn;
      this.dataShowPump.Description =
        this.getTower.Pump[name.slice(3)].Description;

      if (this.dataShowPump.Name == "P102") {
        this.setDataLevel(1);
      } else if (this.dataShowPump.Name == "P104") {
        this.setDataLevel(2);
      } else if (this.dataShowPump.Name == "P105") {
        this.setDataLevel(3);
      } else if (this.dataShowPump.Name == "P106") {
        this.setDataLevel(4);
      } else if (this.dataShowPump.Name == "P107") {
        this.setDataLevel(5);
      } else if (this.dataShowPump.Name == "P108") {
        this.setDataLevel(6);
      } else if (this.dataShowPump.Name == "P101") {
        this.setDataLevel(7);
      }
    },
    setDataLayer(No) {
      console.log(No);
      console.log(this.dataShowLayer);
      console.log(this.getTower.Layer);
      this.dataShowLayer.numLayer = No;
      this.dataShowLayer.functionLayer = this.getTower.Layer[No].functionLayer;
      this.rangeDateClean.start =
        this.getTower.cleanSetting.startDate +
        " " +
        this.getTower.cleanSetting.startTime;
      this.rangeDateClean.end =
        this.getTower.cleanSetting.endDate +
        " " +
        this.getTower.cleanSetting.endTime;
      this.rangeDateCleanNutrient.start =
        this.getTower.cleanNutrientSetting.startDate +
        " " +
        this.getTower.cleanNutrientSetting.startTime;
      this.rangeDateCleanNutrient.end =
        this.getTower.cleanNutrientSetting.endDate +
        " " +
        this.getTower.cleanNutrientSetting.endTime;
      this.rangeDatePonding.start =
        this.getTower.pondingSetting.startDate +
        " " +
        this.getTower.pondingSetting.startTime;
      this.rangeDatePonding.end =
        this.getTower.pondingSetting.endDate +
        " " +
        this.getTower.pondingSetting.endTime;

      this.dataProgramPonding.Start = this.getTower.pondingSetting.Start;
      this.dataProgramPonding.Stop = this.getTower.pondingSetting.Stop;
      this.dataProgramPonding.Mode = this.getTower.pondingSetting.Mode;
      this.dataProgramPonding.timeDrainRO =
        this.getTower.pondingSetting.timeDrainRO;
      this.dataProgramPonding.startDate =
        this.getTower.pondingSetting.startDate;
      this.dataProgramPonding.endDate = this.getTower.pondingSetting.endDate;
      this.dataProgramPonding.startTime =
        this.getTower.pondingSetting.startTime;
      this.dataProgramPonding.endTime = this.getTower.pondingSetting.endTime;

      this.dataProgramClean.Start = this.getTower.cleanSetting.Start;
      this.dataProgramClean.Stop = this.getTower.cleanSetting.Stop;
      this.dataProgramClean.startDate = this.getTower.cleanSetting.startDate;
      this.dataProgramClean.endDate = this.getTower.cleanSetting.endDate;
      this.dataProgramClean.startTime = this.getTower.cleanSetting.startTime;
      this.dataProgramClean.endTime = this.getTower.cleanSetting.endTime;
      this.dataProgramClean.Mode = this.getTower.cleanSetting.Mode;
      this.dataProgramClean.setStart = this.getTower.cleanSetting.setStart;

      this.dataProgramCleanNutrient.Start =
        this.getTower.cleanNutrientSetting.Start;
      this.dataProgramCleanNutrient.Stop =
        this.getTower.cleanNutrientSetting.Stop;
      this.dataProgramCleanNutrient.startDate =
        this.getTower.cleanNutrientSetting.startDate;
      this.dataProgramCleanNutrient.endDate =
        this.getTower.cleanNutrientSetting.endDate;
      this.dataProgramCleanNutrient.startTime =
        this.getTower.cleanNutrientSetting.startTime;
      this.dataProgramCleanNutrient.endTime =
        this.getTower.cleanNutrientSetting.endTime;
      this.dataProgramCleanNutrient.Mode =
        this.getTower.cleanNutrientSetting.Mode;
      this.dataProgramCleanNutrient.setStart =
        this.getTower.cleanNutrientSetting.setStart;

      this.timeDrainRO.timeDrainRO = this.getTower.pondingSetting.timeDrainRO;

      this.dataShowLight.No = No;
      this.dataShowFan.No = No;
      this.dataShowLight.Mode = this.getTower.Layer[No].Light.Mode;
      this.dataShowLight.Manual = this.getTower.Layer[No].Light.Manual;
      this.dataShowLight.Auto = this.getTower.Layer[No].Light.Auto;

      this.dataShowFan.Mode = this.getTower.Layer[No].Fan.Mode;
      this.dataShowFan.Manual = this.getTower.Layer[No].Fan.Manual;
      this.dataShowFan.Auto = this.getTower.Layer[No].Fan.Auto;

      this.dateStage[0].startDate = this.getTower.Layer[No].Stage[0].startDate;
      this.dateStage[0].endDate = this.getTower.Layer[No].Stage[0].endDate;
      this.dateStage[1].startDate = this.getTower.Layer[No].Stage[1].startDate;
      this.dateStage[1].endDate = this.getTower.Layer[No].Stage[1].endDate;
      this.dateStage[2].startDate = this.getTower.Layer[No].Stage[2].startDate;
      this.dateStage[2].endDate = this.getTower.Layer[No].Stage[2].endDate;

      let on1 = this.getTower.Layer[No].Stage[0].timeOn.split(":");
      this.timeStageOn[0].HH = on1[0];
      this.timeStageOn[0].mm = on1[1];
      let off1 = this.getTower.Layer[No].Stage[0].timeOff.split(":");
      this.timeStageOff[0].HH = off1[0];
      this.timeStageOff[0].mm = off1[1];
      let on2 = this.getTower.Layer[No].Stage[1].timeOn.split(":");
      this.timeStageOn[1].HH = on2[0];
      this.timeStageOn[1].mm = on2[1];
      let off2 = this.getTower.Layer[No].Stage[1].timeOff.split(":");
      this.timeStageOff[1].HH = off2[0];
      this.timeStageOff[1].mm = off2[1];
      let on3 = this.getTower.Layer[No].Stage[2].timeOn.split(":");
      this.timeStageOn[2].HH = on3[0];
      this.timeStageOn[2].mm = on3[1];
      let off3 = this.getTower.Layer[No].Stage[2].timeOff.split(":");
      this.timeStageOff[2].HH = off3[0];
      this.timeStageOff[2].mm = off3[1];

      this.modals.layer = true;
    },
    setDataDrain() {
      this.dataProgramDrain.Days = this.getTower.drainSetting.Days;
      this.rangeDate.start =
        this.getTower.drainSetting.startDate +
        " " +
        this.getTower.drainSetting.startTime;
      this.rangeDate.end =
        this.getTower.drainSetting.endDate +
        " " +
        this.getTower.drainSetting.endTime;

      this.modals.drain = true;
    },
    setDatapHEC(data) {
      this.typeSetting = data;
      this.dataSetpH.SetPoint = this.getTower.pHSetting.SetPoint.toFixed(2);
      this.dataSetpH.Deadband = this.getTower.pHSetting.Deadband;
      this.dataSetpH.CorseFeed = this.getTower.pHSetting.CorseFeed;
      this.dataSetpH.FineFeed = this.getTower.pHSetting.FineFeed;
      this.dataSetpH.High = this.getTower.pHSetting.High.toFixed(2);
      this.dataSetpH.Low = this.getTower.pHSetting.Low.toFixed(2);
      this.dataSetpH.LCL = this.getTower.pHSetting.LCL.toFixed(2);
      this.dataSetpH.UCL = this.getTower.pHSetting.UCL.toFixed(2);

      this.dataSetEC.High = this.getTower.ECSetting.High.toFixed(2);
      this.dataSetEC.Low = this.getTower.ECSetting.Low.toFixed(2);
      this.dataSetEC.LCL = this.getTower.ECSetting.LCL.toFixed(2);
      this.dataSetEC.UCL = this.getTower.ECSetting.UCL.toFixed(2);
      this.dataSetEC.SetPoint = this.getTower.ECSetting.SetPoint.toFixed(2);
      this.dataSetEC.Deadband = this.getTower.ECSetting.Deadband;
      this.dataSetEC.CorseFeed = this.getTower.ECSetting.CorseFeed;
      this.dataSetEC.FineFeed = this.getTower.ECSetting.FineFeed;
      this.modals.setpHEC = true;
    },
    setDataAlarm(data) {
      this.typeSetting = data;
      if (this.typeSetting == "DO") {
        this.dataSetAlarm.High = this.getTower.DO.High;
        this.dataSetAlarm.Low = this.getTower.DO.Low;
        this.dataSetAlarm.LCL = this.getTower.DO.LCL;
        this.dataSetAlarm.UCL = this.getTower.DO.UCL;
      } else if (this.typeSetting == "Luminous") {
        this.dataSetAlarm.High = this.getTower.Luminous.High;
        this.dataSetAlarm.Low = this.getTower.Luminous.Low;
        this.dataSetAlarm.LCL = this.getTower.Luminous.LCL;
        this.dataSetAlarm.UCL = this.getTower.Luminous.UCL;
      } else if (this.typeSetting == "Sensor pH") {
        this.dataSetAlarm.High = this.getTower.errSensor.ph.High;
        this.dataSetAlarm.Low = this.getTower.errSensor.ph.Low;
        this.dataSetAlarm.LCL = this.getTower.errSensor.ph.LCL;
        this.dataSetAlarm.UCL = this.getTower.errSensor.ph.UCL;
      } else if (this.typeSetting == "Sensor EC") {
        this.dataSetAlarm.High = this.getTower.errSensor.ec.High;
        this.dataSetAlarm.Low = this.getTower.errSensor.ec.Low;
        this.dataSetAlarm.LCL = this.getTower.errSensor.ec.LCL;
        this.dataSetAlarm.UCL = this.getTower.errSensor.ec.UCL;
      }
      this.modals.setalarm = true;
    },
    setAlarm() {
      this.dataSetAlarm.tower = this.idTower;
      this.dataSetAlarm.macAddress = this.macAddress;
      this.dataSetAlarm.High = parseFloat(this.dataSetAlarm.High);
      this.dataSetAlarm.Low = parseFloat(this.dataSetAlarm.Low);
      this.dataSetAlarm.LCL = parseFloat(this.dataSetAlarm.LCL);
      this.dataSetAlarm.UCL = parseFloat(this.dataSetAlarm.UCL);
      let data = {
        macAddress: this.dataSetAlarm.macAddress,
        tower: this.dataSetAlarm.tower,
        data: {
          LCL: this.dataSetAlarm.LCL,
          UCL: this.dataSetAlarm.UCL,
          High: this.dataSetAlarm.High,
          Low: this.dataSetAlarm.Low,
        },
      };
      if (this.typeSetting == "DO") {
        this.$store.dispatch("setAlarmDO", data).then(
          () => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Setting DO success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;

            this.color = "error";
            this.text = error;
          }
        );
      } else if (this.typeSetting == "Luminous") {
        this.$store.dispatch("setAlarmLuminous", data).then(
          () => {
            this.snackbar = true;

            this.color = "success";
            this.text = "Setting Luminous success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;

            this.color = "error";
            this.text = error;
          }
        );
      } else if (this.typeSetting == "Sensor pH") {
        this.$store.dispatch("setAlarmSensorpH", data).then(
          () => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Setting Sensor pH success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;

            this.color = "error";
            this.text = error;
          }
        );
      } else if (this.typeSetting == "Sensor EC") {
        this.$store.dispatch("setAlarmSensorEC", data).then(
          () => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Setting Sensor EC success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;

            this.color = "error";
            this.text = error;
          }
        );
      }
    },
    closeLevel() {
      this.modals.level = false;
    },
    close() {
      this.modals.valve = false;
      this.modals.pump = false;
      this.typeMode = null;
      this.modals.setpHEC = false;
      this.modals.drain = false;
      this.modals.layer = false;
      this.modals.clean = false;
      this.modals.ponding = false;
      this.modals.setalarm = false;
      this.typeIndex = 0;
      this.numStage = 0;
      this.typeOnOff = null;
    },
    setModeV(data) {
      this.typeMode = data;
      this.typeOnOff = this.dataShowV.setOn;
      this.dataShowV.Mode = data;
      this.dataShowV.tower = this.idTower;
      this.dataShowV.macAddress = this.macAddress;

      console.log(this.dataShowV);
      this.$store.dispatch("setValve", this.dataShowV).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Mode" + this.dataShowV.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setOnOffV(data) {
      this.dataShowV.tower = this.idTower;
      this.dataShowV.macAddress = this.macAddress;
      this.typeOnOff = data;
      this.dataShowV.setOn = data;
      this.$store.dispatch("setValve", this.dataShowV).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting On/Off" + this.dataShowV.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setModePump(data) {
      this.typeMode = data;
      this.dataShowPump.Mode = data;
      this.dataShowPump.tower = this.idTower;
      this.dataShowPump.macAddress = this.macAddress;
      console.log(this.dataShowPump);
      this.$store.dispatch("setPump", this.dataShowPump).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Mode" + this.dataShowPump.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setOnOffPump(data) {
      this.typeOnOff = data;
      this.dataShowPump.setOn = data;
      this.dataShowPump.tower = this.idTower;
      this.dataShowPump.macAddress = this.macAddress;
      console.log(this.dataShowPump);
      this.$store.dispatch("setPump", this.dataShowPump).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Mode" + this.dataShowPump.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setAutoDrain() {
      this.dataProgramDrain.tower = this.idTower;
      this.dataProgramDrain.macAddress = this.macAddress;
      console.log(this.rangeDate.start);
      let [startDate, startTime] = this.rangeDate.start.split(" ");
      let [endDate, endTime] = this.rangeDate.end.split(" ");
      this.dataProgramDrain.startDate = startDate;
      this.dataProgramDrain.startTime = startTime;
      this.dataProgramDrain.endDate = endDate;
      this.dataProgramDrain.endTime = endTime;
      console.log(this.dataProgramDrain);
      this.$store.dispatch("drainSetting", this.dataProgramDrain).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Drain Program" + " success";
        },
        (error) => {
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setLevel() {
      this.dataShowLevel.tower = this.idTower;
      this.dataShowLevel.macAddress = this.macAddress;
      this.dataShowLevel.High = parseInt(this.dataShowLevel.High);
      this.dataShowLevel.HighHigh = parseInt(this.dataShowLevel.HighHigh);
      this.dataShowLevel.SetPoint = parseInt(this.dataShowLevel.SetPoint);
      this.dataShowLevel.Low = parseInt(this.dataShowLevel.Low);
      console.log(this.dataShowLevel);
      this.$store.dispatch("setLevel", this.dataShowLevel).then(
        () => {
          this.closeLevel();
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Mode " + this.dataShowLevel.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setpH() {
      this.dataSetpH.macAddress = this.macAddress;
      this.dataSetpH.tower = this.idTower;
      this.dataSetpH.SetPoint = parseFloat(this.dataSetpH.SetPoint);
      this.dataSetpH.High = parseFloat(this.dataSetpH.High);
      this.dataSetpH.Low = parseFloat(this.dataSetpH.Low);

      this.dataSetpH.LCL = parseFloat(this.dataSetpH.LCL);
      this.dataSetpH.UCL = parseFloat(this.dataSetpH.UCL);
      this.dataSetpH.Deadband = parseInt(this.dataSetpH.Deadband);
      this.dataSetpH.CorseFeed.timeOn = parseInt(
        this.dataSetpH.CorseFeed.timeOn
      );
      this.dataSetpH.CorseFeed.timeOff = parseInt(
        this.dataSetpH.CorseFeed.timeOff
      );
      this.dataSetpH.FineFeed.timeOn = parseInt(this.dataSetpH.FineFeed.timeOn);
      this.dataSetpH.FineFeed.timeOff = parseInt(
        this.dataSetpH.FineFeed.timeOff
      );
      this.$store.dispatch("pHSetting", this.dataSetpH).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting pH success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setEC() {
      this.dataSetEC.macAddress = this.macAddress;
      this.dataSetEC.tower = this.idTower;
      this.dataSetEC.SetPoint = parseFloat(this.dataSetEC.SetPoint);
      this.dataSetEC.Deadband = parseInt(this.dataSetEC.Deadband);
      this.dataSetEC.High = parseFloat(this.dataSetEC.High);
      this.dataSetEC.Low = parseFloat(this.dataSetEC.Low);
      this.dataSetEC.LCL = parseFloat(this.dataSetEC.LCL);
      this.dataSetEC.UCL = parseFloat(this.dataSetEC.UCL);
      this.dataSetEC.CorseFeed.timeOn = parseInt(
        this.dataSetEC.CorseFeed.timeOn
      );
      this.dataSetEC.CorseFeed.timeOff = parseInt(
        this.dataSetEC.CorseFeed.timeOff
      );
      this.dataSetEC.FineFeed.timeOn = parseInt(this.dataSetEC.FineFeed.timeOn);
      this.dataSetEC.FineFeed.timeOff = parseInt(
        this.dataSetEC.FineFeed.timeOff
      );
      this.$store.dispatch("ECSetting", this.dataSetEC).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting EC success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setLightMode(data) {
      this.dataShowLight.Mode = data;
      this.dataShowFan.Mode = data;
      this.dataShowLight.tower = this.idTower;
      this.dataShowLight.macAddress = this.macAddress;

      console.log(this.dataShowLight);
      this.$store.dispatch("setLightMode", this.dataShowLight).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text =
            "Setting Light in Layer" + this.dataShowLight.No + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setStage() {
      let sentdata = {
        macAddress: this.macAddress,
        tower: this.idTower,
        No: this.dataShowLayer.numLayer,
        Stage: [
          {
            name: "Stage1",
            Duration: 0,
            startDate: this.dateStage[0].startDate,
            endDate: this.dateStage[0].endDate,
            timeOn: this.parseTime(this.timeStageOn[0]),
            timeOff: this.parseTime(this.timeStageOff[0]),
          },
          {
            name: "Stage2",
            Duration: 0,
            startDate: this.dateStage[1].startDate,
            endDate: this.dateStage[1].endDate,
            timeOn: this.parseTime(this.timeStageOn[1]),
            timeOff: this.parseTime(this.timeStageOff[1]),
          },
          {
            name: "Stage3",
            Duration: 0,
            startDate: this.dateStage[2].startDate,
            endDate: this.dateStage[2].endDate,
            timeOn: this.parseTime(this.timeStageOn[2]),
            timeOff: this.parseTime(this.timeStageOff[2]),
          },
        ],
      };
      console.log(sentdata);
      this.$store.dispatch("setStage", sentdata).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Stage success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setLightManual() {
      this.dataShowLight.tower = this.idTower;
      this.dataShowLight.macAddress = this.macAddress;

      console.log(this.dataShowLight);
      this.$store.dispatch("setLightManual", this.dataShowLight).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text =
            "Setting Light in Layer" + this.dataShowLight.No + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setLightAuto() {
      this.dataShowLight.Auto[this.numStage].timeOn = this.parseTime(
        this.timeStageOn[this.numStage]
      );
      this.dataShowLight.Auto[this.numStage].timeOff = this.parseTime(
        this.timeStageOff[this.numStage]
      );
      this.dataShowLight.tower = this.idTower;
      this.dataShowLight.macAddress = this.macAddress;

      this.dataShowLight.No = this.dataShowLayer.numLayer;
      this.$store.dispatch("setLightAuto", this.dataShowLight).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text =
            "Setting Light in Layer" + this.dataShowLight.No + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setFanMode(mode) {
      this.dataShowFan.tower = this.idTower;
      this.dataShowFan.macAddress = this.macAddress;
      this.dataShowLight.Mode = mode;
      this.dataShowFan.Mode = mode;
      //this.dataShowLight.Mode = mode;
      //console.log(this.dataShowFan);
      this.$store.dispatch("setFanMode", this.dataShowFan).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting fan mode success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setFanManual() {
      let data = {
        macAddress: this.macAddress,
        tower: this.idTower,
        No: this.dataShowLayer.numLayer,
        Manual: {
          Speed: this.dataShowFan.Manual.Speed,
        },
      };
      this.$store.dispatch("setFanManual", data).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Fan Manual success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setFanAuto() {
      let datasent = {
        macAddress: this.macAddress,
        tower: this.idTower,
        No: this.dataShowLayer.numLayer,
        Auto: [
          {
            stage: "Stage1",
            Speed: this.dataShowFan.Auto[0].Speed,
          },
          {
            stage: "Stage2",
            Speed: this.dataShowFan.Auto[1].Speed,
          },
          {
            stage: "Stage3",
            Speed: this.dataShowFan.Auto[2].Speed,
          },
        ],
      };
      console.log(datasent);
      this.$store.dispatch("setFanAuto", datasent).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Fan in Layer" + datasent.No + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setStartClean(data) {
      console.log(data);
      this.typeOnOff = true;
      this.dataProgramClean.Mode = "Manual";
      this.dataProgramClean.Start = data;
      this.dataProgramClean.Stop = false;
      this.dataProgramClean.tower = this.idTower;
      this.dataProgramClean.macAddress = this.macAddress;
      this.$store.dispatch("setManualClean", this.dataProgramClean).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Clean success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setStopClean(data) {
      this.typeOnOff = false;
      this.dataProgramClean.Mode = "Manual";
      this.dataProgramClean.Stop = data;
      this.dataProgramClean.Start = false;
      this.dataProgramClean.tower = this.idTower;
      this.dataProgramClean.macAddress = this.macAddress;
      console.log(this.dataProgramClean);
      this.$store.dispatch("setManualClean", this.dataProgramClean).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Clean success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setAutoClean() {
      console.log(this.rangeDateClean);
      let [startDate, startTime] = this.rangeDateClean.start.split(" ");
      let [endDate, endTime] = this.rangeDateClean.end.split(" ");
      this.dataProgramClean.startDate = startDate;
      this.dataProgramClean.startTime = startTime;
      this.dataProgramClean.endDate = endDate;
      this.dataProgramClean.endTime = endTime;
      this.dataProgramClean.Mode = "Auto";
      this.dataProgramClean.tower = this.idTower;
      this.dataProgramClean.macAddress = this.macAddress;
      console.log(this.dataProgramClean);
      this.$store.dispatch("setAutoClean", this.dataProgramClean).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Clean success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setModeClean(data) {
      this.typeMode = data;
      this.dataProgramClean.Mode = data;
      this.dataProgramClean.tower = this.idTower;
      this.dataProgramClean.macAddress = this.macAddress;
      this.$store.dispatch("setModeClean", this.dataProgramClean).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Mode Program Clean success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setModeCleanNutrient(data) {
      this.typeMode = data;
      this.dataProgramCleanNutrient.Mode = data;
      this.dataProgramCleanNutrient.tower = this.idTower;
      this.dataProgramCleanNutrient.macAddress = this.macAddress;
      this.$store
        .dispatch("setModeCleanNutrient", this.dataProgramCleanNutrient)
        .then(
          () => {
            this.snackbar = true;

            this.color = "success";
            this.text = "Setting Mode Program Clean Nutrient success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = error;
          }
        );
    },
    setStartCleanNutrient(data) {
      console.log(data);
      this.typeOnOff = true;
      this.dataProgramCleanNutrient.Stop = false;
      this.dataProgramCleanNutrient.Start = data;
      let dataSent = {
        Mode: "Manual",
        Start: data,
        Stop: false,
        tower: this.idTower,
        macAddress: this.macAddress,
      };
      console.log(dataSent);
      this.$store.dispatch("setManualCleanNutrient", dataSent).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Clean Nutrient Tank success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setStopCleanNutrient(data) {
      this.typeOnOff = false;
      this.dataProgramCleanNutrient.Stop = data;
      this.dataProgramCleanNutrient.Start = false;
      let dataSent = {
        Mode: "Manual",
        Start: false,
        Stop: data,
        tower: this.idTower,
        macAddress: this.macAddress,
      };
      this.$store.dispatch("setManualCleanNutrient", dataSent).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Clean Nutrient Tank success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.type = "error";
          this.text = error;
        }
      );
    },
    setAutoCleanNutrient() {
      let [startDate, startTime] = this.rangeDateCleanNutrient.start.split(" ");
      let [endDate, endTime] = this.rangeDateCleanNutrient.end.split(" ");
      this.dataProgramCleanNutrient.startDate = startDate;
      this.dataProgramCleanNutrient.startTime = startTime;
      this.dataProgramCleanNutrient.endDate = endDate;
      this.dataProgramCleanNutrient.endTime = endTime;
      this.dataProgramCleanNutrient.Mode = "Auto";
      this.dataProgramCleanNutrient.tower = this.idTower;
      this.dataProgramCleanNutrient.macAddress = this.macAddress;
      console.log(this.dataProgramCleanNutrient);
      this.$store
        .dispatch("setAutoCleanNutrient", this.dataProgramCleanNutrient)
        .then(
          () => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Setting Manual Program Clean Nutrient Tank success";
          },
          (error) => {
            console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = error;
          }
        );
    },
    setModePonding(data) {
      this.typeMode = data;
      this.dataProgramPonding.Mode = data;
      this.dataProgramPonding.tower = this.idTower;
      this.dataProgramPonding.macAddress = this.macAddress;
      this.$store.dispatch("setModePonding", this.dataProgramPonding).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Mode Program Ponding success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setManualPonding(data) {
      this.typeOnOff = data;
      this.dataProgramPonding.Mode = "Manual";
      this.dataProgramPonding.setStart = data;
      this.dataProgramPonding.tower = this.idTower;
      this.dataProgramPonding.macAddress = this.macAddress;
      this.dataProgramPonding.timeDrainRO = parseInt(
        this.timeDrainRO.timeDrainRO
      );
      this.$store.dispatch("setManualPonding", this.dataProgramPonding).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Manual Program Ponding success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setStartPonding(data) {
      console.log(data);
      this.typeOnOff = true;
      this.dataProgramPonding.Mode = "Manual";
      this.dataProgramPonding.Start = data;
      this.dataProgramPonding.Stop = false;
      this.dataProgramPonding.tower = this.idTower;
      this.dataProgramPonding.macAddress = this.macAddress;
      this.dataProgramPonding.timeDrainRO = parseInt(
        this.timeDrainRO.timeDrainRO
      );

      this.$store.dispatch("setManualPonding", this.dataProgramPonding).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Manual Program Ponding success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
    setStopPonding(data) {
      this.typeOnOff = false;
      this.dataProgramPonding.Mode = "Manual";
      this.dataProgramPonding.Start = false;
      this.dataProgramPonding.Stop = data;
      this.dataProgramPonding.tower = this.idTower;
      this.dataProgramPonding.macAddress = this.macAddress;
      this.dataProgramPonding.timeDrainRO = parseInt(
        this.timeDrainRO.timeDrainRO
      );
      this.$store.dispatch("setManualPonding", this.dataProgramPonding).then(
        () => {
          this.snackbar = true;

          this.color = "success";
          this.text = "Setting Manual Program Ponding success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },

    setAutoPonding() {
      let [startDate, startTime] = this.rangeDatePonding.start.split(" ");
      let [endDate, endTime] = this.rangeDatePonding.end.split(" ");
      this.dataProgramPonding.startDate = startDate;
      this.dataProgramPonding.startTime = startTime;
      this.dataProgramPonding.endDate = endDate;
      this.dataProgramPonding.endTime = endTime;
      this.dataProgramPonding.tower = this.idTower;
      this.dataProgramPonding.macAddress = this.macAddress;
      this.dataProgramPonding.timeDrainRO = parseInt(
        this.timeDrainRO.timeDrainRO
      );
      //console.log(this.dataProgramPonding);
      this.$store.dispatch("setAutoPonding", this.dataProgramPonding).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Manual Program Ponding success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;

          this.color = "error";
          this.text = error;
        }
      );
    },
  },
};
</script>
<style>
>>> .v-dialog {
  overflow-y: visible;
}
.btn-wd {
  width: 80px;
}
</style>
