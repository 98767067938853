import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Overview from "../views/Overview.vue";
import Farm from "../views/Farm.vue";
import Tower from "../views/Tower.vue";
import ReportsPage from "../views/Report.vue";
import EventsPage from "../views/Events.vue";
import AlarmsPage from "../views/Alarms.vue";
import FarmsPage from "../views/Farms.vue";
import LineOwner from "../views/Lines";
import OwnersPage from "../views/Owners.vue";
import AccountPage from "../views/Accounts.vue";
import Home from "../views/Home";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
    meta: {
      breadcrumb: "Overview",
    },
  },
  {
    path: "/farm/:id",
    name: "Farm",
    component: Farm,
  },
  {
    path: "/tower/:id",
    name: "Tower",
    component: Tower,
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsPage,
  },
  {
    path: "/events",
    name: "Events",
    component: EventsPage,
  },
  {
    path: "/alarms",
    name: "Alarms",
    component: AlarmsPage,
  },
  {
    path: "/owners",
    name: "Owners",
    component: OwnersPage,
  },
  {
    path: "/owners/lines",
    name: "LineOwner",
    component: LineOwner,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: AccountPage,
  },
  {
    path: "/farms",
    name: "Farms",
    component: FarmsPage,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
