import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "realtimes/";

import authHeader from "../services/auth-header";
export const realtimes = {
  state: {
    realTime: null,
    realtimeTower: null,
  },
  getters: {
    realTime: (state) => state.realTime,
    realTimeTower: (state) => state.realtimeTower,
  },
  mutations: {
    setRealTime(state, realTime) {
      //console.log(realTime);
      if (this.state.towers.tower != null) {
        //console.log(this.state.towers.tower);
        const index = realTime.tower.findIndex(
          (t) => t.name === this.state.towers.tower.name
        );
        //console.log(index);
        state.realtimeTower = realTime.tower[index];
      }
      state.realTime = realTime;
    },
    SOCKET_status(state, payload) {
      //console.log(payload);
      if (state.realTime != null) {
        if (state.realTime.farm != undefined && payload != undefined) {
          let data = payload;
          if (
            data.farm == state.realTime.farm &&
            data.environment != undefined
          ) {
            state.realTime.environment = data.environment;
            //console.log(state.realTime.environment);
            localStorage.setItem("data", JSON.stringify(state.realTime));
          }
          if (
            data.farm == state.realTime.farm &&
            data.tower != undefined &&
            state.realtimeTower != null
          ) {
            //console.log(state.realtimeTower);
            //console.log(data.tower);
            if (state.realtimeTower.name == data.tower.name) {
              state.realtimeTower = data.tower;
            }
          }
        }
      }
    },
  },
  actions: {
    async getRealTime(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setRealTime", res.data.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
