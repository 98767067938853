import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "towers/";

import authHeader from "../services/auth-header";
export const settingTower = {
  actions: {
    async setValve(context, data) {
      return axios
        .post(
          apiURL + "setValve",
          {
            No: data.No,
            macAddress: data.macAddress,
            tower: data.tower,
            Name: data.Name,
            Number: data.Number,
            setOn: data.setOn,
            Mode: data.Mode,
            Description: data.Description,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setPump(context, data) {
      return axios
        .post(
          apiURL + "setPump",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Name: data.Name,
            Mode: data.Mode,
            Description: data.Description,
            setOn: data.setOn,
            timeOn: data.timeOn,
            timeOff: data.timeOff,
            timeMode: data.timeMode,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
          //console.log("Setting pump is successfully");
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async pHSetting(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "pHSetting", data, { headers: authHeader() })
        .then((res) => {
          //console.log("Setting pH is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async ECSetting(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "ECSetting", data, { headers: authHeader() })
        .then((res) => {
          //console.log("Setting EC is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setLevel(context, data) {
      return axios
        .post(
          apiURL + "setLevel",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Name: data.Name,
            HighHigh: data.HighHigh,
            High: data.High,
            Low: data.Low,
            SetPoint: data.SetPoint,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async drainSetting(context, data) {
      return axios
        .post(apiURL + "drainSetting", data, { headers: authHeader() })
        .then((res) => {
          //console.log("Setting Drain Program is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setLightMode(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "Light/Mode",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Mode: data.Mode,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setLightAuto(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "Light/Auto",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Auto: data.Auto,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setLightManual(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "Light/Manual",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Manual: data.Manual,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setModeClean(context, data) {
      return axios
        .post(
          apiURL + "cleanSetting/mode",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            Mode: data.Mode,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAutoClean(context, data) {
      return axios
        .post(
          apiURL + "cleanSetting/auto",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            startDate: data.startDate,
            endDate: data.endDate,
            startTime: data.startTime,
            endTime: data.endTime,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setManualClean(context, data) {
      return axios
        .post(apiURL + "cleanSetting/manual", data, {
          headers: authHeader(),
        })
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setfunctionLayer(context, data) {
      return axios
        .post(apiURL + "functionLayer", data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          //console.log("Setting function layer is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setFanMode(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "Fan/Mode", data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          //console.log("Setting fan mode is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setFanAuto(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "Fan/Auto",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            No: data.No,
            Auto: data.Auto,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Fan is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setFanManual(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "Fan/Manual", data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          //console.log("Setting Fan Manual is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setStartProgram(context, data) {
      return axios
        .post(apiURL + "MainProgram/start", data, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res);
          //console.log("Setting start Main Program is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setStopProgram(context, data) {
      return axios
        .post(apiURL + "MainProgram/stop", data, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res);
          console.log("Setting stop Main Program is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setModeCleanNutrient(context, data) {
      return axios
        .post(
          apiURL + "cleanNutrientSetting/mode",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            Mode: data.Mode,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAutoCleanNutrient(context, data) {
      return axios
        .post(
          apiURL + "cleanNutrientSetting/auto",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            startDate: data.startDate,
            endDate: data.endDate,
            startTime: data.startTime,
            endTime: data.endTime,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setManualCleanNutrient(context, data) {
      return axios
        .post(apiURL + "cleanNutrientSetting/manual", data, {
          headers: authHeader(),
        })
        .then(() => {
          console.log("Setting Program Clean is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setModePonding(context, data) {
      return axios
        .post(
          apiURL + "pondingSetting/mode",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            Mode: data.Mode,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Ponding is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAutoPonding(context, data) {
      return axios
        .post(
          apiURL + "pondingSetting/auto",
          {
            macAddress: data.macAddress,
            tower: data.tower,
            startDate: data.startDate,
            endDate: data.endDate,
            startTime: data.startTime,
            endTime: data.endTime,
            timeDrainRO: data.timeDrainRO,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Setting Program Auto Ponding is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setManualPonding(context, data) {
      return axios
        .post(apiURL + "pondingSetting/manual", data, {
          headers: authHeader(),
        })
        .then(() => {
          console.log("Setting Program Manual Ponding is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setStage(context, data) {
      return axios
        .post(apiURL + "setStage", data, {
          headers: authHeader(),
        })
        .then(() => {
          console.log("Setting Stage is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAlarmDO(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "setAlarm/do", data, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmLuminous(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "setAlarm/luminous/", data, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmSensorpH(context, data) {
      return axios
        .put(apiURL + "errSensor/ph/" + data.tower, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async setAlarmSensorEC(context, data) {
      return axios
        .put(apiURL + "errSensor/ec/" + data.tower, data, {
          headers: authHeader(),
        })
        .then((res) => {
          ////console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
};
