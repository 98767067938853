<template>
  <div class="mb-3">
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Owners
        </span>
      </v-col>
    </v-row>
    <v-card id="card" class="mx-5 mb-10">
      <v-card-title style="color: #383874; font-style: normal">
        Owners
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getOwners"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{ name: 'LineOwner', params: { data: item } }"
                v-if="getAccess.role === 'Superadmin'"
              >
                <v-btn icon color="green" v-bind="attrs" v-on="on"
                  ><v-icon>fas fa-brands fa-line</v-icon></v-btn
                >
              </router-link>
            </template>
            <span>Line</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="editOwnerData(item)"
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delOwnerData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position: fixed; bottom: 25px; right: 0px"
            class="ma-10"
            fixed
            fab
            color="#384CFF"
            dark
            absolute
            bottom
            right
            dense
            floating
            v-bind="attrs"
            v-on="on"
            @click="form = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>

      <v-dialog v-model="form" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Create Owner</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addOwner()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
                ref="ownerData"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="ownerData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn text @click="addOwner()" color="green"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Edit Owner</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editOwner()"
                ref="ownerEditData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="ownerEditData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn color="green" text @click="editOwner()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="confirmDelete" max-width="320">
        <v-card>
          <v-card-title style="color: #383874" primary-title>
            Confirm Delete
          </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this owner ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delOwner()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "OwnerPage",
  components: {},
  computed: {
    getOwners() {
      return this.$store.getters.allOwners;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role == "Superadmin") {
      this.$store.dispatch("getOwners");
    } else {
      this.$router.push("/");
    }
  },

  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
      form: false,
      search: null,
      confirmDelete: false,
      editform: false,
      ownerData: {
        _id: null,
        name: null,
      },
      default: {
        _id: null,
        name: null,
      },
      ownerEditData: {
        _id: null,
        name: null,
      },
      ownerDelId: null,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  methods: {
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.ownerData.name = null;
      this.ownerData = Object.assign({}, this.default);
    },
    delOwnerData(id) {
      this.confirmDelete = true;
      this.ownerDelId = id;
    },
    addOwner() {
      if (this.ownerData.name === "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create a owner.";
      } else {
        this.$store.dispatch("createOwner", this.ownerData).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.closeDlg();

            this.color = "success";
            this.text = "Account has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        });
      }
    },
    delOwner() {
      this.$store
        .dispatch("deleteOwner", this.ownerDelId)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Account has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this account.";
          this.snackbar = true;
        });
    },
    editOwnerData(data) {
      //console.log("data");
      this.editform = true;
      this.ownerEditData = Object.assign({}, data);
    },
    editOwner() {
      this.$store
        .dispatch("editOwner", this.ownerEditData)
        .then((res) => {
          //console.log('res.error.message');
          //console.log(res);
          if (res.data.message == "Sorry Name Is Already Exists") {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that name already exists!";
            this.snackbar = true;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Owner has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this owner.";
          this.snackbar = true;
        });
    },
  },
};
</script>
<style></style>
