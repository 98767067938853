import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "airs/";

import authHeader from "../services/auth-header";
export const airs = {
  state: {
    towers: [],
    tower: {},
    realtimeTower: null,
  },
  getters: {
    allAir: (state) => state.airs,
  },
  mutations: {
    getAir(state, airs) {
      state.airs = airs;
    },
  },
  actions: {
    async getAirs(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("getAir", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setAir(context, data) {
      console.log(data);
      return axios
        .put(apiURL + data.id, data, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
};
