<template>
  <div fluid :class="isMobile ? 'pb-10' : ''" v-resize="onResize">
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Alarm Events
        </span>
      </v-col>
    </v-row>
    <v-card id="card" class="mx-5 mb-10">
      <v-card-title style="color: #383874; font-style: normal">
        <v-tabs color="#383874" v-model="tab">
          <v-tab>Event</v-tab>
          <v-tab>History</v-tab>
        </v-tabs>
        <v-row justify="center" align="center">
          <v-spacer></v-spacer>
          <v-col align="end" cols="12" lg="2" md="2" sm="2">
            <v-autocomplete
              :items="getFarms"
              name="farm"
              label="Farm*"
              item-value="_id"
              item-text="name"
              v-model="datasearch.farm"
              hide-details="false"
              required
              @change="findTower(datasearch.farm)"
            >
            </v-autocomplete>
          </v-col>
          <v-col align="end" cols="12" lg="2" md="2" sm="2">
            <v-autocomplete
              :items="getTower"
              name="tower"
              label="Tower*"
              item-value="_id"
              item-text="name"
              v-model="datasearch.tower"
              hide-details="false"
              required
            >
            </v-autocomplete>
          </v-col>

          <v-col align="end" cols="12" xl="3" lg="3" md="3" sm="6" xs="12">
            <date-picker
              v-model="timeSearch"
              range
              valueType="format"
              format="DD/MM/YYYY"
              style="max-width: 50vw"
            ></date-picker>
          </v-col>
          <v-col align="end" cols="12" lg="1" md="2" sm="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#384CFF"
                  fab
                  small
                  dark
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="searchDate()"
                >
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-if="tab == 0"
        item-key="id"
        :headers="headersEvent"
        :items="getEvent"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delEventData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-data-table
        v-else
        item-key="id"
        :headers="headersHis"
        :items="getHistory"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
      </v-data-table>
      <div
        :class="
          isMobile
            ? 'table-footer-prepend-mobile d-flex align-center justify-center pb-2'
            : 'table-footer-prepend d-flex pl-2 align-center'
        "
      >
        <v-btn color="#384CFF" dark>
          <download-excel
            v-if="tab == 0"
            :fields="rowEvent"
            :data="getEvent"
            name="data_event.csv"
            type="csv"
          >
            Download Excel
          </download-excel>
          <download-excel
            v-else
            :data="getHistory"
            :fields="rowHis"
            name="data_history.csv"
            type="csv"
          >
            Download Excel
          </download-excel>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="confirmDelete" max-width="320">
      <v-card>
        <v-card-title style="color: #383874" primary-title>
          Confirm Delete
        </v-card-title>

        <v-card-text class="body">
          Are you sure to delete this event ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDlg()"> Cancel </v-btn>

          <v-btn color="error" text @click="delEvent()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showResult" :timeout="2000" top right>
      {{ result }}
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default {
  name: "EventPage",
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getEvent() {
      return this.$store.getters.allEvents;
    },
    getHistory() {
      return this.$store.getters.allHistory;
    },
    getFarms() {
      let towers = this.$store.getters.getFarms;
      const newArray = [{ _id: "", name: "All" }].concat(towers);
      return newArray;
    },
    getTower() {
      let towers = this.$store.getters.allTower;
      const newArray = [{ _id: "", name: "All" }].concat(towers);
      return newArray;
    },
    headersEvent() {
      let data = [
        {
          text: "Start Date",
          value: "startDate",
          sortable: true,
          width: "10%",
        },
        {
          text: "Start Time",
          value: "startTime",
          sortable: true,
          width: "10%",
        },
        { text: "Tower", value: "tower.name", sortable: true, width: "10%" },
        { text: "Farm", value: "farm.name", sortable: true, width: "10%" },
        { text: "Event", value: "event", sortable: true },
      ];
      if (this.getAccess.role == "User") {
        return data;
      } else {
        data.push({
          text: "Action",
          value: "actions",
          sortable: false,
          align: "center",
        });
        return data;
      }
    },
  },
  async mounted() {
    if (!this.currentUser || this.getAccess.role == "User") {
      this.$router.push("/");
    } else {
      this.$store.dispatch("getEvents");
      this.$store.dispatch("getFarms");
      this.$store.dispatch("getHistories");
    }
  },

  data() {
    return {
      tab: 0,
      timeSearch: [],
      eventDelId: null,
      confirmDelete: false,
      datasearch: {
        farm: "",
        tower: "",
        eventStart: "",
        eventEnd: "",
      },
      showResult: false,
      result: "",
      rowEvent: {
        "Start Date": "startDate",
        "Start Time": "startTime",
        Farm: "farm.name",
        Tower: "tower.name",
        Event: "event",
      },
      rowHis: {
        "Start Date": "startDate",
        "Start Time": "startTime",
        "End Date": "endDate",
        "End Time": "endTime",
        Farm: "farm.name",
        Tower: "tower.name",
        Event: "event",
      },
      headersHis: [
        {
          text: "Start Date",
          value: "startDate",
          sortable: true,
          width: "10%",
        },
        {
          text: "Start Time",
          value: "startTime",
          sortable: true,
          width: "10%",
        },
        { text: "End Date", value: "endDate", sortable: true, width: "10%" },
        { text: "End Time", value: "endTime", sortable: true, width: "10%" },
        { text: "Tower", value: "tower.name", sortable: true, width: "10%" },
        { text: "Farm", value: "farm.name", sortable: true, width: "10%" },
        { text: "Event", value: "event", sortable: true },
      ],
      isMobile: true,
    };
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    findTower(id) {
      this.$store.dispatch("getTowerByFarm", id);
    },
    searchDate() {
      this.datasearch.eventStart = this.timeSearch[0];
      this.datasearch.eventEnd = this.timeSearch[1];
      if (this.tab == 0) {
        this.$store.dispatch("getEventBySearch", this.datasearch);
      } else {
        this.$store.dispatch("getHisBySearch", this.datasearch);
      }
    },
    delEventData(id) {
      this.confirmDelete = true;
      this.eventDelId = id;
    },
    delEvent() {
      this.$store
        .dispatch("deleteEvent", this.eventDelId)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Event has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this event.";
          this.snackbar = true;
        });
    },
    closeDlg() {
      this.confirmDelete = false;
    },
  },
};
</script>
<style>
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
