import axios from "axios";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
const apiURL = process.env.VUE_APP_API_URL + "users/";
class AuthService {
  login(user) {
    return axios
      .post(apiURL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        //console.log(response.data)
        if (response.data.accessToken) {
          Cookies.set("user", JSON.stringify(response.data));
          return response.data;
        }
      });
  }
  logout() {
    Cookies.remove("user");
    Cookies.remove("nameBuliding");
    Cookies.remove("idLevel");
    Cookies.remove("nameLevel");
    Cookies.remove("ack");
  }
  resetPassword(payload) {
    return axios.put(
      apiURL + "resetPassword/" + payload.id,
      {
        newPassword: payload.newPassword,
      },
      { headers: authHeader() }
    );
  }
  changePassword(payload) {
    //console.log(payload);
    return axios.put(
      apiURL + "changePassword/" + payload.id,
      {
        newPassword: payload.newPassword,
        password: payload.password,
      },
      { headers: authHeader() }
    );
  }
  jwtDecode() {
    let data = Cookies.get("user");

    if (data) {
      const user = JSON.parse(data);
      //console.log(user);
      return jwt_decode(user.accessToken);
    }
  }
}
export default new AuthService();
