<template>
  <div>
    <highcharts :options="optionsVPD"></highcharts>
  </div>
</template>

<script>
export default {
  name: "GaugeChart",
  props: {
    height: {
      type: String,
      default: "55%",
    },
    value: Number,
    target: Number,
    LCL: Number,
    UCL: Number,
    LSL: Number,
    USL: Number,
    unit: String,
    min: Number,
    max: Number,
  },
  computed: {
    optionsVPD() {
      var gaugeOptions = {
        chart: {
          type: "gauge",
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: this.height,
          margin: [0, 10, 0, 10],
        },
        title: null,
        pane: {
          startAngle: -90,
          endAngle: 89.9,
          background: null,
          center: ["50%", "80%"],
          size: "130%",
        },
        // the value axis
        yAxis: {
          min: this.LSL - this.min,
          max: this.USL + this.max,
          tickPixelInterval: 72,
          tickPosition: "inside",
          tickPositioner: function () {
            var plotBands = this.options.plotBands,
              ticks = [],
              i = 0;
            for (i = 0; i < plotBands.length - 1; i++) {
              if (i == 0) {
                ticks.push(plotBands[i].from);
              }
              ticks.push(plotBands[i].to);
            }
            return ticks;
          },
          //tickPositioner: [this.LSL, this.LCL, this.target, this.USL, this.UCL],
          tickColor: "#FFFFFF",
          tickLength: 20,
          tickWidth: 2,
          minorTickInterval: null,
          labels: {
            distance: 7,
            style: {
              fontSize: "8px",
            },
          },
          lineWidth: 0,
          plotBands: [
            {
              from: parseFloat(this.target),
              to: this.UCL,
              color: "#55BF3B", // green
              thickness: 6,
            },
            {
              from: parseFloat(this.target),
              to: this.LCL,
              color: "#55BF3B", // green
              thickness: 6,
            },
            {
              from: this.UCL,
              to: this.USL,
              color: "#DDDF0D", // yellow
              thickness: 6,
            },
            {
              from: this.LCL,
              to: this.LSL,
              color: "#DDDF0D", // yellow
              thickness: 6,
            },
            {
              from: this.USL,
              to: this.USL + this.max,
              color: "#DF5353", // red
              thickness: 6,
            },
            {
              from: this.LSL,
              to: this.LSL - this.min,
              color: "#DF5353", // red
              thickness: 6,
            },
          ],
        },
        tooltip: {
          enabled: false,
        },
        series: [
          {
            name: "Speed",
            data: [parseFloat(this.value.toFixed(2))],
            tooltip: {
              valueSuffix: ` ${this.unit}`,
            },
            dataLabels: {
              format: `{y} ${this.unit}`,
              borderWidth: 0,
              color: "#333333",
              style: {
                fontSize: "10px",
              },
              enabled: true,
              y: 1,
              verticalAlign: "top",
            },
            dial: {
              radius: "100%",
              backgroundColor: "black",
              baseWidth: 2,
              baseLength: "0%",
              rearLength: "0%",
            },
            pivot: {
              backgroundColor: "black",
              radius: 2,
            },
          },
        ],

        credits: {
          enabled: false,
        },
      };
      return gaugeOptions;
    },
  },
};
</script>
