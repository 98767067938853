<template>
  <v-app style="background: #fff">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <h1>Reset Password</h1>
                </div>
                <v-form>
                  <v-text-field
                    :type="hideNewPassword ? 'password' : 'text'"
                    :append-icon="
                      hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="newPassword"
                    label="New Password"
                    id="newPassword"
                    :rules="[rules.required]"
                    v-model="password.new"
                    :error="error"
                    @click:append="hideNewPassword = !hideNewPassword"
                  />
                  <v-text-field
                    :type="hideConfirmPassword ? 'password' : 'text'"
                    :append-icon="
                      hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="confirmPassword"
                    label="Confirm Password"
                    id="confirmPassword"
                    :rules="[rules.required]"
                    v-model="password.confirm"
                    :error="error"
                    @click:append="hideConfirmPassword = !hideConfirmPassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="white--text" block color="#007346" @click="change"
                  >Reset</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      password: {
        new: "",
        confirm: "",
      },
      payload: {
        id: "",
        newPassword: "",
      },
      loading: false,
      hideNewPassword: true,
      hideConfirmPassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.$store.dispatch("auth/decode");
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  created() {
    if (this.getAccess == null) {
      this.$router.push("/");
    }
  },
  methods: {
    change() {
      if (this.password.new == "" || this.password.confirm == "") {
        this.text = "Error! Please fill out this form.";
        this.color = "red";
        this.snackbar = true;
      } else {
        if (this.password.new == this.password.confirm) {
          this.payload.id = this.getAccess.id;
          this.payload.newPassword = this.password.new;
          this.$store.dispatch("auth/resetPassword", this.payload).then(
            () => {
              this.$router.push("/overview");
            },
            (error) => {
              this.loading = false;
              this.error = true;
              this.result = "";
              this.showResult = true;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              console.log(this.message);
            }
          );
        } else {
          this.result = "New Password and Confirm Password do not match";
          this.showResult = true;
          return;
        }
      }
    },
  },
};
</script>
