import ReportsService from "../services/reports.service";
export const reports = {
  state: {
    report: ReportsService.chartdefault(),
  },
  getters: {
    getReport: (state) => state.report,
  },
  mutations: {
    setReport(state, report) {
      state.report = report;
    },
  },
  actions: {
    getReport({ commit }, data) {
      console.log(data);
      return ReportsService.getReport(data).then(
        (report) => {
          console.log(report);
          commit("setReport", report);
          return report;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
