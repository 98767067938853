<template>
  <div fluid class="pb-10" v-resize="onResize">
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Accounts
        </span>
      </v-col>
    </v-row>
    <v-card
      id="card"
      class="mx-5 mb-10"
      :style="isMobile ? 'margin-bottom: 15%' : ''"
    >
      <v-card-title style="color: #383874; font-style: normal">
        Accounts
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getUsers"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="resetPasswordData(item._id)"
                icon
                color="green"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-key</v-icon></v-btn
              >
            </template>
            <span>Reset Password</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="editUserData(item)"
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delUserData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position: fixed; bottom: 25px; right: 0px"
            class="ma-10"
            fixed
            fab
            color="#384CFF"
            dark
            absolute
            bottom
            right
            dense
            floating
            v-bind="attrs"
            v-on="on"
            @click="form = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>

      <v-dialog v-model="form" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Create Account</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addUser()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="userData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="username"
                  label="Username*"
                  v-model="userData.username"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Username is required']"
                  required
                ></v-text-field>
                <v-text-field
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="
                    hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                  "
                  name="password"
                  label="Password*"
                  v-model="userData.password"
                  @click:append="hidePassword = !hidePassword"
                  :rules="[(v) => !!v || 'Password is required']"
                  required
                ></v-text-field>
                <v-select
                  v-if="getAccess.role == 'Superadmin'"
                  :items="getOwners"
                  name="owner"
                  label="Owner*"
                  item-value="_id"
                  item-text="name"
                  v-model="userData.owner"
                  required
                ></v-select>
                <v-select
                  v-if="getAccess.role == 'Superadmin'"
                  :items="['Superadmin', 'Admin', 'User']"
                  name="role"
                  label="Role*"
                  item-value="id"
                  v-model="userData.role"
                  required
                ></v-select>
                <v-select
                  v-else
                  :items="['Admin', 'User']"
                  name="role"
                  label="Role*"
                  item-value="id"
                  v-model="userData.role"
                  required
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn text @click="addUser()" color="green"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Edit Profile</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editUser()"
                ref="userDataEdit"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="userDataEdit.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="username"
                  label="Username*"
                  v-model="userDataEdit.username"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Username is required']"
                  required
                ></v-text-field>
                <v-select
                  v-if="getAccess.role == 'Superadmin'"
                  :items="getOwners"
                  name="owner"
                  label="Owner*"
                  item-value="_id"
                  item-text="name"
                  v-model="userDataEdit.owner"
                  required
                ></v-select>
                <v-select
                  v-if="getAccess.role == 'Superadmin'"
                  :items="['Superadmin', 'Admin', 'User']"
                  name="role"
                  label="Role*"
                  item-value="id"
                  v-model="userDataEdit.role"
                  required
                ></v-select>
                <v-select
                  v-else
                  :items="['Admin', 'User']"
                  name="role"
                  label="Role*"
                  item-value="id"
                  v-model="userDataEdit.role"
                  required
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn
                color="green"
                text
                @click="
                  editUser();
                  closeDlg();
                "
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="resetPW" max-width="350px" persistent>
        <v-card>
          <v-card-title primary-title style="color: #383874">
            Reset password</v-card-title
          >
          <v-card-text>
            <v-form
              @submit.prevent="resetPasswords()"
              ref="userData"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                :type="hideNewPassword ? 'password' : 'text'"
                :append-icon="
                  hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="new password"
                label="New Password"
                hide-details="auto"
                v-model="password.new"
                @click:append="hideNewPassword = !hideNewPassword"
                :rules="[(v) => !!v || 'New Password is required']"
                required
              ></v-text-field>
              <v-text-field
                :type="hideConfirmPassword ? 'password' : 'text'"
                :append-icon="
                  hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="confirm password"
                label="Confirm Password"
                hide-details="auto"
                v-model="password.confirm"
                @click:append="hideConfirmPassword = !hideConfirmPassword"
                :rules="[(v) => !!v || 'Confirm Password is required']"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>
            <v-btn text @click="resetPasswords()" color="green"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDelete" max-width="320" persistent>
        <v-card>
          <v-card-title style="color: #383874" primary-title>
            Confirm Delete
          </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this account ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delUser()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "accountPage",
  data: () => ({
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    resetPW: false,
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    headers: [
      { text: "Username", value: "username", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Owner", value: "owner.name", sortable: true },
      { text: "Role", value: "role", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    userData: {
      name: "",
      username: "",
      password: "",
      role: "",
      owner: "",
    },
    userDataEdit: {
      _id: "",
      name: "",
      username: "",
      role: "",
      owner: "",
    },
    default: {
      name: "",
      username: "",
      role: "",
      owner: "",
    },
    resetData: {
      id: "",
      newPassword: "",
    },
    password: {
      id: "",
      new: "",
      confirm: "",
    },
    isMobile: true,
  }),
  computed: {
    getUsers() {
      let user = this.$store.getters.allUsers;

      this.$store.dispatch("auth/decode");
      //console.log(this.getAccess)
      if (this.getAccess.username != "pmAdmin") {
        for (let i = user.length - 1; i >= 0; i--) {
          if (user[i].role === "Superadmin") {
            user.splice(i, 1);
          }
        }
      }
      return user;
    },
    getOwners() {
      return this.$store.getters.allOwners;
    },
    getAccess() {
      console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role != "User") {
      this.loadUser();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    loadUser() {
      this.$store.dispatch("getUsers");
      this.$store.dispatch("getOwners");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.resetPW = false;
      this.userData = Object.assign({}, this.default);
      this.password.new = "";
      this.password.confirm = "";
    },
    delUserData(id) {
      this.confirmDelete = true;
      this.userDelId = id;
    },
    addUser() {
      if (
        this.userData.username === "" ||
        this.userData.password === "" ||
        this.userData.role === ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create an account.";
      } else {
        if (this.getAccess.role != "Superadmin") {
          this.userData.owner = this.getAccess.owner;
        }
        this.$store.dispatch("createUser", this.userData).then((res) => {
          console.log(res);
          if (res.status === 201) {
            this.closeDlg();

            this.color = "success";
            this.text = "Account has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        });
      }
    },
    delUser() {
      this.$store
        .dispatch("deleteUser", this.userDelId)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Account has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this account.";
          this.snackbar = true;
        });
    },
    editUserData(data) {
      //console.log("data");
      this.editform = true;
      this.userDataEdit = Object.assign({}, data);
      console.log(this.userDataEdit);
    },
    editUser() {
      //console.log(this.userDataEdit);
      this.userDataEdit.owner = this.getAccess.owner;
      this.$store
        .dispatch("editUser", this.userDataEdit)
        .then((res) => {
          //console.log('res.error.message');
          //console.log(res);
          if (res.status == 400) {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that username already exists!";
            this.snackbar = true;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Account has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this account.";
          this.snackbar = true;
        });
    },
    resetPasswordData(id) {
      this.resetPW = true;
      this.password.id = id;
    },
    resetPasswords() {
      if (this.password.new === this.password.confirm) {
        this.resetData.id = this.password.id;
        this.resetData.newPassword = this.password.new;
        //console.log(this.resetData);
        this.$store
          .dispatch("resetPassword", this.resetData)
          .then(() => {
            this.color = "success";
            this.text = "Reset Password Complete.";
            this.resetPW = false;
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.password.new = "";
            this.password.confirm = "";
            this.color = "error";
            this.text = " Error! You cannot reset password this account.";
            this.snackbar = true;
          });
      } else {
        this.color = "error";
        this.text = " Error! Passwords do not match!";
        this.snackbar = true;
      }
    },
  },
};
</script>
