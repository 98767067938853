<template>
  <div fluid :class="isMobile ? 'pb-10' : ''" v-resize="onResize">
    <v-row v-if="getAccess.role == 'Superadmin'">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="8"
        lg="8"
        align="start"
        justify="center"
      >
        <v-breadcrumbs :items="items">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-else align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Owners Lines
        </span>
      </v-col>
    </v-row>

    <v-card id="card" class="mx-5 mb-10">
      <v-card-title style="color: #383874; font-style: normal">
        Lines
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getLines"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="editLineData(item)"
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delLineData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position: fixed; bottom: 25px; right: 0px"
            class="ma-10"
            fixed
            fab
            color="#384CFF"
            dark
            absolute
            bottom
            right
            dense
            floating
            v-bind="attrs"
            v-on="on"
            @click="form = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>

      <v-dialog v-model="form" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874"
                >Create Line Config</span
              ></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addLine()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="lineData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="token"
                  label="Token*"
                  v-model="lineData.token"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Token is required']"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn text @click="addLine()" color="green"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Edit Line</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editLine()"
                ref="lineDataEdit"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="lineDataEdit.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="token"
                  label="Token*"
                  v-model="lineDataEdit.token"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Token is required']"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn
                color="green"
                text
                @click="
                  editLine();
                  closeDlg();
                "
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="confirmDelete" max-width="320">
        <v-card>
          <v-card-title primary-title> Confirm Delete </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this line config ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delLine()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "lineconfigPage",
  data: () => ({
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    idOwner: null,
    headers: [
      { text: "Name", value: "name", sortable: true },
      { text: "Token", value: "token", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    lineData: {
      name: "",
      token: "",
      owner: "",
    },
    lineDataEdit: {
      _id: "",
      name: "",
      token: "",
      owner: "",
    },
    default: {
      name: "",
      token: "",
      owner: "",
    },
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/owners",
      },
      {
        text: "Owners Lines",
        disabled: true,
      },
    ],
    isMobile: true,
  }),
  computed: {
    getLines() {
      return this.$store.getters.allLines;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.$store.dispatch("auth/decode");
    if (this.getAccess.role == "Superadmin") {
      if (this.$route.params.data != undefined) {
        Cookies.set("idOwner", this.$route.params.data._id);
        this.idOwner = this.$route.params.data._id;
      } else {
        this.idOwner = Cookies.get("idOwner");
      }
    } else {
      this.idOwner = this.$store.state.auth.access.owner;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role != "User") {
      this.$store.dispatch("getLines", this.idOwner);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    formetDate() {
      const d = new Date();
      const offset = d.getTimezoneOffset();
      const date = new Date(d.getTime() - offset * 60 * 1000);
      let day = date.toISOString().split("T")[0];
      return day;
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;

      this.lineData = Object.assign({}, this.default);
    },
    delLineData(id) {
      this.confirmDelete = true;
      this.lineDelId = id;
    },
    addLine() {
      if (this.lineData.token === "" || this.lineData.name === "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create a line config.";
      } else {
        this.lineData.owner = this.idOwner;
        this.$store.dispatch("createLine", this.lineData).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.closeDlg();
            this.color = "success";
            this.text = "Line config has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        });
      }
    },
    delLine() {
      this.$store
        .dispatch("deleteLine", this.lineDelId)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Line config has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this line config.";
          this.snackbar = true;
        });
    },
    editLineData(data) {
      //console.log("data");
      this.editform = true;
      this.lineDataEdit = Object.assign({}, data);
      console.log(this.lineDataEdit);
    },
    editLine() {
      //console.log(this.lineDataEdit);
      this.$store
        .dispatch("editLine", this.lineDataEdit)
        .then((res) => {
          //console.log('res.error.message');
          //console.log(res);
          if (res.data.message == "Sorry Username Is Already Exists") {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that line already exists!";
            this.snackbar = true;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Line config has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this line config.";
          this.snackbar = true;
        });
    },
  },
};
</script>
