import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "samplings/";
import store from "@/store";
import authHeader from "../services/auth-header";
export const samplings = {
  state: {
    samplings: [],
  },
  getters: {
    getSamplings: (state) => state.samplings,
  },
  mutations: {
    setSamplings(state, samplings) {
      state.samplings = samplings;
    },
    createSampling(state, sampling) {
      if (typeof sampling.farm == "string") {
        const indexFarm = store.state.farms.farms.findIndex(
          (u) => u._id === sampling.farm
        );
        sampling.farm = store.state.farms.farms[indexFarm];
      }
      if (sampling.tower == undefined) {
        sampling.tower = null;
      }
      if (typeof sampling.tower == "string") {
        const indexTower = store.state.farms.towers.findIndex(
          (u) => u._id === sampling.tower
        );
        if (indexTower > -1) {
          sampling.tower = store.state.farms.towers[indexTower];
        }
      }
      console.log(sampling);
      state.samplings.push(sampling);
    },
    editSampling(state, sampling) {
      console.log(sampling.tower);
      const index = state.samplings.findIndex((u) => u._id === sampling._id);
      const indexFarm = store.state.farms.farms.findIndex(
        (u) => u._id === sampling.farm
      );
      const indexTower = store.state.farms.towers.findIndex(
        (u) => u._id === sampling.tower
      );
      if (indexTower > -1) {
        sampling.tower = store.state.farms.towers[indexTower];
      }
      sampling.farm = store.state.farms.farms[indexFarm];

      state.samplings.splice(index, 1, sampling);
    },
    deleteSampling(state, id) {
      const index = state.samplings.findIndex((u) => u._id === id);
      state.samplings.splice(index, 1);
    },
  },
  actions: {
    async getSampling(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setSamplings", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.respone;
        });
    },
    createSampling(context, data) {
      console.log(data);
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createSampling", res.data.data);
          store.state.tower;
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteSampling(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log("Delete is successfully");
          context.commit("deleteSampling", id);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    editSampling(context, data) {
      console.log(data);
      return axios
        .put(apiURL + data._id, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("editSampling", data);
          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
  },
};
