<template>
  <div
    fluid
    :class="isMobile ? 'pb-10' : ''"
    v-if="getSamplings"
    v-resize="onResize"
  >
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Reports
        </span>
      </v-col>
    </v-row>
    <v-card id="card" class="mx-5">
      <v-card-title style="color: #383874; font-style: normal">
        <v-row align="center">
          <v-col cols="12" xs="4" sm="4" md="2" lg="2">
            <v-autocomplete
              hide-details="false"
              dense
              label="Farm"
              :items="getFarms"
              item-value="_id"
              item-text="name"
              v-model="dataSearch.farm"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="2" lg="2">
            <v-autocomplete
              hide-details="false"
              dense
              label="Tower"
              :items="getTower"
              item-value="_id"
              item-text="name"
              v-model="dataSearch.tower"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="2" lg="2">
            <v-autocomplete
              label="Select Data"
              hide-details="false"
              :items="getSamplings"
              item-value="description"
              item-text="description"
              v-model="typeSearch"
              required
              dense
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.description }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ typeSearch.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="2" lg="2">
            <v-select
              label="Select Time"
              hide-details="false"
              :items="timeMode"
              item-value="value"
              item-text="text"
              v-model="indexTimeMode"
              required
              dense
            >
            </v-select>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="3" lg="3">
            <date-picker
              v-if="indexTimeMode == 1"
              style="max-width: 300px"
              v-model="dateRange"
              range
              valueType="YYYY-MM-DD HH:mm:ss"
              format="DD/MM/YYYY HH:mm:ss"
              type="datetime"
            ></date-picker>
            <date-picker
              v-if="indexTimeMode == 2"
              style="max-width: 130px"
              v-model="dateMonth"
              type="month"
              valueType="M/YYYY"
              format="MM/YYYY"
            ></date-picker>
            <date-picker
              v-if="indexTimeMode == 3"
              style="max-width: 130px"
              v-model="dataSearch.year"
              type="year"
              valueType="YYYY"
              format="YYYY"
            ></date-picker>
          </v-col>
          <v-col
            align="end"
            justify="end"
            cols="12"
            xs="1"
            sm="1"
            md="1"
            lg="1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#384CFF"
                  fab
                  small
                  dark
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="searchDate()"
                >
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-row v-if="!loading">
      <v-flex d-flex class="ml-5 mr-5 mt-5">
        <v-layout wrap>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            :md="getReport.length == 1 ? 12 : 6"
            :lg="getReport.length == 1 ? 12 : 6"
            v-for="(report, key) in getReport"
            :key="key"
          >
            <v-card class="pa-2" height="260px">
              <v-row v-if="report.unit != undefined">
                <v-col> Unit {{ report.unit }} </v-col>
              </v-row>
              <v-row v-if="report.flowTotalizer != undefined">
                <v-col>
                  Flow Totalizer {{ report.flowTotalizer.toFixed(2) }}
                </v-col>
              </v-row>
              <ApexChart
                width="100%"
                height="200px"
                type="line"
                :options="report.chartOptions"
                :series="report.series"
              ></ApexChart>
            </v-card>
          </v-col>
        </v-layout>
      </v-flex>
    </v-row>
    <v-row v-else align="center" justify="center" style="height: 70vh">
      <v-col cols="12" align="center" justify="center">
        <v-progress-circular
          :size="60"
          :width="7"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-tooltip top v-if="getAccess.role != 'User'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="position: fixed; bottom: 20px; right: 0px"
          class="ma-10"
          fixed
          fab
          color="#384CFF"
          dark
          absolute
          bottom
          right
          dense
          floating
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Setting</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-card-title>
          <v-col cols="6" class="mx-0 px-0">
            <span style="color: #383874">Data Sampling</span>
          </v-col>
          <v-col cols="4" class="mx-0 px-0 text-right">
            <v-autocomplete
              hide-details="false"
              dense
              label="Farm"
              :items="getFarms"
              item-value="_id"
              item-text="name"
              v-model="dataSampling.farm"
              @change="searchSampling"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="mx-0 px-0 text-right">
            <v-btn dark color="green" @click="dialogUpdate = true"> Add</v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            item-key="id"
            :headers="headers"
            :items="getSamplings"
            :items-per-page="5"
            style="color: #383874; font-style: normal"
            :loading="loadingSampling"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editData(item)"
                    icon
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="delData(item._id)"
                    icon
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUpdate" max-width="400px" persistent>
      <template>
        <v-card>
          <v-card-title>
            <span v-if="indexEdit == -1" style="color: #383874">
              Add Data Sampling
            </span>
            <span v-else style="color: #383874"> Edit Data Sampling </span>
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="updateSampling()"
              ref="addSampling"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                name="name"
                label="Name*"
                v-model="dataSampling.name"
                @keypress="isLetter($event)"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
              <v-text-field
                name="description"
                label="Description*"
                v-model="dataSampling.description"
                @keypress="isLetter($event)"
                :rules="[(v) => !!v || 'Description is required']"
                required
              ></v-text-field>
              <v-text-field
                name="time"
                label="Time*"
                v-model="dataSampling.time"
                @keypress="isNum($event)"
                :rules="[(v) => !!v || 'Time is required']"
                required
              >
              </v-text-field>
              <v-autocomplete
                :items="getTower"
                name="tower"
                label="Tower*"
                item-value="_id"
                item-text="name"
                v-model="dataSampling.tower"
                required
              ></v-autocomplete>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>
            <v-btn color="green" text @click="updateSampling()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="confirmDelete" max-width="320" persistent>
      <v-card>
        <v-card-title style="color: #383874" primary-title>
          Confirm Delete
        </v-card-title>

        <v-card-text class="body">
          Are you sure to delete this data sampling ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDlg()"> Cancel </v-btn>

          <v-btn color="error" text @click="delSampling()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "ReportPage",

  computed: {
    getSamplings() {
      let sampling = this.$store.getters.getSamplings;
      console.log(sampling);
      sampling.push({
        description: "Flow Total Day",
      });
      return this.$store.getters.getSamplings;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getFarms() {
      return this.$store.getters.getFarms;
    },
    getTower() {
      let towers = this.$store.getters.allTower;
      const newArray = [{ _id: null, name: "Environment" }].concat(towers);
      return newArray;
    },
    getFarm() {
      return this.$store.getters.OneFarm;
    },
    getReport() {
      return this.$store.getters.getReport;
    },
  },
  async created() {
    this.$store.dispatch("auth/decode");
    this.loading = false;
  },
  async mounted() {
    if (this.getAccess.role != "User") {
      this.$store.dispatch("getFarms").then(() => {
        this.dataSearch.farm = this.getFarms[0].id;
        this.dataSampling.farm = this.getFarms[0].id;
        this.loading = true;
        this.$store
          .dispatch("getTowerByFarm", this.dataSearch.farm)
          .then(() => {
            this.$store.dispatch("getSampling", this.getFarms[0]._id);
            this.$store.dispatch("getReport", this.dataSearch).then(() => {
              this.loading = false;
            });
          });
      });
    } else {
      this.$router.push("/");
    }
  },
  data() {
    return {
      loading: false,
      loadingSampling: false,
      confirmDelete: false,
      idFarm: "",
      idTower: "",
      dialog: false,
      dialogUpdate: false,
      snackbar: false,
      text: "",
      color: "",
      timeMode: [
        { value: 0, text: "Today" },
        { value: 1, text: "Range time" },
        { value: 2, text: "Month" },
        { value: 3, text: "Year" },
      ],
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Farm", value: "farm.name", sortable: true },
        { text: "Tower", value: "tower.name", sortable: true },
        { text: "Time(s)", value: "time", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      dataSearch: {
        farm: null,
        tower: null,
        type: [],
        timeMode: null,
        month: null,
        year: null,
        timeStart: null,
        timeEnd: null,
      },
      dataSampling: {
        farm: null,
        tower: null,
        name: null,
        description: null,
        time: null,
      },
      default: {
        farm: null,
        tower: null,
        name: null,
        description: null,
        time: null,
      },
      indexEdit: -1,
      indexTimeMode: 0,
      dateRange: [],
      dateMonth: "",
      dateYear: "",
      typeSearch: [],
      isMobile: false,
    };
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9!@#$&()-`.+,/"]*$/.test(char)) return true;
      else e.preventDefault();
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    setChart(index) {
      console.log(index);
      this.typeIndex = index;
      this.searchChart();
    },
    getData() {
      this.$store.dispatch("getFarms").then(() => {
        this.dataSearch.farm = this.getFarms[0]._id;
        this.$store.dispatch("getFarmByID", this.dataSearch.farm);
      });
    },
    ChangeFarm() {
      this.$store.dispatch("getFarmByID", this.dataSearch.farm);
    },
    findTower(id) {
      this.$store.dispatch("getTowerByFarm", id);
    },
    formetDate() {
      const d = new Date();
      const offset = d.getTimezoneOffset();
      const date = new Date(d.getTime() - offset * 60 * 1000);
      let day = date.toISOString().split("T")[0];
      let start = day + "T00:00:00";
      let end = day + "T23:59:59";
      return [start, end];
    },
    searchDate() {
      if (this.typeSearch.length == 0) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Please select datas for search";
      } else {
        let array = [];
        const days = this.formetDate();
        switch (this.indexTimeMode) {
          case 0:
            this.dataSearch.timeMode = "Day";
            this.dataSearch.timeStart = days[0];
            this.dataSearch.timeEnd = days[1];
            break;
          case 1:
            this.dataSearch.timeMode = "Day";
            this.dataSearch.timeStart = this.dateRange[0].replace(" ", "T");
            this.dataSearch.timeEnd = this.dateRange[1].replace(" ", "T");
            break;
          case 2:
            this.dataSearch.timeMode = "Month";
            array = this.dateMonth.split("/");
            this.dataSearch.month = array[0];
            this.dataSearch.year = array[1];
            break;
          case 3:
            this.dataSearch.timeMode = "Year";
            break;
        }
        this.loading = true;
        this.dataSearch.type = this.typeSearch;
        this.$store.dispatch("getReport", this.dataSearch).then(() => {
          this.loading = false;
          this.dataSearch.type = [];
        });
      }
    },
    closeDlg() {
      this.confirmDelete = false;
      this.dialogUpdate = false;
      this.indexEdit = -1;
      this.dataSampling = Object.assign({}, this.default);
      this.dataSampling.farm = this.dataSearch.farm;
    },
    editData(data) {
      console.log(data);
      this.dialogUpdate = true;
      this.dataSampling = Object.assign({}, data);
      console.log(this.dataSampling);
      this.indexEdit = data._id;
    },
    updateSampling() {
      if (
        this.dataSampling.name == null ||
        this.dataSampling.description == null ||
        this.dataSampling.time == null
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create.";
      } else {
        if (this.indexEdit == -1) {
          console.log(this.dataSampling);
          this.$store
            .dispatch("createSampling", this.dataSampling)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                this.closeDlg();
                this.color = "success";
                this.text = "Data Sampling has been successfully saved.";
                this.snackbar = true;
              } else {
                this.snackbar = true;
                this.color = "error";
                this.text = res.data.error.message;
              }
            });
        } else {
          if (typeof this.dataSampling.farm == "object") {
            this.dataSampling.farm = this.dataSampling.farm._id;
          }
          if (
            typeof this.dataSampling.tower == "object" &&
            this.dataSampling.tower != null
          ) {
            this.dataSampling.tower = this.dataSampling.tower._id;
          }
          this.$store
            .dispatch("editSampling", this.dataSampling)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                this.closeDlg();
                this.color = "success";
                this.text = "Line config has been successfully saved.";
                this.snackbar = true;
              } else {
                this.snackbar = true;
                this.color = "error";
                this.text = res.data.error.message;
              }
            });
        }
      }
    },
    delData(id) {
      this.confirmDelete = true;
      this.indexEdit = id;
    },
    delSampling() {
      this.$store
        .dispatch("deleteSampling", this.indexEdit)
        .then(() => {
          this.confirmDelete = false;
          this.indexEdit = -1;
          this.color = "success";
          this.text = "Deleted! Data Sampling has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this data sampling.";
          this.snackbar = true;
        });
    },
    searchSampling() {
      this.loadingSampling = true;
      this.$store.dispatch("getSampling", this.dataSampling.farm).then(() => {
        this.loadingSampling = false;
      });
      this.$store.dispatch("getTowerByFarm", this.dataSampling.farm);
    },
  },
};
</script>
<style>
.select {
  max-width: 134px;
}
.apexcharts-toolbar {
  z-index: 0 !important;
}
</style>
