import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "alarms/";
import store from "@/store";
import authHeader from "../services/auth-header";
export const alarms = {
  state: {
    alarms: [],
  },
  getters: {
    allAlarms: (state) => state.alarms,
  },
  mutations: {
    setAlarm(state, alarm) {
      state.alarms = alarm;
    },
    createAlarm(state, alarm) {
      const indexFarm = store.state.farms.farms.findIndex(
        (u) => u._id === alarm.farm
      );
      const indexTower = store.state.farms.towers.findIndex(
        (u) => u._id === alarm.tower
      );
      alarm.farm = store.state.farms.farms[indexFarm];
      alarm.tower = store.state.farms.towers[indexTower];
      state.alarms.push(alarm);
    },
    editAlarm(state, alarm) {
      console.log(alarm);
      if (alarm.farm.name == undefined) {
        const indexFarm = store.state.farms.farms.findIndex(
          (u) => u._id === alarm.farm
        );
        alarm.farm = store.state.farms.farms[indexFarm];
      }
      if (alarm.tower == null) {
        alarm.tower = {
          _id: null,
          name: "Environment",
        };
      } else {
        if (alarm.tower.name == undefined) {
          const indexTower = store.state.farms.towers.findIndex(
            (u) => u._id === alarm.tower
          );

          alarm.tower = store.state.farms.towers[indexTower];
        }
      }

      const index = state.alarms.findIndex((u) => u._id === alarm.id);
      state.alarms.splice(index, 1, alarm);
    },
    deleteAlarm(state, id) {
      const index = state.alarms.findIndex((u) => u._id === id);
      state.alarms.splice(index, 1);
    },
  },
  actions: {
    async getAlarms(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setAlarm", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createAlarm(context, data) {
      console.log(data);
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createAlarm", res.data.data);
          store.state.tower;
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAlarm(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteAlarm", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editAlarm(context, data) {
      console.log(data);
      return axios
        .put(apiURL + data._id, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("editAlarm", data);
          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
