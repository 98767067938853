<template>
  <div v-if="this.getFarm.camera">
    <canvas
      ref="canvas1"
      :style="{ height: height, borderRadius: radius }"
      style="width: 100%"
    ></canvas>
  </div>
</template>

<script>
import { loadPlayer } from "rtsp-relay/browser";
import Cookies from "js-cookie";
export default {
  name: "WebcamPage",
  props: {
    height: {
      type: String,
      default: "13vh",
    },
    radius: {
      type: String,
      default: "4px 4px 0px 0px",
    },
  },
  computed: {
    getFarm() {
      return this.$store.getters.OneFarm;
    },
  },
  async created() {
    if (this.$route.params.id != "" && this.$route.params.id != null) {
      this.idFarm = this.$route.params.id;
      Cookies.set("idFarm", this.idFarm);
    } else {
      this.idFarm = Cookies.get("idFarm");
    }
  },
  data() {
    return {
      canvas: null,
      idFarm: null,
    };
  },
  methods: {},

  mounted() {
    loadPlayer({
      url:
        "wss://" +
        this.getFarm.camera.ip +
        "/api/" +
        this.getFarm.macAddress +
        "/stream/1",
      canvas: this.$refs["canvas1"],
    });
  },
};
</script>
