import axios from "axios";
// const apiURL = `http://192.168.1.130:3300/users/`;
const apiURL = process.env.VUE_APP_API_URL + "users/";
import store from "@/store";
import authHeader from "../services/auth-header";
export const accounts = {
  state: {
    users: [],
  },
  getters: {
    allUsers: (state) => state.users,
  },
  mutations: {
    setUser(state, user) {
      state.users = user;
    },
    createUser(state, user) {
      const indexFarm = store.state.owners.owners.findIndex(
        (u) => u._id === user.owner
      );
      user.owner = store.state.owners.owners[indexFarm];
      state.users.push(user);
    },
    editUser(state, user) {
      //console.log(user);
      const index = state.users.findIndex((u) => u._id === user._id);
      state.users.splice(index, 1, user);
    },
    deleteUser(state, id) {
      const index = state.users.findIndex((u) => u._id === id);
      state.users.splice(index, 1);
    },
  },
  actions: {
    async getUsers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setUser", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUser(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            owner: data.owner,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          context.commit("createUser", res.data.data);
          console.log("Create is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    deleteUser(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          console.log("Delete is successfully");
          context.commit("deleteUser", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editUser(context, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
            username: data.username,
            owner: data.owner,
            role: data.role,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          context.commit("editUser", data);
          console.log("Edit is successfully");
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    resetPassword(context, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "resetPassword/" + data.id,
          {
            id: data,
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Reset password successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
