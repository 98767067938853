<template>
  <div
    fluid
    :class="isMobile ? 'mb-10' : ''"
    v-if="!this.loading"
    v-resize="onResize"
    class="pb-3"
  >
    <v-breadcrumbs :items="items" style="padding: 15px 15px 0px">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="ma-1">
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="mr-0 mb-3">
        <v-row>
          <v-col cols="4" class="pr-1">
            <v-card style="color: #383874">
              <div style="position: absolute; right: 0">
                <v-btn icon @click="openModal('TEMPERATURE')">
                  <i class="fas fa-cog" style="font-size: 0.9em"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 10% 5%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874; font-size: 0.9em"
                >
                  Temperature
                </div>
                <div class="text-center" style="font-size: 0.9em">
                  Program {{ getRealTime.environment.temperature.Program }}
                </div>
                <Gauge
                  :value="
                    parseFloat(this.getRealTime.environment.temperature.Average)
                  "
                  :target="
                    findTarget(
                      getFarm.Environment.Programs.temp,
                      getRealTime.environment.temperature.Program
                    )
                  "
                  height="70dvh"
                  :min="5"
                  :max="5"
                  :LCL="getFarm.Environment.Programs.temp.LCL"
                  :UCL="getFarm.Environment.Programs.temp.UCL"
                  :LSL="getFarm.Environment.Programs.temp.Low"
                  :USL="getFarm.Environment.Programs.temp.High"
                  unit="°C"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  height="40dvh"
                  :options="chartAll[0].chartOptions"
                  :series="chartAll[0].series"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" class="pr-1">
            <v-card style="color: #383874">
              <div style="position: absolute; right: 0">
                <v-btn icon @click="openModal('HUMIDITY')">
                  <i class="fas fa-cog" style="font-size: 0.9em"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 10% 5%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874; font-size: 0.9em"
                >
                  Humidity
                </div>
                <div class="text-center" style="font-size: 0.9em">
                  Program {{ getRealTime.environment.humidity.Program }}
                </div>
                <Gauge
                  :value="
                    parseFloat(this.getRealTime.environment.humidity.Average)
                  "
                  :target="
                    findTarget(
                      getFarm.Environment.Programs.humidity,
                      getRealTime.environment.humidity.Program
                    )
                  "
                  :min="5"
                  :max="5"
                  height="70dvh"
                  :LCL="getFarm.Environment.Programs.humidity.LCL"
                  :UCL="getFarm.Environment.Programs.humidity.UCL"
                  :LSL="getFarm.Environment.Programs.humidity.Low"
                  :USL="getFarm.Environment.Programs.humidity.High"
                  unit="%"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  height="40dvh"
                  :options="chartAll[1].chartOptions"
                  :series="chartAll[1].series"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" class="pr-1">
            <v-card style="color: #383874">
              <div style="position: absolute; right: 0">
                <v-btn icon @click="openModal('CO2')">
                  <i class="fas fa-cog" style="font-size: 0.9em"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 10% 5%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874; font-size: 0.9em"
                >
                  CO2
                </div>
                <div class="text-center" style="font-size: 0.9em">
                  Program {{ getRealTime.environment.CO2.Program }}
                </div>
                <Gauge
                  :value="parseFloat(this.getRealTime.environment.CO2.Value1)"
                  :target="
                    findTarget(
                      getFarm.Environment.Programs.CO2,
                      getRealTime.environment.CO2.Program
                    )
                  "
                  :min="100"
                  :max="100"
                  height="70dvh"
                  :LCL="getFarm.Environment.Programs.CO2.LCL"
                  :UCL="getFarm.Environment.Programs.CO2.UCL"
                  :LSL="getFarm.Environment.Programs.CO2.Low"
                  :USL="getFarm.Environment.Programs.CO2.High"
                  unit="ppm"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[2].chartOptions"
                  :series="chartAll[2].series"
                  height="40dvh"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="color: #383874" class="mt-1">
          <v-col cols="6" class="pr-1">
            <v-card style="color: #383874">
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('vpd')">
                  <i class="fas fa-cog" style="font-size: 0.9em"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874; font-size: small"
                >
                  VPD
                </div>
                <Gauge
                  :value="parseFloat(this.getRealTime.environment.VPD)"
                  :target="1"
                  height="80dvh"
                  :min="1"
                  :max="1"
                  :LCL="parseFloat(getFarm.Environment.VPD.LCL.toFixed(2))"
                  :UCL="parseFloat(getFarm.Environment.VPD.UCL.toFixed(2))"
                  :LSL="parseFloat(getFarm.Environment.VPD.Low.toFixed(2))"
                  :USL="parseFloat(getFarm.Environment.VPD.High.toFixed(2))"
                  unit="kPa"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[3].chartOptions"
                  :series="chartAll[3].series"
                  height="50dvh"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-card>
              <div style="position: absolute; right: 0">
                <v-btn icon @click="setDataAlarm('diff')">
                  <i class="fas fa-cog" style="font-size: 0.9em"></i>
                </v-btn>
              </div>
              <v-card-text style="padding: 5% 10%">
                <div
                  class="text-center font-weight-bold"
                  style="color: #383874"
                >
                  Diff Pressure
                </div>
                <Gauge
                  :value="parseFloat(this.getRealTime.environment.diffPressure)"
                  :target="1"
                  height="80dvh"
                  :min="1"
                  :max="1"
                  :LCL="
                    parseFloat(getFarm.Environment.diffPressure.LCL.toFixed(2))
                  "
                  :UCL="
                    parseFloat(getFarm.Environment.diffPressure.UCL.toFixed(2))
                  "
                  :LSL="
                    parseFloat(getFarm.Environment.diffPressure.Low.toFixed(2))
                  "
                  :USL="
                    parseFloat(getFarm.Environment.diffPressure.High.toFixed(2))
                  "
                  unit="Pa"
                  style="margin-top: 1%"
                ></Gauge>
                <ChartSmall
                  :options="chartAll[4].chartOptions"
                  :series="chartAll[4].series"
                  height="50dvh"
                ></ChartSmall>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="color: #383874" class="mt-1">
          <v-col cols="12" class="pr-1">
            <v-card>
              <v-col cols="12" style="color: #383874">
                <div class="font-weight-bold" align="center">Power Meter</div>
              </v-col>
              <v-row
                cols="12"
                class="mx-1 pb-0"
                style="color: #383874; font-size: 75%"
              >
                <v-col cols="3" align="start">Phase 1</v-col>
                <v-col cols="3" align="start" class="px-0">
                  {{ getRealTime.environment.powerMeter[0].power.toFixed(2) }}
                  W</v-col
                >
                <v-col cols="3" align="center">
                  {{ getRealTime.environment.powerMeter[0].current.toFixed(2) }}
                  A
                </v-col>
                <v-col cols="3" align="end">
                  {{ getRealTime.environment.powerMeter[0].voltage.toFixed(2) }}
                  V
                </v-col>
              </v-row>
              <v-row
                cols="12"
                class="mx-1 my-0 pb-0 py-0"
                style="color: #383874; font-size: 75%"
              >
                <v-col cols="3" align="start">Phase 2</v-col>
                <v-col cols="3" align="start" class="px-0">
                  {{ getRealTime.environment.powerMeter[1].power.toFixed(2) }}
                  W</v-col
                >
                <v-col cols="3" align="center">
                  {{ getRealTime.environment.powerMeter[1].current.toFixed(2) }}
                  A
                </v-col>
                <v-col cols="3" align="end">
                  {{ getRealTime.environment.powerMeter[1].voltage.toFixed(2) }}
                  V
                </v-col>
              </v-row>
              <v-row
                cols="12"
                class="mx-1 pb-0 pt-0 my-n3"
                style="color: #383874; font-size: 75%"
              >
                <v-col cols="3" align="start">Phase 3</v-col>
                <v-col cols="3" align="start" class="px-0">
                  {{ getRealTime.environment.powerMeter[2].power.toFixed(2) }}
                  W</v-col
                >
                <v-col cols="3" align="center">
                  {{ getRealTime.environment.powerMeter[2].current.toFixed(2) }}
                  A
                </v-col>
                <v-col cols="3" align="end">
                  {{ getRealTime.environment.powerMeter[2].voltage.toFixed(2) }}
                  V
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        align="center"
        justify="center"
        class="pl-3"
      >
        <Farm />
        <v-card style="color: #383874">
          <div class="font-weight-bold py-2" align="center">CO2</div>
          <div style="position: absolute; right: 0; top: -1px">
            <v-btn icon @click="setPT(1)">
              <i class="fas fa-cog" style="font-size: 0.9em"></i>
            </v-btn>
          </div>
          <v-row>
            <v-col class="pt-0">
              <Gauge
                :value="Math.round(getRealTime.environment.Pressure1)"
                :target="2"
                height="31%"
                :min="1"
                :max="1"
                :LSL="1"
                :LCL="4"
                :UCL="7"
                :USL="10"
                unit="bar"
                style="z-index: -10"
              ></Gauge>
              <div
                class="text-center font-weight-bold py-1"
                style="color: #383874"
              >
                PT101
              </div>
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle
                    v-model="getRealTime.environment.SV[0].Mode"
                    dense
                    color="#384CFF"
                  >
                    <v-btn
                      class="text-upper"
                      small
                      value="Auto"
                      @click="setModeSV(1, 'Auto')"
                      :width="65"
                    >
                      auto
                    </v-btn>
                    <v-btn
                      class="text-upper"
                      small
                      value="Manual"
                      @click="setModeSV(1, 'Manual')"
                      :width="65"
                    >
                      manual
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <v-btn-toggle
                    v-model="getRealTime.environment.SV[0].On"
                    dense
                    :color="getRealTime.environment.SV[0].On ? 'green' : 'red'"
                  >
                    <v-btn
                      class="text-upper"
                      small
                      :value="true"
                      :disabled="getRealTime.environment.SV[0].Mode == 'Auto'"
                      @click="setOnOffSV(1, true)"
                      :width="65"
                    >
                      on
                    </v-btn>
                    <v-btn
                      class="text-upper"
                      small
                      :value="false"
                      :disabled="getRealTime.environment.SV[0].Mode == 'Auto'"
                      @click="setOnOffSV(1, false)"
                      :width="65"
                    >
                      off
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pt-0">
              <Gauge
                :value="Math.round(getRealTime.environment.Pressure2)"
                :target="2"
                height="31%"
                :min="1"
                :max="1"
                :LSL="1"
                :LCL="4"
                :UCL="7"
                :USL="10"
                unit="bar"
                style="z-index: -10"
              ></Gauge>
              <div
                class="text-center font-weight-bold py-1"
                style="color: #383874"
              >
                PT102
              </div>
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle
                    v-model="getRealTime.environment.SV[1].Mode"
                    dense
                    color="#384CFF"
                  >
                    <v-btn
                      class="text-upper"
                      small
                      value="Auto"
                      @click="setModeSV(2, 'Auto')"
                      :width="65"
                    >
                      auto
                    </v-btn>
                    <v-btn
                      class="text-upper"
                      small
                      value="Manual"
                      @click="setModeSV(2, 'Manual')"
                      :width="65"
                    >
                      manual
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <v-btn-toggle
                    v-model="getRealTime.environment.SV[1].On"
                    dense
                    :color="getRealTime.environment.SV[1].On ? 'green' : 'red'"
                  >
                    <v-btn
                      class="text-upper"
                      small
                      :value="true"
                      :disabled="getRealTime.environment.SV[1].Mode == 'Auto'"
                      @click="setOnOffSV(2, true)"
                      :width="65"
                    >
                      on
                    </v-btn>
                    <v-btn
                      class="text-upper"
                      small
                      :value="false"
                      :disabled="getRealTime.environment.SV[1].Mode == 'Auto'"
                      @click="setOnOffSV(2, false)"
                      :width="65"
                    >
                      off
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        align="center"
        justify="center"
        :class="isMobile ? 'pl-3' : 'pl-1'"
      >
        <v-card>
          <v-col cols="12" style="color: #383874">
            <div
              class="font-weight-bold mb-1"
              align="center"
              style="font-size: 2dvh"
            >
              CCTV
            </div>
          </v-col>
          <v-row class="px-3">
            <v-col
              cols="6"
              xs="6"
              sm="6"
              md="6"
              lg="6"
              class="pt-0 mt-0 pr-2"
              v-for="(title, index) in titleCCTV"
              :key="index"
            >
              <v-card>
                <div style="position: absolute; right: 0">
                  <v-btn text icon @click="showCCTV(index + 1)">
                    <i
                      class="fas fa-expand"
                      style="
                        font-size: 25px;
                        color: #fdfdfd;
                        -webkit-text-stroke: 1px black;
                      "
                    ></i>
                  </v-btn>
                </div>
                <div style="height: 14dvh">
                  <component :is="'stream' + (index + 1)" height="14dvh" />
                </div>
                <div style="font-size: 2dvh">{{ title }}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modal2.cctv"
      width="50vw"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar dark color="#749a4c">
          <v-btn icon @click="modal2.cctv = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ titleCCTV[componentCCTV - 1] }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div style="height: 85vh" class="ma-4">
          <component
            :is="'stream' + componentCCTV"
            height="85vh"
            radius="10px 10px 10px 10px"
          />
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal2.air"
      max-width="450px"
      persistent
      class="overflow-visible"
    >
      <v-card class="overflow-visible">
        <v-card-title
          class="font-weight-bold text-center"
          style="color: #383874"
          >SETTING AIR NO. {{ dataSetAir.no }}
        </v-card-title>

        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="3"> Time Start: </v-col>
            <v-col cols="3">
              <vue-timepicker
                v-model="time.startTime"
                input-width="90px"
                manual-input
              ></vue-timepicker>
            </v-col>
            <v-col cols="3"> Time End:</v-col>
            <v-col cols="3" class="pl-0">
              <vue-timepicker
                input-width="90px"
                manual-input
                v-model="time.endTime"
                class="overflow-visible"
              ></vue-timepicker>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-2">
          <v-btn class="mb-3" color="#383874" text @click="setAir()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal"
      max-width="500px"
      persistent
      class="overflow-visible"
    >
      <v-card class="overflow-visible">
        <v-card-title
          class="font-weight-bold text-center mb-0 pb-0"
          style="color: #383874"
        >
          SETTING {{ this.dataSetting.name }}
        </v-card-title>
        <v-row justify="end" class="mx-3 mb-3 mt-0">
          <v-btn-toggle dense v-model="typeIndex" color="#383874">
            <v-btn
              small
              v-for="(option, index) in typeProgram"
              :key="index"
              @click="setDataProgram(index)"
              >{{ option.name }}</v-btn
            >
          </v-btn-toggle>
        </v-row>

        <v-card-text v-if="typeIndex != 2">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="6"> Time Start: </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" justify="start">
                  <vue-timepicker
                    v-model="timeProgram"
                    input-width="80px"
                    manual-input
                  ></vue-timepicker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> Target:</v-col>
                <v-col cols="5" class="pl-0" justify="center" align="center">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.target"
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="typeIndex == 2">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> LCL:</v-col>
                <v-col cols="5">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.LCL"
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> UCL:</v-col>
                <v-col cols="5" justify="center" align="center">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.UCL"
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> LSL:</v-col>
                <v-col cols="5" justify="center" align="center">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.Low"
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> USL:</v-col>
                <v-col cols="5">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.High"
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-2" v-if="typeIndex != 2">
          <v-btn class="mb-3" color="#383874" text @click="setProgram()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
        <v-card-actions class="pb-2" v-if="typeIndex == 2">
          <v-btn class="mb-3" color="#383874" text @click="setAlarm()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal2.fanflow"
      max-width="300px"
      persistent
      class="overflow-visible"
    >
      <v-card class="overflow-visible">
        <v-card-title
          class="font-weight-bold text-center"
          style="color: #383874"
          >SETTING FANFLOW
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn
                :color="this.typeModeFanFlow == 'Auto' ? '#384CFF' : ''"
                :dark="this.typeModeFanFlow == 'Auto'"
                style="width: 90px"
                @click="setModeFanFlow('Auto')"
              >
                AUTO
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                :color="this.typeModeFanFlow == 'Manual' ? '#384CFF' : ''"
                :dark="this.typeModeFanFlow == 'Manual'"
                style="width: 90px"
                @click="setModeFanFlow('Manual')"
              >
                MANUAL
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="typeModeFanFlow == 'Auto'">
            <v-row justify="center" align="center">
              <v-col cols="6"> Time Start: </v-col>
              <v-col cols="6" class="text-right">
                <vue-timepicker
                  v-model="time.startTime"
                  input-width="90px"
                  manual-input
                ></vue-timepicker>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="6"> Time End:</v-col>
              <v-col cols="6" class="text-right">
                <vue-timepicker
                  input-width="90px"
                  manual-input
                  v-model="time.endTime"
                  class="overflow-visible"
                ></vue-timepicker>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6">
                <v-btn
                  :color="this.typeOnOffFanFlow ? 'success' : ''"
                  style="width: 90px"
                  @click="setOnOffFanFlow(true)"
                >
                  OPEN
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  :color="!this.typeOnOffFanFlow ? 'error' : ''"
                  style="width: 90px"
                  @click="setOnOffFanFlow(false)"
                >
                  CLOSE
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions class="pb-2">
          <v-btn class="mb-3" color="#383874" text @click="setFanFlow()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal2.ffu"
      max-width="300px"
      persistent
      class="overflow-visible"
    >
      <v-card class="overflow-visible">
        <v-card-title
          class="font-weight-bold text-center"
          style="color: #383874"
          >SETTING FFU
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn
                :color="this.typeModeFFU == 'Auto' ? '#384CFF' : ''"
                :dark="this.typeModeFFU == 'Auto'"
                style="width: 90px"
                @click="setModeFFU('Auto')"
              >
                AUTO
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                :color="this.typeModeFFU == 'Manual' ? '#384CFF' : ''"
                :dark="this.typeModeFFU == 'Manual'"
                style="width: 90px"
                @click="setModeFFU('Manual')"
              >
                MANUAL
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="typeModeFFU == 'Auto'">
            <v-row justify="center" align="center">
              <v-col cols="6"> Time Start: </v-col>
              <v-col cols="6" class="text-right">
                <vue-timepicker
                  v-model="time.startTime"
                  input-width="90px"
                  manual-input
                ></vue-timepicker>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="6"> Time End:</v-col>
              <v-col cols="6" class="text-right">
                <vue-timepicker
                  input-width="90px"
                  manual-input
                  v-model="time.endTime"
                  class="overflow-visible"
                ></vue-timepicker>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="7">Days</v-col>
              <v-col cols="5" class="text-right">
                <v-text-field
                  dense
                  outlined
                  hide-details="false"
                  required
                  style="width: 100%"
                  v-model="dataSetFFU.data.days"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6">
                <v-btn
                  :color="this.typeOnOffFFU ? 'success' : ''"
                  style="width: 90px"
                  @click="setOnOffFFU(true)"
                >
                  OPEN
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  :color="!this.typeOnOffFFU ? 'error' : ''"
                  style="width: 90px"
                  @click="setOnOffFFU(false)"
                >
                  CLOSE
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions class="pb-2">
          <v-btn class="mb-3" color="#383874" text @click="setFFU()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modal2.alarm"
      max-width="500px"
      persistent
      class="overflow-visible"
    >
      <v-card class="overflow-visible">
        <v-card-title
          class="font-weight-bold text-center mb-0 pb-0"
          style="color: #383874"
        >
          Setting Alarm {{ this.dataSetting.name }}
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row justify="center" align="center">
            <v-col cols="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> LCL:</v-col>
                <v-col cols="5">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.LCL"
                    type="Number"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> UCL:</v-col>
                <v-col cols="5" class="pl-0" justify="center" align="center">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.UCL"
                    type="Number"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> LSL:</v-col>
                <v-col cols="5">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.Low"
                    type="Number"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row justify="center" align="center">
                <v-col cols="4"> USL:</v-col>
                <v-col cols="5" class="pl-0" justify="center" align="center">
                  <v-text-field
                    dense
                    outlined
                    hide-details="false"
                    required
                    style="width: 70px"
                    v-model="editProgram.data.High"
                    type="Number"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col>
                  {{ this.dataSetting.unit }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-btn class="mb-3" color="#383874" text @click="setAlarm()">
            update </v-btn
          ><v-spacer></v-spacer>
          <v-btn class="mb-3" text @click="close()"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal2.PT" max-width="300px" persistent scrollable>
      <v-card>
        <v-card-text>
          <div class="col-12 px-0 mx-0 text-end">
            <v-btn-toggle dense v-model="dataSetPT.No" color="#383874">
              <v-btn small @click="setPT(1)" :value="1">PT101</v-btn>
              <v-btn small @click="setPT(2)" :value="2">PT102</v-btn>
            </v-btn-toggle>
          </div>
          <p
            class="mb-2 text-left"
            style="text-transform: uppercase; font-size: 13px"
          >
            Pressure Transmitter {{ this.dataSetPT.No }}
          </p>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>HighHigh :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataSetPT.HighHigh"
              >
              </v-text-field>
            </v-col>
            <v-col class="text-end">
              <h5>bar</h5>
            </v-col>
          </v-row>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>High :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataSetPT.High"
              >
              </v-text-field>
            </v-col>
            <v-col class="text-end">
              <h5>bar</h5>
            </v-col>
          </v-row>
          <v-row class="row" align="center">
            <v-col class="col-5 mt-2">
              <h5>Low :</h5>
            </v-col>
            <v-col class="col-4">
              <v-text-field
                dense
                outlined
                hide-details="false"
                required
                v-model="dataSetPT.Low"
              >
              </v-text-field>
            </v-col>
            <v-col class="text-end">
              <h5>bar</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-wd my-1"
            text
            color="#384CFF"
            style="font-size: 14px"
            @click="setPressure()"
            >UPDATE</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn class="btn-wd" text @click.native="close()">CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
  <div
    v-else
    align="center"
    justify="center"
    style="height: 400"
    :style="loading ? 'display: block;' : 'display: none;'"
  >
    <v-col cols="12">
      <v-progress-circular
        :size="60"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-col>
  </div>
</template>
<script>
import Farm from "@/components/Farm";
import Cookies from "js-cookie";
import "d3";
import * as d3 from "d3";
import router from "@/router/index";
import stream1 from "@/components/CCTV/stream1.vue";
import stream2 from "@/components/CCTV/stream2.vue";
import stream3 from "@/components/CCTV/stream3.vue";
import stream4 from "@/components/CCTV/stream4.vue";
import stream5 from "@/components/CCTV/stream5.vue";
import stream6 from "@/components/CCTV/stream6.vue";
import stream7 from "@/components/CCTV/stream7.vue";
import stream8 from "@/components/CCTV/stream8.vue";
import Gauge from "@/components/Gauge.vue";
import ChartSmall from "@/components/ChartSmall.vue";
export default {
  name: "DashboardPage",
  components: {
    Farm,
    stream1,
    stream2,
    stream3,
    stream4,
    stream5,
    stream6,
    stream7,
    stream8,
    Gauge,
    ChartSmall,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getRealTime() {
      return this.$store.getters.realTime;
    },
    getFarm() {
      return this.$store.getters.OneFarm;
    },
    getEnv() {
      return this.$store.getters.envFarm;
    },
    getTower() {
      return this.$store.getters.allTower;
    },
    typeProgram() {
      return [{ name: "Day" }, { name: "Night" }, { name: "Alarm" }];
    },
  },
  async mounted() {
    Cookies.set("idFarm", this.$route.params.id);
    this.idFarm = this.$route.params.id;
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.farmClick();
      setTimeout(function () {
        var svg;
        svg = document.getElementsByTagName("svg");
        if (svg.length > 0) {
          for (let i = 0; i < svg.length; i++) {
            var path = svg[i].getElementsByTagName("path");
            if (path.length > 1) {
              path[0].setAttributeNS(null, "stroke-linejoin", "round");
              path[1].setAttributeNS(null, "stroke-linejoin", "round");
            }
          }
        }
      }, 500);
    }
  },
  data() {
    return {
      isMobile: false,
      dataSearch: {
        farm: null,
        tower: null,
        type: [],
        timeMode: "day",
        month: null,
        year: null,
        timeStart: null,
        timeEnd: null,
      },
      chartAll: [],
      dataSetSV: {
        No: 1,
        Name: null,
        Mode: null,
        setOn: false,
      },
      titleCCTV: [
        "Plant Room 1",
        "Plant Room 2",
        "Layer 1",
        "Layer 2",
        "Layer 3",
        "Tower",
        "Dressing Room",
        "Working Room",
      ],
      idFarm: null,
      idTower: [],
      snackbar: false,
      text: null,
      color: null,
      loading: true,
      items: [
        {
          text: "Overview",
          disabled: false,
          to: "/overview",
        },
        {
          text: "Farm",
          disabled: true,
        },
      ],
      toggle: 0,
      typeFFU: {
        mode: false,
        setOn: false,
      },
      typeFanFlow: {
        mode: false,
        setOn: false,
      },
      dataSetting: {
        name: "",
        time: "Day",
        data: {
          day: {
            startTime: null,
            target: null,
          },
          night: {
            startTime: null,
            target: null,
          },
        },
        unit: "",
      },
      dataSetAir: {
        no: null,
        name: null,
        farm: null,
        id: null,
        startTime: {
          HH: 0,
          mm: 0,
        },
        endTime: {
          HH: 0,
          mm: 0,
        },
      },
      dataSetFanFlow: {
        id: null,
        data: {
          mode: null,
          startTime: null,
          stopTime: null,
          setOn: false,
        },
      },
      dataSetFFU: {
        id: null,
        data: {
          mode: null,
          days: null,
          startTime: null,
          stopTime: null,
          setOn: false,
        },
      },
      width: null,
      height: null,
      numTower: 0,
      numLayer: 1,
      numLayerTower1: 1,
      numLayerTower2: 1,
      programs: null,
      typeIndex: 0,
      typeOnOff: false,
      optionsTower: [
        { text: "Tower1", value: 0 },
        { text: "Tower2", value: 1 },
      ],
      optionsLayer: [
        { text: "Layer1", value: 1 },
        { text: "Layer2", value: 2 },
        { text: "Layer3", value: 3 },
        { text: "Layer4", value: 4 },
        { text: "Layer5", value: 5 },
      ],

      typeModeFFU: "Auto",
      typeOnOffFFU: false,
      typeModeFanFlow: "Auto",
      typeOnOffFanFlow: false,
      componentCCTV: null,
      modal: false,
      modal2: {
        cctv: false,
        air: false,
        fanflow: false,
        ffu: false,
        alarm: false,
        PT: false,
      },
      dataSetPT: {
        macAddress: null,
        No: null,
        Name: null,
        HighHigh: null,
        High: null,
        Low: null,
      },
      editProgram: {
        time: "Day",
        farm: null,
        data: {
          startTime: null,
          target: null,
          High: null,
          Low: null,
          LCL: null,
          UCL: null,
        },
      },
      time: {
        startTime: {
          HH: 0,
          mm: 0,
        },
        endTime: {
          HH: 0,
          mm: 0,
        },
      },
      timeProgram: {
        HH: 0,
        mm: 0,
      },
    };
  },
  methods: {
    numberRule: (val) => {
      if (val < 0) return "Please enter a positive number";
      return true;
    },
    setModeSV(no, mode) {
      //console.log(no);
      if (no == 1) {
        this.dataSetSV.No = no;
        this.dataSetSV.Name = "SV101";
      } else {
        this.dataSetSV.No = no;
        this.dataSetSV.Name = "SV102";
      }
      this.dataSetSV.Mode = mode;
      this.dataSetSV.macAddress = Cookies.get("macAddress");
      //console.log(this.dataSetSV);
      this.$store.dispatch("setCO2Valve", this.dataSetSV).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Mode " + this.dataSetSV.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    setOnOffSV(no, data) {
      if (no == 1) {
        this.dataSetSV.No = no;
        this.dataSetSV.Name = "SV101";
      } else {
        this.dataSetSV.No = no;
        this.dataSetSV.Name = "SV102";
      }
      this.dataSetSV.Mode = "Manual";
      this.dataSetSV.setOn = data;
      this.dataSetSV.macAddress = Cookies.get("macAddress");
      this.$store.dispatch("setCO2Valve", this.dataSetSV).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting On/Off " + this.dataSetSV.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
    showCCTV(num) {
      //console.log(num);
      this.componentCCTV = num;
      this.modal2.cctv = true;
    },
    findTarget(data, program) {
      //console.log(data);
      if (program == "Night") {
        //console.log(data["night"].target);
        return data["night"].target;
      } else {
        //console.log(data["day"].target);
        return data["day"].target;
      }
    },
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    formetDate() {
      const d = new Date();
      const offset = d.getTimezoneOffset();
      const date = new Date(d.getTime() - offset * 60 * 1000);
      let day = date.toISOString().split("T")[0];
      let start = day + "T00:00:00";
      let end = day + "T23:59:59";
      return [start, end];
    },
    isNum(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handler() {
      var args = arguments;
      for (var arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },
    close() {
      this.typeIndex = 0;
      this.modal = false;
      this.modal2.air = false;
      this.modal2.fanflow = false;
      this.modal2.ffu = false;
      this.modal2.alarm = false;
      this.modal2.PT = false;
    },
    setAir() {
      let time;
      let time2;
      time = [this.time.startTime.HH, this.time.startTime.mm];
      time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetAir.startTime = time.join(":");
      this.dataSetAir.endTime = time2.join(":");
      this.$store
        .dispatch("setAir", this.dataSetAir)
        .then(() => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! air conditioner has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting air conditioner.";
        });
    },
    openModal(type) {
      let dataTime;
      this.dataSetting.name = type;
      //console.log(this.getFarm.Environment.Programs);
      this.editProgram.time = "Day";
      if (type == "HUMIDITY") {
        this.dataSetting.unit = "%";
        this.editProgram.data.target =
          this.getFarm.Environment.Programs.humidity.day.target;
        dataTime =
          this.getFarm.Environment.Programs.humidity.day.startTime.split(":");
      } else if (type == "TEMPERATURE") {
        this.dataSetting.unit = "°C";
        this.editProgram.data.target =
          this.getFarm.Environment.Programs.temp.day.target;
        dataTime =
          this.getFarm.Environment.Programs.temp.day.startTime.split(":");
      } else if (type == "CO2") {
        this.dataSetting.unit = "ppm";
        this.editProgram.data.target =
          this.getFarm.Environment.Programs.CO2.day.target;
        dataTime =
          this.getFarm.Environment.Programs.CO2.day.startTime.split(":");
      }

      this.timeProgram.HH = dataTime[0];
      this.timeProgram.mm = dataTime[1];
      this.modal = true;
      //console.log(this.timeProgram);
    },
    setDataProgram(index) {
      //console.log(index);
      this.typeIndex = index;
      let dataTime;
      if (index == 0) {
        this.editProgram.time = "Day";
        if (this.dataSetting.name == "HUMIDITY") {
          this.dataSetting.unit = "%";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.humidity.day.target;
          dataTime =
            this.getFarm.Environment.Programs.humidity.day.startTime.split(":");
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        } else if (this.dataSetting.name == "TEMPERATURE") {
          this.dataSetting.unit = "°C";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.temp.day.target;
          dataTime =
            this.getFarm.Environment.Programs.temp.day.startTime.split(":");
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        } else if (this.dataSetting.name == "CO2") {
          this.dataSetting.unit = "ppm";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.CO2.day.target;
          dataTime =
            this.getFarm.Environment.Programs.CO2.day.startTime.split(":");
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        }
      } else if (index == 1) {
        this.editProgram.time = "Night";
        if (this.dataSetting.name == "HUMIDITY") {
          this.dataSetting.unit = "%";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.humidity.night.target;
          dataTime =
            this.getFarm.Environment.Programs.humidity.night.startTime.split(
              ":"
            );
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        } else if (this.dataSetting.name == "TEMPERATURE") {
          this.dataSetting.unit = "°C";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.temp.night.target;
          dataTime =
            this.getFarm.Environment.Programs.temp.night.startTime.split(":");
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        } else if (this.dataSetting.name == "CO2") {
          this.dataSetting.unit = "ppm";
          this.editProgram.data.target =
            this.getFarm.Environment.Programs.CO2.night.target;
          dataTime =
            this.getFarm.Environment.Programs.CO2.night.startTime.split(":");
          this.timeProgram.HH = dataTime[0];
          this.timeProgram.mm = dataTime[1];
        }
      } else if (index == 2) {
        if (this.dataSetting.name == "HUMIDITY") {
          this.dataSetting.unit = "%";
          this.editProgram.data.High =
            this.getFarm.Environment.Programs.humidity.High;
          this.editProgram.data.Low =
            this.getFarm.Environment.Programs.humidity.Low;
          this.editProgram.data.LCL =
            this.getFarm.Environment.Programs.humidity.LCL;
          this.editProgram.data.UCL =
            this.getFarm.Environment.Programs.humidity.UCL;
        } else if (this.dataSetting.name == "TEMPERATURE") {
          this.dataSetting.unit = "°C";
          this.editProgram.data.High =
            this.getFarm.Environment.Programs.temp.High;
          this.editProgram.data.Low =
            this.getFarm.Environment.Programs.temp.Low;
          this.editProgram.data.LCL =
            this.getFarm.Environment.Programs.temp.LCL;
          this.editProgram.data.UCL =
            this.getFarm.Environment.Programs.temp.UCL;
        } else if (this.dataSetting.name == "CO2") {
          this.dataSetting.unit = "ppm";
          this.editProgram.data.High =
            this.getFarm.Environment.Programs.CO2.High;
          this.editProgram.data.Low = this.getFarm.Environment.Programs.CO2.Low;
          this.editProgram.data.LCL = this.getFarm.Environment.Programs.CO2.LCL;
          this.editProgram.data.UCL = this.getFarm.Environment.Programs.CO2.UCL;
        }
      }
    },
    setProgram() {
      //console.log(this.dataSetting.unit);
      let timeS = [this.timeProgram.HH, this.timeProgram.mm];
      this.editProgram.farm = Cookies.get("idFarm");
      this.editProgram.data.startTime = timeS.join(":");
      this.editProgram.data.target = parseInt(this.editProgram.data.target);
      //console.log(this.editProgram);
      if (this.dataSetting.unit == "%") {
        this.$store
          .dispatch("setHumidity", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Success! humidity has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting humidity.";
          });
      } else if (this.dataSetting.unit == "ppm") {
        this.$store
          .dispatch("setCO2", this.editProgram)
          .then(() => {
            this.close();

            this.snackbar = true;
            this.color = "success";
            this.text = "Success! CO2 has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting CO2.";
          });
      } else if (this.dataSetting.unit == "°C") {
        this.$store
          .dispatch("setTemp", this.editProgram)
          .then(() => {
            this.close();

            this.snackbar = true;
            this.color = "success";
            this.text = "Success! Temperature has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting temperature.";
          });
      }
    },
    setFFU() {
      this.dataSetFFU.id = Cookies.get("idFarm");
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFFU.data.startTime = time.join(":");
      this.dataSetFFU.data.stopTime = time2.join(":");
      this.dataSetFFU.data.days = parseInt(this.dataSetFFU.data.days);
      this.dataSetFFU.data.mode = "Auto";
      //console.log(this.dataSetFFU);
      this.$store
        .dispatch("setFFU", this.dataSetFFU)
        .then(() => {
          //this.close();
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! FFU has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting FFU.";
        });
    },
    setModeFFU(mode) {
      this.dataSetFFU.id = Cookies.get("idFarm");
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFFU.data.startTime = time.join(":");
      this.dataSetFFU.data.stopTime = time2.join(":");
      this.typeModeFFU = mode;
      this.dataSetFFU.data.mode = mode;
      //console.log(this.dataSetFFU);
      this.$store
        .dispatch("setFFU", this.dataSetFFU)
        .then(() => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! FFU has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting FFU.";
        });
    },
    setOnOffFFU(on) {
      this.dataSetFFU.id = Cookies.get("idFarm");
      this.typeOnOffFFU = on;
      this.dataSetFFU.data.setOn = on;
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFFU.data.startTime = time.join(":");
      this.dataSetFFU.data.stopTime = time2.join(":");
      //console.log(this.dataSetFFU);
      this.$store
        .dispatch("setFFU", this.dataSetFFU)
        .then(() => {
          //this.close();
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! FFU has been setting.";
        })
        .catch(() => {
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting FFU.";
        });
    },
    setFanFlow() {
      this.dataSetFanFlow.id = Cookies.get("idFarm");
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFanFlow.data.startTime = time.join(":");
      this.dataSetFanFlow.data.stopTime = time2.join(":");
      this.dataSetFanFlow.data.mode = "Auto";
      //console.log(this.dataSetFanFlow);
      this.$store
        .dispatch("setFanFlow", this.dataSetFanFlow)
        .then(() => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! Fanflow has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting Fanflow.";
        });
    },
    setModeFanFlow(mode) {
      this.dataSetFanFlow.id = Cookies.get("idFarm");
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFanFlow.data.startTime = time.join(":");
      this.dataSetFanFlow.data.stopTime = time2.join(":");
      this.typeModeFanFlow = mode;
      this.dataSetFanFlow.data.mode = mode;
      //console.log(this.dataSetFanFlow);
      this.$store
        .dispatch("setFanFlow", this.dataSetFanFlow)
        .then(() => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! Fanflow has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting Fanflow.";
        });
    },
    setOnOffFanFlow(on) {
      this.dataSetFanFlow.id = Cookies.get("idFarm");
      this.typeOnOffFanFlow = on;
      this.dataSetFanFlow.data.setOn = on;
      let time = [this.time.startTime.HH, this.time.startTime.mm];
      let time2 = [this.time.endTime.HH, this.time.endTime.mm];
      this.dataSetFanFlow.data.startTime = time.join(":");
      this.dataSetFanFlow.data.stopTime = time2.join(":");
      //console.log(this.dataSetFanFlow);
      this.$store
        .dispatch("setFanFlow", this.dataSetFanFlow)
        .then(() => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Success! Fanflow has been setting.";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Erorr! You cannot setting Fanflow.";
        });
    },
    async farmClick() {
      let setDataFFU = this.setDataFFU;
      let setDataFanFlow = this.setDataFanFlow;
      let setDataAir = this.setDataAir;
      let openModal = this.openModal;
      let getAccess = this.getAccess;
      const days = this.formetDate();
      let data = {
        filter: "Farm",
        timeMode: "Day",
        timeStart: days[0],
        timeEnd: days[1],
        farm: this.idFarm,
        tower: null,
        type: [
          "TemperatureAverage",
          "HumidityAverage",
          "CO2Value1",
          "VPD",
          "DiffPressure",
        ],
      };
      this.$store.dispatch("getFarmByID", this.idFarm).then(() => {
        this.$store.dispatch("getTowerByFarm", this.idFarm).then(() => {
          for (let i = 0; i < this.getTower.length; i++) {
            this.idTower[i] = this.getTower[i]._id;
            Cookies.set("idTower" + (i + 1), this.idTower[i]);
          }
        });
        this.$store.dispatch("getReport", data).then((res) => {
          this.chartAll = res;

          this.$store.dispatch("getRealTime", this.idFarm).then(() => {
            this.loading = false;
          });
        });

        Cookies.set("macAddress", this.getFarm.macAddress);
        //console.log(this.getFarm);
        d3.selectAll("div").on("click", function (event) {
          //console.log(event.target.id);
          if (event.target.id == "Tower") {
            const id = Cookies.get("idTower1");
            router.push({ name: "Tower", params: { id: id } });
          }
          if (event.target.id == "Tower2") {
            const id = Cookies.get("idTower2");
            router.push({ name: "Tower", params: { id: id } });
          }
          if (getAccess.role != "User") {
            if (event.target.id == "setHumidity") {
              openModal("HUMIDITY");
            }
            if (event.target.id == "setFFU") {
              setDataFFU();
            }
            if (event.target.id == "setFanFlow") {
              setDataFanFlow();
            }
            if (
              event.target.id == "setAir1" ||
              event.target.id == "setAir2" ||
              event.target.id == "setAir3"
            ) {
              setDataAir(event.target.id.slice(6));
            }
          }
        });
      });
    },
    setDataAir(num) {
      this.dataSetAir.no = num;
      this.dataSetAir.name = "AIR NO." + num;
      this.dataSetAir.farm = this.getFarm.airs[num - 1].farm;
      this.dataSetAir.id = this.getFarm.airs[num - 1]._id;
      let on1 = this.getFarm.airs[num - 1].startTime.split(":");
      let off1 = this.getFarm.airs[num - 1].endTime.split(":");
      this.time.startTime.HH = on1[0];
      this.time.startTime.mm = on1[1];
      this.time.endTime.HH = off1[0];
      this.time.endTime.mm = off1[1];
      this.modal2.air = true;
    },
    setDataFanFlow() {
      this.typeModeFanFlow = this.getFarm.Environment.Programs.fanflow.mode;
      this.typeOnOffFanFlow = this.getFarm.Environment.Programs.fanflow.setOn;
      this.dataSetFanFlow.data.days =
        this.getFarm.Environment.Programs.fanflow.days;
      this.dataSetFanFlow.data.mode =
        this.getFarm.Environment.Programs.fanflow.mode;
      this.dataSetFanFlow.data.setOn =
        this.getFarm.Environment.Programs.fanflow.setOn;
      let on1 = this.getFarm.Environment.Programs.fanflow.startTime.split(":");
      //console.log(on1);
      this.time.startTime.HH = on1[0];
      this.time.startTime.mm = on1[1];
      let off1 = this.getFarm.Environment.Programs.fanflow.stopTime.split(":");
      this.time.endTime.HH = off1[0];
      this.time.endTime.mm = off1[1];
      this.modal2.fanflow = true;
    },
    setDataFFU() {
      this.modal2.ffu = true;
      this.typeModeFFU = this.getFarm.Environment.Programs.FFU.mode;
      this.typeOnOffFFU = this.getFarm.Environment.Programs.FFU.setOn;
      this.dataSetFFU.data.days = this.getFarm.Environment.Programs.FFU.days;
      this.dataSetFFU.data.mode = this.getFarm.Environment.Programs.FFU.mode;
      this.dataSetFFU.data.setOn = this.getFarm.Environment.Programs.FFU.setOn;
      let on1 = this.getFarm.Environment.Programs.FFU.startTime.split(":");
      this.time.startTime.HH = on1[0];
      this.time.startTime.mm = on1[1];
      let off1 = this.getFarm.Environment.Programs.FFU.stopTime.split(":");
      this.time.endTime.HH = off1[0];
      this.time.endTime.mm = off1[1];
    },
    setDataAlarm(type) {
      if (type == "vpd") {
        this.dataSetting.name = "VPD";
        this.dataSetting.unit = "kPa";
        this.editProgram.data.High =
          this.getFarm.Environment.VPD.High.toFixed(2);
        this.editProgram.data.Low = this.getFarm.Environment.VPD.Low.toFixed(2);
        this.editProgram.data.LCL = this.getFarm.Environment.VPD.LCL.toFixed(2);
        this.editProgram.data.UCL = this.getFarm.Environment.VPD.UCL.toFixed(2);
        this.modal2.alarm = true;
      } else if (type == "diff") {
        this.dataSetting.name = "Differential Pressure";
        this.dataSetting.unit = "Pa";
        this.editProgram.data.High =
          this.getFarm.Environment.diffPressure.High.toFixed(2);
        this.editProgram.data.Low =
          this.getFarm.Environment.diffPressure.Low.toFixed(2);
        this.editProgram.data.LCL =
          this.getFarm.Environment.diffPressure.LCL.toFixed(2);
        this.editProgram.data.UCL =
          this.getFarm.Environment.diffPressure.UCL.toFixed(2);
        this.modal2.alarm = true;
      }
    },
    setAlarm() {
      this.editProgram.farm = Cookies.get("idFarm");
      //console.log(this.editProgram);
      this.editProgram.data.High = parseFloat(this.editProgram.data.High);
      this.editProgram.data.Low = parseFloat(this.editProgram.data.Low);
      this.editProgram.data.LCL = parseFloat(this.editProgram.data.LCL);
      this.editProgram.data.UCL = parseFloat(this.editProgram.data.UCL);

      if (this.dataSetting.unit == "%") {
        this.$store
          .dispatch("setAlarmHumi", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Success! alarm humidity has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting alarm humidity.";
          });
      } else if (this.dataSetting.unit == "ppm") {
        this.$store
          .dispatch("setAlarmCO2", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Success! alarm CO2 has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting alarm CO2.";
          });
      } else if (this.dataSetting.unit == "°C") {
        this.$store
          .dispatch("setAlarmTemp", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Success! alarm Temperature has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting alarm temperature.";
          });
      } else if (this.dataSetting.name == "VPD") {
        this.$store
          .dispatch("setAlarmVPD", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text = "Success! alarm VPD has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text = "Erorr! You cannot setting alarm VPD.";
          });
      } else if (this.dataSetting.name == "Differential Pressure") {
        this.$store
          .dispatch("setAlarmDiff", this.editProgram)
          .then(() => {
            this.snackbar = true;
            this.color = "success";
            this.text =
              "Success! alarm Differential Pressure has been setting.";
          })
          .catch(() => {
            this.snackbar = true;
            this.color = "error";
            this.text =
              "Erorr! You cannot setting alarm Differential Pressure.";
          });
      }
    },
    setPT(no) {
      this.modal2.PT = true;
      this.dataSetPT.Name = "PT10" + no;
      this.dataSetPT.No = no;
      this.dataSetPT.HighHigh =
        this.getFarm.Environment.CO2.Pressure[no].HighHigh;
      this.dataSetPT.High = this.getFarm.Environment.CO2.Pressure[no].High;
      this.dataSetPT.Low = this.getFarm.Environment.CO2.Pressure[no].Low;
    },
    setPressure() {
      this.dataSetPT.macAddress = Cookies.get("macAddress");
      this.dataSetPT.HighHigh = parseFloat(this.dataSetPT.HighHigh);
      this.dataSetPT.High = parseFloat(this.dataSetPT.High);
      this.dataSetPT.Low = parseFloat(this.dataSetPT.Low);
      this.$store.dispatch("setPressure", this.dataSetPT).then(
        () => {
          this.snackbar = true;
          this.color = "success";
          this.text = "Setting Pressure " + this.dataSetPT.Name + " success";
        },
        (error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = error;
        }
      );
    },
  },
};
</script>
<style scoped>
>>> .v-dialog {
  overflow-y: visible;
}
>>> .v-btn__content {
  font-size: small;
}
>>> .apexcharts-tooltip {
  border-radius: 12px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}
>>> .apexcharts-tooltip-text-value {
  font-weight: normal;
  font-family: Lato;
  font-size: 5px;
}
>>> apexcharts-theme-light .apexcharts-tooltip-title {
  font-family: Lato !important;
  background-color: white;
  border-bottom: 0;
  font-weight: bold;
}
>>> .apexcharts-tooltip span {
  padding: 0px 0px;
}
</style>
