import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import markerClusters from "highcharts/modules/marker-clusters";
import solidGauge from "highcharts/modules/solid-gauge";
import More from "highcharts/highcharts-more";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from "vue2-timepicker";
import VueSocketIO from "vue-socket.io";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { VLazyImagePlugin } from "v-lazy-image";
Vue.use(VLazyImagePlugin);
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
import VueApexCharts from "vue-apexcharts";
Vue.component("VueSlider", VueSlider);
Vue.use(VueApexCharts);
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_SOCKET_URL, //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.component("ApexChart", VueApexCharts);
markerClusters(Highcharts);
Maps(Highcharts);
More(Highcharts);
solidGauge(Highcharts);
Vue.use(HighchartsVue);
Vue.component("VueTimepicker", VueTimepicker);
Vue.use(DatePicker);
Vue.use(VueTimepicker);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
