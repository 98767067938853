<template>
  <div v-if="lang == 0">
    <v-lazy-image :src="THP01" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP02" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP03" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP04" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP05" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP06" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP07" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP08" width="100%"></v-lazy-image>
    <v-lazy-image :src="THP09" width="100%"></v-lazy-image>
  </div>
  <div v-else>
    <v-lazy-image :src="ENP01" width="100%" />
    <v-lazy-image :src="ENP02" width="100%" />
    <v-lazy-image :src="ENP03" width="100%" />
    <v-lazy-image :src="ENP04" width="100%" />
    <v-lazy-image :src="ENP05" width="100%" />
    <v-lazy-image :src="ENP06" width="100%" />
    <v-lazy-image :src="ENP07" width="100%" />
    <v-lazy-image :src="ENP08" width="100%" />
    <v-lazy-image :src="ENP09" width="100%" />
  </div>
</template>
<script>
export default {
  name: "ProFile",
  props: {
    lang: Number,
  },
  methods: {
    onload() {
      console.log("dd");
    },
  },
  data() {
    return {
      ENP01: require("/public/en/ENP01.jpg"),
      ENP02: require("/public/en/ENP02.jpg"),
      ENP03: require("/public/en/ENP03.jpg"),
      ENP04: require("/public/en/ENP04.jpg"),
      ENP05: require("/public/en/ENP05.jpg"),
      ENP06: require("/public/en/ENP06.jpg"),
      ENP07: require("/public/en/ENP07.jpg"),
      ENP08: require("/public/en/ENP08.jpg"),
      ENP09: require("/public/en/ENP09.jpg"),

      THP01: require("/public/th/THP01.jpg"),
      THP02: require("/public/th/THP02.jpg"),
      THP03: require("/public/th/THP03.jpg"),
      THP04: require("/public/th/THP04.jpg"),
      THP05: require("/public/th/THP05.jpg"),
      THP06: require("/public/th/THP06.jpg"),
      THP07: require("/public/th/THP07.jpg"),
      THP08: require("/public/th/THP08.jpg"),
      THP09: require("/public/th/THP09.jpg"),
    };
  },
};
</script>
