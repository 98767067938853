<template>
  <div fluid :class="isMobile ? 'pb-10' : ''" v-resize="onResize">
    <v-row align="center" class="ma-1">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ml-1">
        <span
          class="font-weight-medium"
          style="
            color: #383874;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
          "
        >
          Alarms
        </span>
      </v-col>
    </v-row>

    <v-card id="card" class="mx-5 mb-10">
      <v-card-title style="color: #383874; font-style: normal">
        Alarms
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
          color="#384CFF"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getAlarms"
        :items-per-page="5"
        style="color: #383874; font-style: normal"
      >
        <template v-slot:[`item.tower`]="{ item }">
          <span v-if="item.tower">
            {{ item.tower.name }}
          </span>
          <span v-else> Environment </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="openLine(item)"
              >
                <v-icon>fas fa-brands fa-line</v-icon>
              </v-btn>
            </template>
            <span>Line</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="editAlarmData(item)"
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="delAlarmData(item._id)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="position: fixed; bottom: 25px; right: 0px"
            class="ma-10"
            fixed
            fab
            color="#384CFF"
            dark
            absolute
            bottom
            right
            dense
            floating
            v-bind="attrs"
            v-on="on"
            @click="form = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add</span>
      </v-tooltip>

      <v-dialog v-model="form" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Create Alarm</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addAlarm()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="alarmData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="description"
                  label="Description*"
                  v-model="alarmData.description"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Description is required']"
                  required
                ></v-text-field>
                <v-select
                  :items="getFarms"
                  name="farm"
                  label="Farm*"
                  item-value="_id"
                  item-text="name"
                  v-model="alarmData.farm"
                  required
                  color="#384CFF"
                  @change="findTower(alarmData.farm)"
                ></v-select>
                <v-select
                  :items="getTower"
                  name="tower"
                  label="Tower*"
                  item-value="_id"
                  item-text="name"
                  v-model="alarmData.tower"
                  required
                  color="#384CFF"
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn text @click="addAlarm()" color="green"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="450px">
        <template>
          <v-card>
            <v-card-title
              ><span style="color: #383874">Edit Alarm</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editAlarm()"
                ref="alarmDataEdit"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  label="Name*"
                  v-model="alarmDataEdit.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="description"
                  label="Description*"
                  v-model="alarmDataEdit.description"
                  @keypress="isLetter($event)"
                  :rules="[(v) => !!v || 'Description is required']"
                  required
                ></v-text-field>
                <v-select
                  :items="getFarms"
                  name="farm"
                  label="Farm*"
                  item-value="_id"
                  item-text="name"
                  v-model="alarmDataEdit.farm"
                  required
                ></v-select>
                <v-select
                  :items="getTower"
                  name="tower"
                  label="Tower*"
                  item-value="_id"
                  item-text="name"
                  v-model="alarmDataEdit.tower"
                  required
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn color="green" text @click="editAlarm()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="confirmDelete" max-width="320" persistent>
        <v-card>
          <v-card-title style="color: #383874" primary-title>
            Confirm Delete
          </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this {{ nameDelete }}?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delAlarm()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="formAddLine" max-width="300" persistent>
        <v-card>
          <v-card-title style="color: #383874" primary-title>
            Add Line
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              :items="getLines"
              name="line"
              label="Line*"
              item-value="_id"
              item-text="name"
              v-model="lineAlarm.line"
              required
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="formAddLine = false"> Cancel </v-btn>

            <v-btn color="green" text @click="addLine()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="lineform" max-width="550" persistent>
        <v-card>
          <v-card-title>
            <v-col cols="8" class="mx-0 px-0">
              <span style="color: #383874">Line</span>
            </v-col>
            <v-col cols="4" class="mx-0 px-0 text-right">
              <v-btn dark color="green" @click="openformAdd"> Add</v-btn>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-data-table
              item-key="id"
              :search="search"
              :headers="headersLine"
              :items="getLinesAlarms"
              :items-per-page="5"
              style="color: #383874; font-style: normal"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="delLineData(item._id)"
                      icon
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="lineform = false"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
//import Cookies from "js-cookie";
export default {
  name: "AlarmPage",
  data: () => ({
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    lineform: false,
    formAddLine: false,
    idOwner: null,
    headers: [
      { text: "Name", value: "name", sortable: true },
      { text: "Farm", value: "farm.name", sortable: true },
      { text: "Tower", value: "tower", sortable: true },
      { text: "Description", value: "description", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    headersLine: [
      { text: "Name", value: "name", sortable: true },
      { text: "Token", value: "token", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    lineAlarm: {
      line: "",
      token: "",
      name: "",
      alarm: "",
    },
    alarmData: {
      name: "",
      tower: "",
      farm: "",
      description: "",
    },
    alarmDataEdit: {
      _id: "",
      name: "",
      tower: "",
      farm: "",
      description: "",
    },
    default: {
      name: "",
      tower: "",
      farm: "",
      description: "",
    },
    nameDelete: "",
    isMobile: true,
  }),
  computed: {
    getAlarms() {
      return this.$store.getters.allAlarms;
    },
    getLines() {
      return this.$store.getters.allLines;
    },
    getLinesAlarms() {
      return this.$store.getters.getLinesAlarms;
    },
    getFarms() {
      return this.$store.getters.getFarms;
    },
    getTower() {
      let towers = this.$store.getters.allTower;
      const newArray = [{ _id: null, name: "Environment" }].concat(towers);
      return newArray;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      if (this.getAccess.role != "User") {
        this.$store.dispatch("getAlarms");
        this.$store.dispatch("getFarms");
        console.log(this.getAccess.owner);
        this.$store.dispatch("getLines", this.getAccess.owner);
      } else {
        this.$router.push("/");
      }
    }
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    findTower(id) {
      this.$store.dispatch("getTowerByFarm", id);
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.alarmData = Object.assign({}, this.default);
    },
    delAlarmData(id) {
      this.confirmDelete = true;
      this.delId = id;
      this.nameDelete = "alarm";
    },
    delLineData(id) {
      this.confirmDelete = true;
      this.delId = id;
      this.nameDelete = "line";
    },
    openLine(data) {
      this.lineform = true;
      this.lineAlarm.alarm = data._id;
      this.$store.dispatch("getLinesAlarms", data._id);
    },
    openformAdd() {
      this.formAddLine = true;
    },
    addAlarm() {
      if (this.alarmData.name === "" || this.alarmData.farm === "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create a alarm.";
      } else {
        this.alarmData.owner = this.idOwner;
        if (this.alarmData.tower == "null") {
          this.alarmData.tower = null;
        }
        console.log(this.alarmData);
        this.$store.dispatch("createAlarm", this.alarmData).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.closeDlg();

            this.color = "success";
            this.text = "Line config has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        });
      }
    },
    delAlarm() {
      if (this.nameDelete == "alarm") {
        this.$store
          .dispatch("deleteAlarm", this.delId)
          .then(() => {
            this.closeDlg();
            this.color = "success";
            this.text = "Deleted! alarm has been deleted.";
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.color = "error";
            this.text = " Error! You cannot delete this alarm.";
            this.snackbar = true;
          });
      } else {
        this.$store
          .dispatch("deleteAlarmLine", this.delId)
          .then(() => {
            this.closeDlg();
            this.color = "success";
            this.text = "Deleted! alarm has been deleted.";
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.color = "error";
            this.text = " Error! You cannot delete this alarm.";
            this.snackbar = true;
          });
      }
    },
    editAlarmData(data) {
      //console.log("data");
      this.editform = true;
      this.alarmDataEdit = Object.assign({}, data);
      this.findTower(data.farm._id);
      console.log(this.alarmDataEdit);
      if (this.alarmDataEdit.tower == undefined) {
        this.alarmDataEdit.tower = {
          _id: null,
          name: "Environment",
        };
      }
    },
    editAlarm() {
      console.log(this.alarmDataEdit);
      if (this.alarmData.tower == "null") {
        this.alarmData.tower = null;
      }
      this.$store
        .dispatch("editAlarm", this.alarmDataEdit)
        .then((res) => {
          //console.log('res.error.message');
          //console.log(res);
          if (res.data.message == "Sorry Username Is Already Exists") {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that alarm already exists!";
            this.snackbar = true;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Alarm has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this alarm.";
          this.snackbar = true;
        });
    },
    addLine() {
      const index = this.getLines.findIndex(
        (u) => u._id === this.lineAlarm.line
      );
      this.lineAlarm.name = this.getLines[index].name;
      this.lineAlarm.token = this.getLines[index].token;
      this.$store.dispatch("createAlarmLine", this.lineAlarm).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.formAddLine = false;

          this.color = "success";
          this.text = "Line config has been successfully saved.";
          this.snackbar = true;
        } else {
          this.snackbar = true;
          this.color = "error";
          this.text = res.data.error.message;
        }
      });
    },
  },
};
</script>
