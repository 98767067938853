import getStorage from "../services/auth-expire";
export default function authHeader() {
  let user = getStorage("user");
  //console.log(user);
  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
}
