<template>
  <v-layout v-resize="onResize" column>
    <template v-if="!$route.meta.allowAnonymous">
      <v-app style="background: #eef1fa">
        <v-main>
          <SideBar />
          <router-view></router-view>
          <BottomNavigation v-if="isMobile" />
        </v-main>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
  </v-layout>
</template>

<script>
import SideBar from "./components/SideBar.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
export default {
  name: "App",
  components: {
    SideBar,
    BottomNavigation,
  },
  data: () => ({
    isMobile: false,
  }),
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins");
.v-application {
  font-family: "Poppins";
}
::-webkit-scrollbar {
  width: 14px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c2c2c2;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
