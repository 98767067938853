<template>
  <v-app>
    <v-app-bar app :color="bg" elevation="0" class="bar">
      <v-app-bar-nav-icon class="ml-3">
        <img :src="require('/public/logoWH.png')" width="4%" />
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu rounded>
        <template v-slot:activator="{ attrs, on }">
          <div v-bind="attrs" v-on="on">
            <v-row align="center" style="width: 80pt">
              <v-col cols="6">
                <v-avatar size="30">
                  <img :src="flags[lang].img" />
                </v-avatar>
              </v-col>
              <v-col cols="6" class="px-0 white--text">
                {{ flags[lang].text }}
              </v-col>
            </v-row>
          </div>
        </template>
        <v-list dense flat>
          <template v-for="(flag, i) in flags">
            <v-divider v-if="flag.inset" :key="i"></v-divider>
            <v-list-item v-else :key="flag.text" link @click="changeLang(i)">
              <v-list-item-title>
                <v-row align="center">
                  <v-col cols="6">
                    <v-avatar size="30">
                      <img :src="flag.img" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="6" class="pl-0 text--white">
                    {{ flag.text }}
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-divider></v-divider>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn class="mx-2" :to="{ name: 'login' }"> Login </v-btn>
    </v-app-bar>
    <Profile :lang="lang"></Profile>
  </v-app>
</template>
<script>
import Profile from "@/components/Profile";
export default {
  name: "home-page",
  components: {
    Profile,
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  data() {
    return {
      bg: "transparent",
      lang: 0,
      flags: [
        {
          text: "THA",
          img: require("/public/flag/THA.svg"),
        },
        { type: "divider", inset: true },
        {
          text: "EN",
          img: require("/public/flag/EN.svg"),
        },
      ],
    };
  },
  computed: {},
  methods: {
    changeLang(i) {
      console.log(i);
      this.lang = i;
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        //this.bg = "transparent";
        this.bg = "#007346";
      } else {
        this.bg = "transparent";
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Damion");
</style>
