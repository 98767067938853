<template>
  <div>
    <v-bottom-navigation
      dense
      fixed
      color="#749a4c"
      background-color="#fff"
      v-model="path"
    >
      <v-btn
        style="width: 80px"
        v-for="item of items"
        :key="item.id"
        :to="item.to"
        :value="item.name"
      >
        <span style="font-size: xx-small">{{ item.name }}</span>

        <v-icon style="font-size: x-large">{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  mounted() {
    this.checkManu();
  },
  watch: {
    $route(to) {
      console.log(to.name);
      this.path = to.name;
      this.checkManu();
    },
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data: () => ({
    tab: null,
    path: null,
    items: [],
    itemsSuperAdmin: [
      { name: "Overview", to: "/overview", icon: "fas fa-house" },
      { name: "Reports", to: "/reports", icon: "fas fa-chart-line" },
      { name: "Events", to: "/events", icon: "fas fa-bell" },
      { name: "Alarms", to: "/alarms", icon: "fas fa-triangle-exclamation" },
      { name: "Setting", to: "/owners", icon: "fas fa-gears" },
    ],
    itemsAdmin: [
      { name: "Overview", to: "/overview", icon: "fas fa-house" },
      { name: "Reports", to: "/reports", icon: "fas fa-chart-line" },
      { name: "Events", to: "/events", icon: "fas fa-bell" },
      { name: "Alarms", to: "/alarms", icon: "fas fa-triangle-exclamation" },
      { name: "Setting", to: "/owners/lines", icon: "fas fa-gears" },
    ],
    itemsUser: [{ name: "Overview", to: "/overview", icon: "fas fa-house" }],
    itemsSettingSuper: [
      { name: "Overview", to: "/overview", icon: "fas fa-house" },
      { name: "Owners", to: "/owners" },
      { name: "Farms", to: "/farms" },
      { name: "Accounts", to: "/accounts", icon: "fas fa-user-gear" },
    ],
    itemsSettingAdmin: [
      { name: "Overview", to: "/overview", icon: "fas fa-house" },
      {
        name: "Owners Line ",
        to: "/owners/lines",
        icon: "fas fa-brands fa-line",
      },
      { name: "Accounts", to: "/accounts", icon: "fas fa-user-gear" },
    ],
  }),
  methods: {
    checkManu() {
      //console.log(this.eventSound);
      this.path = this.$router.currentRoute.name;
      console.log(this.path);
      if (
        this.path == "Owners" ||
        this.path == "Farms" ||
        this.path == "Accounts" ||
        this.path == "LineOwner"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.itemsSettingSuper;
        } else if (this.getAccess.role == "Admin") {
          this.items = this.itemsSettingAdmin;
        } else {
          this.items = this.itemsAdmin;
        }
      } else if (
        this.path == "Overview" ||
        this.path == "Farm" ||
        this.path == "Tower" ||
        this.path == "Reports" ||
        this.path == "Events" ||
        this.path == "Alarms"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.itemsSuperAdmin;
        } else if (this.getAccess.role == "Admin") {
          console.log("item admin");
          this.items = this.itemsAdmin;
        } else {
          this.items = this.itemsUser;
        }
      }
    },
  },
};
</script>

<style>
.v-bottom-navigation {
  justify-content: start;
}
</style>
